import React, {useContext} from "react";
import {useHistory} from 'react-router-dom';
import {ScoringModelContext} from '../global_values';

const ChoiceAfterScoring = () => {
  const {scoringModelContextValue, setScoringModelContextValue} = useContext(ScoringModelContext); 
  const history = useHistory();

  const ReportPageFunction=(e)=>{
      history.push(`/reporting/${scoringModelContextValue.analysis}`)
  }

  const PortfolioPageFunction=(e)=>{
    history.push(`/portfolio/${scoringModelContextValue.analysis}`)
  }

  function previewPageFunction(e){
    history.push(`/custom-scoring/${scoringModelContextValue.analysis}`)
  }

  return(
  <div className="main-container">
    <div className="content-main">
      <div className="choiceAfterScoring">
        <h1>Your custom scoring is ready. Where would you like to go next?</h1>
        <div className="choice-buttons">
          <button className="b-report" onClick={ReportPageFunction}>
            Reporting
           </button>
          <button className="b-portfolio" onClick={PortfolioPageFunction}>
          Portfolio Analysis   
          </button>
        </div>
      </div>
      <div className="icons-buttons">
        <button className="btn-icon" onClick={previewPageFunction}>
           Previous
        </button>
        <button className="btn-icon" style={{visibility:'hidden'}}>
            Next
        </button>
    </div>  
    </div>
  </div>
  ) 
}

export default ChoiceAfterScoring;