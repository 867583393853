import React, {useState} from "react";
import ReactDom from 'react-dom';

import ShowNodeView from "./ShowNodeView";
import close from "../assets/close.svg";

const MODAL_STYLES={
    position:'fixed',
    top:'50%',
    left:'50%',
    transform:'translate(-50%, -50%)',
    backgroundColor:'#f5fafd', 
    padding:'0px 40px 40px 40px',
    borderRadius:'5px',
    zIndex:1000,
    width:'80%',
    maxHeight: '-webkit-fill-available',
    overflowY: 'scroll',
    margin:'50px 0', 
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
   
  }
  
  const OVERLAY_STYLES={
    position:'fixed',
    top:0,
    left:0,
    right:0,
    bottom:0,
    backgroundColor:'rgba(0,0,0,.7)',
    zIndex:1000,    
  };

const TableModal = ({open, setIsOpen, onClose, showNode}) => {
  const [error, setError]=useState("");
 
  const cancel = () => {
    setIsOpen(!open);
    }

    if(!open) return null
  return ReactDom.createPortal(
      <>
      <div onClick={onClose} style={OVERLAY_STYLES}/>
    <div style={MODAL_STYLES}>
      <button style={{backgroundColor:'inherit'}} onClick={()=>setIsOpen(!open)}>
        <img src={close} alt="close icon"/>
      </button>
      <ShowNodeView style={{width:"100%"}} showNode={showNode}></ShowNodeView>
     </div>
     </>,
     document.getElementById('portal')
  )
}

export default TableModal;