import React, {useContext} from 'react'
import trash from "../assets/trash.svg"
//Import contexts
import { ClDragSetValueContext,
    ClDropSetValueContext,
    FactorSelectionFilterContext} from '../global_values'

function DropSetValues() {

    const {clDragSetValueContextValue, setClDragSetValueContextValue} = useContext(ClDragSetValueContext)
    const {clDropSetValueContextValue, setClDropSetValueContextValue} = useContext(ClDropSetValueContext)
    const {factorSelectionFilterContextValue, setFactorSelectionFilterContextValue} = useContext(FactorSelectionFilterContext)

    const dropToDrag=(event)=>{
        if(event.target.dataset.type===factorSelectionFilterContextValue || factorSelectionFilterContextValue==="all"){
            setClDragSetValueContextValue([...clDragSetValueContextValue, clDropSetValueContextValue.find(element=>element.id.toString()===event.target.dataset.id)])
            setClDropSetValueContextValue(clDropSetValueContextValue.filter(element=>element.id.toString()!==event.target.dataset.id))
        }
    }

    const setProfileFunction=((e)=>{
        const tag=clDropSetValueContextValue.find(element=>element.id.toString()===e.target.dataset.id)
        tag.profile=e.target.value
      })

    return (
        <table className="responsive-table">
        <tr className="table-header">
          <th className="col col-1">Investment’s type</th>
          <th className="col col-2">Profile</th>
        </tr>
        {
        clDropSetValueContextValue.map((value, index) => {
              return (
                    <tr className="table-row"
                    key={value['id']}
                    > 
                        <td className="col-1"
                        style={{
                          userSelect: "none",
                          margin: "8px 8px",
                          width:"67px",
                          minHeight: "28px",
                          borderRadius:5,
                          fontFamily: "Overpass",
                          fontWeight: 400,
                          fontSize: 18,
                        }}
                      >
                        {value['content']}
                      </td>
                    <td className="col-2">
                    <select onChange={setProfileFunction} data-id={value.id}>
                       <option value={value.profile}>{value.profile}</option>
                      { 
                        value.profileOptions.map((profile,index)=>(
                          profile !== value.profile ?
                          <option value={profile}>{profile}</option>
                          :null
                        ))
                      }
                    </select>
                    </td>
                    <div 
                  style={{ 
                    backgroundColor: (factorSelectionFilterContextValue===value['type'] || factorSelectionFilterContextValue==='all')  ? 'crimson':'lightGray',
                    height: '30px',
                    width:'60px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius:'5px',
                    margin: '8px 10px 0px 10px',
                    cursor: factorSelectionFilterContextValue===value['type']? 'pointer':null,
                  }}
                  data-id={value['id']}
                  onClick={dropToDrag}
                  >
                  <img 
                  src={trash} alt="trash"
                  data-id={value['id']}
                  data-type={value['type']}
                  onClick={dropToDrag}
                  />
                  </div>
                    </tr>
        )})
      }
          </table>
    )
}

export default DropSetValues
