import React from 'react'

function ShowNodeView(props) {
    return (
      <table>
      <tbody style={{border: '3px solid #2f5596', fontfamily: "Poppins", fontSize:"16px"}}>
      {props.showNode!==null? props.showNode.map((val,key)=>(
       <tr key={key} style={{border: '3px solid #2f5596', fontfamily: "Poppins", fontSize:"16px", backgroundColor:'#2f5596'}}>
         {val.map((val2,key2)=>(
           val2.type==="th"?<th style={{border: '3px solid #2f5596', color: 'white', fontfamily: "Poppins", fontSize:"16px", backgroundColor:val2.backgroundColor, maxWidth:"20px", overflowWrap:'break-word'}} rowSpan={val2.rowSpan} colSpan={val2.colSpan}><center>{val2.value}</center></th>
           :<td style={{border: '3px solid #2f5596', fontfamily: "Poppins", fontSize:"16px", backgroundColor:val2.backgroundColor, textAlign: 'center'}} rowSpan={val2.rowSpan} colSpan={val2.colSpan}>{val2.value.toFixed(2)}</td>
         ))}
       </tr>
      )):null}
      </tbody>
      </table>
    )
}

export default ShowNodeView