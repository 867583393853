import React, {useEffect, useContext} from 'react'

//Import contexts
import { ClDragAddEdgeContext,
    ClDropAddEdgeContext,
    ClDragAddEdgeGlobalContext,
    FactorSelectionFilterContext
    } from '../global_values'

function DragAddEdge() {

    const {clDragAddEdgeContextValue, setClDragAddEdgeContextValue} = useContext(ClDragAddEdgeContext)
    const {clDropAddEdgeContextValue, setClDropAddEdgeContextValue} = useContext(ClDropAddEdgeContext)
    const {clDragAddEdgeContextGlobalValue, setClDragAddEdgeContextGlobalValue} = useContext(ClDragAddEdgeGlobalContext)
    const {factorSelectionFilterContextValue, setFactorSelectionFilterContextValue} = useContext(FactorSelectionFilterContext)
   
    useEffect(()=>{
      if(factorSelectionFilterContextValue==="all"){
        setClDragAddEdgeContextValue([...clDragAddEdgeContextGlobalValue])
        return
      }
      setClDragAddEdgeContextValue(clDragAddEdgeContextGlobalValue.filter((element)=>{
          return element['type']===factorSelectionFilterContextValue

      }))
    },[factorSelectionFilterContextValue])
   
    const dragToDrop=(event)=>{

      if(clDropAddEdgeContextValue.length !==0){
        if(clDropAddEdgeContextValue[clDropAddEdgeContextValue.length-1].source && clDropAddEdgeContextValue[clDropAddEdgeContextValue.length-1].destination){
          setClDropAddEdgeContextValue([...clDropAddEdgeContextValue, {source:clDragAddEdgeContextValue.find(element=>element.id.toString()===event.target.dataset.id)}])
        }else{
          clDropAddEdgeContextValue[clDropAddEdgeContextValue.length-1].destination=clDragAddEdgeContextValue.find(element=>element.id.toString()===event.target.dataset.id)
          setClDropAddEdgeContextValue([...clDropAddEdgeContextValue])
        }
      }else{
        setClDropAddEdgeContextValue([...clDropAddEdgeContextValue, {source:clDragAddEdgeContextValue.find(element=>element.id.toString()===event.target.dataset.id)}])       
      }
    }

    return (
        <div
        style={{
          minHeight: 77, 
          display:"flex",
          justifyContent:"space-between",
          flexFlow:"wrap",
        }}>
       {
       clDragAddEdgeContextValue.map((value,index)=>(
            <div
            key={value.id}
            data-id={value.id}
            onClick={dragToDrop}
            style={{
              cursor: "pointer",
              userSelect: "none",
              padding: 16,
              margin: "8px 8px",
              width:'fit-content',
              minHeight: "28px",
              backgroundColor: "blue"
                ? "#081c4d"
                : "#F5FAFD",
              filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
              color: "red" 
              ? "#F5FAFD"
              : "#081c4d",
              borderRadius:5,
              fontFamily: "Overpass",
              fontWeight: 400,
              fontSize: 18,
            }}
           >
             {value['content']}
            </div>
       ))}
        </div>
    )
}

export default DragAddEdge
