import axios from "axios";
import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";

import chroma from 'chroma-js'


import trash from "../assets/trash.svg";

import Loader from '../subViews/Loader';
import Network from '../subViews/network_what_If_Analysis';


// Import Pagination UI Material Library
import { makeStyles } from '@material-ui/core/styles';

// RC-SLIDER
import Slider, { SliderTooltip }  from "rc-slider";
import "rc-slider/assets/index.css";
const { Handle } = Slider;

// RC-SLIDER-TOOLTIP
const handle = props => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${value} %`}
      visible={dragging}
      placement="top"
      key={index}
      // defaultVisible={true}
      // visible={true}
      // align={{
      //   offset: [0, -5],
      // }}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};
const wrapperStyle = { width: 400, margin: 50, color:'red' };
// RC-SLIDER

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
      display:'flex',
      justifyContent:'center',
    },
  },
}));


const forecastName=`default name${Math.random()}`;

const getModels_Dates=`${process.env.REACT_APP_URL_MASTER}/models?fields=modelName,dateInterval`
const getFunds_Factors=`${process.env.REACT_APP_URL_MASTER}/what-if-analysis?name=${forecastName}&&fields=factors`
const getWhat_If_Analysis=`${process.env.REACT_APP_URL_MASTER}/what-if-analysis`
const getWhat_Investment=`${process.env.REACT_APP_URL_MASTER}/investment-strategies`
const getWhat_If_Analysis2=`${process.env.REACT_APP_URL_MASTER}/what-if-analysis?name=${forecastName}`
const jsonNetworkEndPoint = `${process.env.REACT_APP_URL_MASTER}/view_model/get_network`
const urlModelChoices = `${process.env.REACT_APP_URL_MASTER}/view_model/get_model_names`

const red="#f995aa"
const blue="lightblue"
const gray="gray"

const options = {
  indexAxis: 'y',
  // Elements options apply to all of the options unless overridden in a dataset
  // In this case, we are setting the border of each horizontal bar to be 2px wide
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'right',
    },
    title: {
      display: true,
      text: 'Factors Influence',
      position:'top'
    },
  },
}


const CreateForecast = () => {
    const [models, setModels] = useState([])
    const [strategies, setStrategies] = useState([])
    const [tags, setTags] = useState([])
    const [funds, setFunds] = useState([])
    const [explicativeFactors, setExplicativeFactors] = useState([])
    const [chosenModel, setChosenModel] = useState(null)
    const [chosenDate, setChosenDate] = useState(null)
    const [chosenStrategy, setChosenStrategy] = useState(null)
    const [dateInterval, setDateInterval] = useState(null)
    const [jsonData, setJsonData]=useState(null)
    const [pickedFont, setPickedFont]=useState(["#f7fcf5", "#e5f5e0", "#c7e9c0", "#a1d99b", "#74c476", "#41ab5d", "#238b45", "#006d2c", "#00441b"])
    const [chosenFactor, setChosenFactor]= useState(null)
    const [plotVariableInfluenceChart, setPlotVariableInfluenceChart]=useState([])
    const [plotExpectedReturnsChart, setPlotExpectedReturnsChart]=useState([])
    const [error, setError]=useState("");
    const [loadingMode, setLoadingMode]=useState(false);
    const [deletedFactor, setDeletedFactor]=useState(null)
    const [updateD,setUpdateData]=useState(null)

    let history = useHistory();

    const handleNavigation=async()=>{
      //setTimeout(setLoadingMode(true), 1000)
      setLoadingMode(true)
        try{
            if(chosenModel && chosenDate){
                const response = await axios.patch(getWhat_If_Analysis2,
                    {factors:funds.concat(explicativeFactors)}     
                )
                if(parseInt(response.status) === parseInt(200)){                  
                  setLoadingMode(false)
               }
              //variableInfluenceChart
              const res= await axios.get(getWhat_If_Analysis2)

              const factorsLabels=[]
              //const factorsData=[]
              const factorsDataNegative=[]
              const factorsDataNPositive=[]
             // const factorsBackGroundColors=[]
             // const factorsBorderColor=[]
              res.data.variableInfluenceChart.forEach(factor => {
                factorsLabels.push(factor.name)
                //factorsData.push(factor.value*100)
                if(factor.value<0){
                    factorsDataNegative.push(factor.value*100)
                    factorsDataNPositive.push(null)
                   // factorsBackGroundColors.push('crimson')
                   // factorsBorderColor.push('crimson')
                }
                else if(factor.value>0){
                    factorsDataNPositive.push(factor.value*100)
                    factorsDataNegative.push(null)
                   // factorsBackGroundColors.push('green')   
                   // factorsBorderColor.push('green')    
                }
                else {
                    factorsDataNPositive.push(factor.value*100)
                    factorsDataNegative.push(null)
                   // factorsBackGroundColors.push('red') 
                   // factorsBorderColor.push('red')  
                }

            });
            const obj={
              labels: factorsLabels,
              datasets: [
                {
                  label: 'Value > 0',
                  data: factorsDataNPositive,
                  backgroundColor: "green",
                  borderColor:"green",
                  borderWidth: 1,
                },
                {
                  label: 'Value < 0',
                  data: factorsDataNegative,
                  backgroundColor: "crimson",
                  borderColor:"crimson",
                  borderWidth: 1,
                },
              ]
          }
          //expectedReturnsChart 
          const currentTab=[]
          const expectedTab=[]
          const factorsLabels2=[]
          const expectedBackGroundColors=[]
          const currentBackGroundColors=[]
          res.data.expectedReturnsChart.forEach(factor => {
            currentTab.push(factor.actualReturn*100)
            expectedTab.push(factor.expectedReturn*100)
            factorsLabels2.push(factor.name)
            currentBackGroundColors.push('rgb(46, 196, 182)')
            expectedBackGroundColors.push('tomato')
          });
          const obj2={
            labels: factorsLabels2,
            datasets: [
              {
                label: 'Current',
                data: currentTab,
                backgroundColor: currentBackGroundColors,
                borderColor:currentBackGroundColors,
                borderWidth: 1,
              },
              {
                  label: 'Expected',
                  data: expectedTab,
                  backgroundColor: expectedBackGroundColors,
                  borderColor:expectedBackGroundColors,
                  borderWidth: 1,
                },
            ],
          }
          setPlotVariableInfluenceChart(obj)
          setPlotExpectedReturnsChart(obj2)
                history.push({
                    pathname: `/simulation/${forecastName}`,
                    // search: '?query=abc',
                    state: {
                        factors: explicativeFactors.concat(funds),
                        chosenDate:chosenDate,
                        forecastName:forecastName,
                        plotExpectedReturnsChart:obj,
                        plotVariableInfluenceChart: obj2,
                        explicativeFactors: explicativeFactors,
                        funds:funds,
                        chosenModel:chosenModel,
                        updateD:updateD

                    }
                })
            }else{
                setError("Please choose a model before submitting")
            }
        }catch(error){
            console.log('Server error')
        }
        

    }
 
    useEffect(async()=>{

        // get models Names and dates intervals
        const response= await axios.get(getModels_Dates)
        setModels(response.data)
        // get investments
        const response3= await axios.get(getWhat_Investment)
        setStrategies(response3.data)
    },[])

    const dragToDrop=((factor)=>{
        // add the factor to ExplicativeFactors table
        setExplicativeFactors(explicativeFactors=>[...explicativeFactors,factor])
        // remove the factor to ExplicativeFactors list
        setTags(tags.filter(tag=>tag.name!=factor.name))
        setChosenFactor(factor.name)
    })
    const dropToDrag=((factor)=>{
        // add the factor to ExplicativeFactors list
        setTags(explicativeFactors=>[...explicativeFactors,factor])
        // remove the factor to ExplicativeFactors table
        setExplicativeFactors(explicativeFactors.filter(tag=>tag.name!=factor.name))
        // send factor name to network
        setDeletedFactor(factor.name)
    })
    const updateFactorWeight=((e,factorName)=>{
        setChosenFactor(factorName)
        const fundsLocal=[...funds]
        const explicativeFactorsLocal=[...explicativeFactors]
        const factorFunds=fundsLocal.find(fund=>fund.name===factorName)
       // const factorExpFactors=explicativeFactorsLocal.find(fund=>fund.name===factorName)
        
       // case +
        if(e.target.textContent==="+"){
            if(factorFunds && factorFunds.pfWeight<100 && fundsLocal.reduce((a, b) => a + (b['pfWeight'] || 0), 0)<100){
                factorFunds.pfWeight=factorFunds.pfWeight+1
            }
        }
        // case -
        else{
            if(factorFunds && factorFunds.pfWeight>0){
                    factorFunds.pfWeight=factorFunds.pfWeight-1
            }
        }
        setFunds(fundsLocal)
        setExplicativeFactors(explicativeFactorsLocal)
    })
    const handleSetChosenModel=(e)=>{
        if(e.target.value!==""){
            setChosenModel(e.target.value)
            // set min and max values of date 
            const fModel=models.find(model=>model.modelName===e.target.value)
            setDateInterval({min:fModel.dateInterval.min, max:fModel.dateInterval.max})
            //setDateInterval({min:'2017-06-09', max:'2021-09-12'})
        }else{
            setChosenModel(null)
        }
    }
    const handleSetChosenStrategy=(e)=>{
        if(e.target.value!==""){
            setChosenStrategy(e.target.value)
        }else{
            setChosenStrategy(null)
        }       
    }
    const handleSetChosenDate=async (e)=>{

      setExplicativeFactors([]);
        setChosenDate(e.target.value)
        const a={
            name:forecastName, 
            description:'analysis description', 
            modelName:chosenModel, 
            strategy:chosenStrategy, 
            date:e.target.value
            }
             await axios.post(
             getWhat_If_Analysis,
             a
        )

    // set default colors for edges
    let res=await axios.post(jsonNetworkEndPoint,{model_name:chosenModel})
    res.data.edges.forEach(edge => {
      edge.color=gray
    });

    // get max width value
    const maxEntropy=Math.max.apply(Math, res.data.nodes.map(function(o) { return o.entropy; }))
    const minEntropy=Math.min.apply(Math, res.data.nodes.map(function(o) { return o.entropy; }))
    const maxEntropyEdge=Math.max.apply(Math, res.data.edges.map(function(o) { return o.entropy; }))
    //const minEntropyEdge=Math.min.apply(Math, res.data.edges.map(function(o) { return o.entropy; }))

    // get E S G factors
    let E=res.data.nodes.find(node=>node.name==="PortfolioEnvironmentalScore")
    let S=res.data.nodes.find(node=>node.name==="PortfolioGovernanceScore")
    let G=res.data.nodes.find(node=>node.name==="PortfolioSocialScore")
    // star square diamond 
    if(E){
      E.shape="star"
    }
    if(S){
      S.shape="star"
    }
    if(G){
      G.shape="star"
    }


    res.data.nodes.forEach(node=>{
    node.label=node.name  
    //node.color=chroma.scale('puBuGn').domain([maxEntropy,0])(node.entropy).toString()  
    node.color=chroma.scale(pickedFont).domain([minEntropy,maxEntropy])((node.entropy)).toString()  
    node.value=node.entropy       
   })
   const widthCalulWay="entropys"
   res.data.edges.forEach(edge=>{

    if(widthCalulWay==="entropy"){
      edge.width=edge.entropy/maxEntropyEdge*10
      edge.label=edge.entropy
      if(edge.corr>0){
        edge.color=blue
      }
      else if(edge.corr<0){
        edge.color=red
      }
     }
     else{
      if(edge.corr!==0){
        edge.width=Math.abs(edge.corr)*10
        edge.label=edge.corr 
        if(edge.corr>0){
          edge.color=blue
        }
        else if(edge.corr<0){
          edge.color=red
        }  
      } else{
        edge.width=edge.width*10
        edge.label=edge.corr 
        if(edge.corr>0){
          edge.color=blue
        }
        else if(edge.corr<0){
          edge.color=red
        }            
      }   
     }
     if(!edge.corr){
       edge.width=1
     }
   })
    setJsonData(res.data)
    // get funds and explicative factors

    // initialize states to empty arrays
    setTags([])
    setFunds([])
    const response2=await axios.get(getFunds_Factors)
    response2.data.factors.forEach(factor => {
        if(factor.is_target){
            factor.pfWeight=factor.pfWeight*100
            setFunds(funds=>[...funds,factor])
        }else{  
            factor.prob=factor.prob*100
            setTags(tags=>[...tags,factor])
        }
    });
}
    const updateFactorProbFromInput=(prob,factor2)=>{
        const factorsLocal=[...explicativeFactors]
        const factorExplicativeFactors=factorsLocal.find(factor=>factor.name===factor2.name)  
        factorExplicativeFactors.prob=parseFloat(prob)  
        setExplicativeFactors(factorsLocal)
      //  setChosenFactor(factor2.name)
    }
    const updateFactorWeightFromInput=(e,factorName)=>{
        const fundsLocal=[...funds]
        const factorFunds=fundsLocal.find(fund=>fund.name===factorName)  
        factorFunds.pfWeight=parseFloat(e.target.value)  
        setFunds(fundsLocal)
    }
    const updateData=(d)=>{
      setUpdateData({...d})
    }
    return (
    <div style={{opacity: loadingMode ? "0.6" : "" , flex: '1', backgroundColor: "#f5fafd", minHeight: '100vh', marginLeft: '293px'}}>
    <div className="content-main">
      {loadingMode && 
      <div className="loader-on-page">
      <Loader/> 
      </div>
      }
        <div style={{display:'flex', justifyContent:'space-between', alignItems:'flex-start', marginBottom:'40px'}}>
        <h2>Create Simulation</h2>
        </div>
    <div className="edit-model-container" style={{display:'flex', flexDirection:'row', justifyContent:'space-around', alignItems:'center'}}>  
       <div>
           <label>Causal Model</label>
           <br/> <br/>
            <select onChange={handleSetChosenModel}>
            <option value="" disabled selected>Select a model</option>
              {
                 models.map((model,index)=>(
                    <option key={index}>{model.modelName}</option>
                 ))
              }
            </select>
       </div>
       <div style={{visibility:'hidden'}}>
           <label>Predefined Strategies</label>
            <br/> <br/>
            <select onChange={handleSetChosenStrategy}>
                <option value="">No strategy</option>
                {
                 strategies.map((strategie,index)=>(
                    <option>{strategie}</option>
                 ))
                }

            </select>
       </div>
         { dateInterval &&
       <div>

           <label>Choose Date</label>
           <br/> <br/>
            <input type="date" id="start" name="trip-start" 
            min={dateInterval.min} max={dateInterval.max}
            onInput={handleSetChosenDate}
            />  
       </div> 
        }   


    </div>
    <br/><br/>

{ jsonData !==null &&
    <div className="edit-model-container">
    <Network
        networkData={jsonData}
        chosenFactor={chosenFactor}
        deletedFactor={deletedFactor}
        funds={funds}
        updateData={updateData}
    />
        <br/><br/>
        <div style={{display:'flex', alignItems:'flex-start', justifyContent:'space-between'}}>
        <div 
        style={{ width: '100%'}}
        //style={{width:'49%'}}
        >
        {/* <div
         style={{ width:'100%', margin:'0 auto', display:'flex', alignItems:'flex-start', fontFamily: 'Overpass', fontWeight: 'bold', lineHeight: '25px', marginBottom:'13px'}}
        >
            <div style={{flex:'80%', fontSize: '24px'}}>Assets</div>
            <div style={{flex:'20%', fontSize: '18px'}}>Allocation</div>
    </div> */}
    {/* <div className="grey-line"></div> */}
    <div>
    <table className="forecast-table" 
    //style={{ width: simulationReturn ? '50%' : '100%',  margin:'0 auto' }}
    style={{ width:'100%', margin:'0 auto'}}
    >
        <tr>
            <th style={{textAlign:'inherit', width:'80%', marginBottom:'9px'}}>Assets</th>
            <th style={{textAlign:'inherit', width:'20%', marginBottom:'9px'}}>Weight (100%)</th>
        </tr>
        {
         funds.map((fund,index)=>(
           <tr key={fund.name}>
            <td>{fund.name}</td>
            <td>
                <span style={{cursor:"pointer"}} onClick={(e)=>updateFactorWeight(e,fund.name)}>-</span>
                <input type="number" min={0} step={1} max={100} style={{width:"50px",textAlign:"center"}} value={fund.pfWeight} onChange={(e)=>updateFactorWeightFromInput(e,fund.name)}/>
                <span style={{cursor:"pointer"}} onClick={(e)=>updateFactorWeight(e,fund.name)}>+</span>
            </td>
           </tr>
         ))
        }

    </table>
    </div>
    <div className="tags-container">
    <div style={{width:'100%'}}>
    <div className="wrap-collapsible" style={{width:'100%', margin: '0 auto', marginBottom:'20px'}}>
            <input id="collapsible" className="toggle" type="checkbox" defaultChecked={true}/>
            <label for="collapsible" className="lbl-toggle">Explicative Factors List</label>
            <div className="collapsible-content" style={{padding:'inherit'}}>
              <section style={{
                  minheight: '77px',
                  display: "flex",
                  justifyContent: "stretch",
                  flexFlow: "wrap", 
                  padding:'inherit', 
                  background: '#f5fafd',
                  borderRadius:'0 0 10px 10px', 

              }}>
            {tags.map((tag,index)=>(
              <div
              className="tag-on-click"
                onClick={(e)=>dragToDrop(tag)}
                style={{
                  cursor: "pointer",
                  userSelect: "none",
                  padding: 16,
                  margin: "6px 6px",
                  width:'fit-content',
                  borderRadius:'2px',
                  backgroundColor:"white",
                  color:'#081c4d',
                  borderRadius:5,
                  fontFamily: "Overpass",
                  fontWeight: 400,
                  fontSize: 18,
                  display:'flex', 
                  justifyContent:'center', 
                  alignItems:'center', 
                }}
                  >
                {tag.name}
                </div>
                ))}
                </section>
                </div>
                </div>
                </div>

    <div style={{width:'100%'}}>
    <table className="forecast-table" 
    //style={{ width:'50%', margin:'0 auto'}}
    >
        <tr>
            <th style={{textAlign:'inherit', width:'30%', marginBottom:'9px'}}>Explicative Factors</th>
            <th style={{textAlign:'inherit', width:'70%', marginBottom:'9px'}}>Hypothesis</th>
        </tr>
        {
         explicativeFactors.map((expFactor,index)=>(
           console.log(expFactor),
            <tr key={index}>
            <td>{expFactor.name}</td>
            <td>
                {/* <span onClick={(e)=>updateFactorWeight(e,expFactor.name)}>-</span>
                <input type="number" min={0} step="0.01" style={{width:"50px",textAlign:"center"}} value={expFactor.prob} onChange={(e)=>updateFactorProbFromInput(e,expFactor.name)}/>
                <span onClick={(e)=>updateFactorWeight(e,expFactor.name)}>+</span> */}
                      <div style={{width: '90%', margin: 50, display:'flex', justifyContent:'space-around', alignItems:'center' }}>
                      <Slider
                       value={expFactor.prob}
                        min={0}
                        max={100} 
                        step={1}
                        handle={handle}
                        disabled={false }
                        onChange={(e)=>updateFactorProbFromInput(e,expFactor)}
                        tipProps={{visible:true}}
                      />
                      <div style={{marginRight:'-40px', fontWeight:'800'}}>{expFactor.prob}%</div>
                      </div>
            </td>
            <td onClick={(e)=>dropToDrag(expFactor)}><img src={trash} alt="trash"/></td>
        </tr>
          ))
        }
    </table>
    </div>
    </div>
    </div>
{/* Second page PLOTS */}
</div>

        <div style={{width: '100%', margin: '0 auto', display: 'flex', justifyContent: 'flex-end'}}>
        <p className="error">{error}</p>
        <button 
        className="btn-run-simulation"
        onClick={handleNavigation}
        >
         
         <p>RUN SIMULATION</p>
         </button>
        </div>
    </div>
}

    </div>
    </div>
       
  );
}

export default CreateForecast;