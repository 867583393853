import _ from "lodash";
import React, { useState, useContext, useEffect, useRef, useCallback} from "react";
import chroma from 'chroma-js'

//Import contexts
import { 
         ScoringModelContext, 
         ClickDragContext, 
         ClickDropContext,
         AssetPickingFilterContext,
         ClickDndGlobalContext,
         ClDropLayoutFactorsGlobalContext } from '../global_values';
//Import libraries
import axios from 'axios'
import {v4 as uuid} from "uuid";

import { Dropdown } from 'semantic-ui-react';

import {Colorscale} from 'react-colorscales';
import ColorscalePicker from 'react-colorscales';

// Import FontAwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


//Click version
import DragTags2 from "../subViews/DragTags2";
import DropTags2 from "../subViews/DropTags2";

import Network from '../subViews/networkAssetPicking'
import Funds from "../subViews/Funds";
import NetworkSlider from '../subViews/NetworkSlider'
//Import Icons
import environment from "../assets/seeding.svg";
import social from "../assets/social.svg";
import bank from "../assets/bank.svg";
import gain from "../assets/gain.svg";
import loss from "../assets/loss.svg";
import plus from "../assets/plus.svg";
import minus from "../assets/minus.svg";

// constants and variables
const generateReportEndPoint = `${process.env.REACT_APP_URL_MASTER}/analysis_scoring/generate_report2`
// profiles choices endPoint
const profilesChoicesEndPointAll = `${process.env.REACT_APP_URL_MASTER}/analysis_scoring/get_profile_options`;

const jsonNetworkEndPoint = `${process.env.REACT_APP_URL_MASTER}/analysis_scoring/get_network`

const fundEntropyEndPoint =  `${process.env.REACT_APP_URL_MASTER}/analysis_scoring/get_fund_entropy`

const rowsFromBackend = [];

const red="crimson"
const blue="lightblue"


const findType = (result, nodeId) => {
  if(result.E.includes(nodeId)) {
    return "E"
  }
  if(result.S.includes(nodeId)) {
    return "S"
  }
  if(result.G.includes(nodeId)) {
    return "G"
  }
  if(result.Other.includes(nodeId)) {
    return "Other"
}
}

const CustomScoring = () => {
  const {scoringModelContextValue, setScoringModelContextValue} = useContext(ScoringModelContext);

  // click version
  const {clickDragContextValue, setClickDragContextValue} = useContext(ClickDragContext)
  const {clickDropContextValue, setClickDropContextValue} = useContext(ClickDropContext)
  const {assetPickingFilterContextValue, setAssetPickingFilterContextValue} = useContext(AssetPickingFilterContext)
  const {clickDndGlobalContextValue, setClickDndGlobalContextValue} = useContext(ClickDndGlobalContext)
  const {clDropLayoutFactorsGlobalContextValue, setClDropLayoutFactorsGlobalContextValue} = useContext(ClDropLayoutFactorsGlobalContext)

  const [responseServer, setResponseServer] = useState(false);
  const [availableTags, setAvailableTags] = useState({  id : uuid(), tags:[]});
  const [esgFilter, setEsgFilter] = useState("");

  const [jsonData, setJsonData]=useState({}) 
  const [jsonDataGlobal, setJsonDataGlobal]=useState(null) 
  const [pickedFont, setPickedFont]=useState(["#f7fcf5", "#e5f5e0", "#c7e9c0", "#a1d99b", "#74c476", "#41ab5d", "#238b45", "#006d2c", "#00441b"])
  const [currentFund, setCurrentFund]=useState(null)
  const [showColorscalePicker, setShowColorscalePicker]=useState(false)
  const [currentFundEntropys, setCurrentFundEntropys]=useState(null)

  const [universesFromBackend, setUniversesFromBackend]=useState({});
  const [universeChosen, setUniverseChosen] = useState("");
  const [riskChosen, setRiskChosen] = useState(7);
  const [funds, setFunds]=useState([]);
  const [submitButton, setSubmitButton]=useState("initial");
  const [fundInfo, setFundInfo]=useState({});
  const [esgScoreSum, setEsgScoreSum]=useState(false);
  const [esgValuesMS, setEsgValuesMS]=useState({});
  const [esgValuesCM, setEsgValuesCM]=useState({});
  const [msgToUser, setMsgToUser] = useState("");

  const [fold,setFold]=useState(true);

  const [nodeMaxEntropy, setNodeMaxEntropy]=useState(0)
  const [nodeMinEntropy, setNodeMinEntropy]=useState(0)
  const [edgeMaxEntropy, setEdgeMaxEntropy]=useState(0)
  const [edgeMinEntropy, setEdgeMinEntropy]=useState(0)
  const [stepEdgesSlider, setStepEdgesSlider]=useState(0)
  const [stepNodesSlider, setStepNodesSlider]=useState(0)

  
  const [columnsGrouped, setColumnsGrouped]=useState()
  const[factorsGrouped, setFactorsGrouped]=useState([])
  const[expanded, setExpanded]=useState(false)

  const [showNetwork, setShowNetwork]=useState(false)

  const reportingRef = useRef(null);

  const tab = (Object.keys(universesFromBackend));
  const universeOptions = _.map(tab, (universe, index) => ({
    key: index,
    text: `${universe} (${universesFromBackend[universe].length} Funds)`,
    value: universe,
  }));

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${process.env.REACT_APP_URL_MASTER}/analysis_scoring/get_universe_names`);
      setUniversesFromBackend(response.data);
    };
    fetchData();
  }, []);

  // get profiles choices for each tag 
  useEffect(async()=>{
    try{ 
      const result = await axios.post(`${process.env.REACT_APP_URL_MASTER}/view_model/get_esg_types`, 
      {
        model_name:scoringModelContextValue.model_name
      })
      const tagsWithId = {  id : availableTags.id, tags:[]}
    axios.post(profilesChoicesEndPointAll, {analysis_name:scoringModelContextValue.analysis})
    .then(async(res)=>{
      for(const node in res.data.profile_options){
        if(clickDragContextValue.length===0 && clickDropContextValue.length===0){   
          setClickDndGlobalContextValue(clickDragContextValue=>[...clickDragContextValue,{id : uuid(), content:node, profileOptions:res.data.profile_options[node ], weightOptions:[1,2,3,4,5], profile:res.data.profile_options[node][0], weight:1, type: findType(result.data, node.id)}])
          setClickDragContextValue(clickDragContextValue=>[...clickDragContextValue,{id : uuid(), content:node, profileOptions:res.data.profile_options[node ], weightOptions:[1,2,3,4,5], profile:res.data.profile_options[node][0], weight:1, type: findType(result.data, node.id)}])
        }
        tagsWithId.tags.push({id : uuid(), content:node, profileOptions:res.data.profile_options[node ], weightOptions:[1,2,3,4,5], profile:res.data.profile_options[node][0], weight:1, type: findType(result.data, node.id)})
      }
      setAvailableTags(tagsWithId)
    })
    .catch(err=>console.log(err))
    }catch(err){ 
        console.log(err);       
    }
  },[])

  useEffect(async()=>{
    let res=await axios.post(jsonNetworkEndPoint,{analysis_name:scoringModelContextValue.analysis})
      // update entropys
      if(currentFundEntropys!==null){
        let tab=[]
        res.data.nodes.forEach(node => {
          node.entropy=currentFundEntropys[node.name]
          tab.push(node)
        });
        res.data.nodes=[...tab]
      }
      // get max width value
      const maxEntropy=Math.max.apply(Math, res.data.nodes.map(function(o) { return o.entropy; }))
      const minEntropy=Math.min.apply(Math, res.data.nodes.map(function(o) { return o.entropy; }))
      const maxEntropyEdge=Math.max.apply(Math, res.data.edges.map(function(o) { return o.entropy; }))
      const minEntropyEdge=Math.min.apply(Math, res.data.edges.map(function(o) { return o.entropy; }))
      // set min and max entropy's
      setEdgeMaxEntropy(maxEntropyEdge)
      setEdgeMinEntropy(minEntropyEdge)
      setNodeMinEntropy(minEntropy)
      setNodeMaxEntropy(maxEntropy)
      setStepEdgesSlider([(maxEntropyEdge-minEntropyEdge)/res.data.edges.length]+1)
      setStepNodesSlider([(maxEntropy-minEntropy)/res.data.nodes.length]+1)
      // get E S G factors
      let E=res.data.nodes.find(node=>node.name==="PortfolioEnvironmentalScore")
      let S=res.data.nodes.find(node=>node.name==="PortfolioGovernanceScore")
      let G=res.data.nodes.find(node=>node.name==="PortfolioSocialScore")
      // star square diamond 
      if(E){
        E.shape="star"
      }
      if(S){
        S.shape="star"
      }
      if(G){
        G.shape="star"
      }
      
      // star square diamond case upoload csv 
      res.data.nodes.forEach(node => {
      const f=clDropLayoutFactorsGlobalContextValue.find(factor=>factor.name===node.name)
      if(f && f.isTarget){
        node.shape="star"
      }
      });
      res.data.nodes.forEach(node=>{
      node.label=node.name  
      //node.color=chroma.scale('puBuGn').domain([maxEntropy,0])(node.entropy).toString()  
      node.color=chroma.scale(pickedFont).domain([minEntropy,maxEntropy])((node.entropy)).toString()  
      node.value=node.entropy       
     })
     const widthCalulWay="entropys"
     res.data.edges.forEach(edge=>{
       if(widthCalulWay==="entropy"){
        edge.width=edge.entropy/maxEntropyEdge*10
        edge.label=edge.entropy
        if(edge.corr>0){
          edge.color=blue
        }
        else if(edge.corr<0){
          edge.color=red
        }
       }
       else{
        if(edge.corr!==0){
          edge.width=Math.abs(edge.corr)*10
          edge.label=edge.corr 
          if(edge.corr>0){
            edge.color=blue
          }
          else if(edge.corr<0){
            edge.color=red
          }  
        } else{
          edge.width=edge.width*10
          edge.label=edge.corr 
          if(edge.corr>0){
            edge.color=blue
          }
          else if(edge.corr<0){
            edge.color=red
          }            
        }   
       }

     })
    // set titles
    if (currentFund!==null){
      res.data.nodes.forEach(node=>{
        node.label=node.name   
        if(node.name==="PortfolioSocialScore" || node.name==="PortfolioEnvironmentalScore" || node.name==="PortfolioGovernanceScore"){
          node.title=`Causal model : ${currentFund.sources.CM["scoreSum"][node.label]}\nMorning star : ${currentFund.sources.MS["scoreSum"][node.label]}` 
        }else{
          node.title=`Causal model : ${currentFund.sources.CM[node.label]}\nMorning star : ${currentFund.sources.MS[node.label]}` 
        }           
       })
    } 
    setJsonData(res.data)
    setJsonDataGlobal(res.data)

    const test = _.groupBy(res.data.nodes,(c)=>{
      if(c.label?.substr(0, c.label.indexOf(' '))){
        return  c.label?.substr(0, c.label.indexOf(' '));
      }else{
        return  c.label
      } 
    })
    // add targets to test
    const targets=[]
    res.data.nodes.forEach(element => {
      if(clDropLayoutFactorsGlobalContextValue.find(factor=>factor.name===element.name && factor.isTarget)){
        targets.push(element)
        for(const key in test){
          const targetFactor = test[key].find(el=>el.name===element.name)
          if(targetFactor){
            test[key].splice(test[key].indexOf(targetFactor), test[key].indexOf(targetFactor)+1)
          } 
        }
      }
    });
    // if there is targets
    if(targets.length>0){
      test["Targets"]=targets
    }
    setColumnsGrouped(test)
    let tab =[];
    for(const f in test){
      test[f].forEach(e=>{
        tab.push(e.id)
      })
    }
    setFactorsGrouped(tab)
    setShowNetwork(true)
  },[pickedFont,currentFundEntropys])

  const handleUpdateEdgesEntropySliderValue = useCallback((sliderValue)=>{
    // const edges=jsonDataGlobal['edges'].filter(edge=>edge['entropy']>=sliderValue)
     const edges=[]
     jsonDataGlobal['edges'].forEach(edge => {
       if(edge['entropy']<sliderValue){
         edges.push({...edge,color:"#f5fafd"})
       }else{
         const color=jsonDataGlobal['edges'].find(element=>element['id']===edge['id']).color
         edges.push({...edge,color:color})
       }
 
     });
     setJsonData({nodes:jsonData['nodes'], edges:edges})
   },[jsonData, jsonDataGlobal])
 
   const handleUpdateNodesEntropySliderValue = useCallback((sliderValue)=>{
     const nodes=jsonDataGlobal['nodes'].filter(node=>node['entropy']>=sliderValue)
     setJsonData({nodes:nodes, edges:jsonData['edges']})
   },[jsonData, jsonDataGlobal])

  // API POST to post inputs chosen on this page to the back. Backend return success if Ok
  const sendScoringToServerFunction=async(e)=>{
    setResponseServer(false);
    const localScoringValue=[]
    clickDropContextValue.forEach(element => {
      localScoringValue.push({node:element['content'],weight:element['weight'],profile:element['profile']})
    });
    if(universeChosen){
      setMsgToUser("");
      setSubmitButton("initial");
      const response = await axios.post(generateReportEndPoint,{
        analysis_name:scoringModelContextValue.analysis,
        profile:localScoringValue,
        universe_name:universeChosen,
      })
      setSubmitButton("loading");
      if(response.data.success === true){
        setSubmitButton("submitted");
        setResponseServer(true);
        setFold(false);
        setTimeout(() => {
          setSubmitButton("initial");
        }, 3000);
        // API GET to fetch the funds of the chosen universe and the chosen investor's profile from backend
        const resFunds = await axios.get(`${process.env.REACT_APP_URL_MASTER}/analysis_scoring/funds?analysis_name=${scoringModelContextValue.analysis}`);
        setFunds(resFunds.data
          .sort((a,b) => a.sources.CM.score - b.sources.CM.score)
          .map((fund, index) => ({...fund, rankCM : index+1}))
          .sort((a,b) => a.sources.MS.score - b.sources.MS.score)
          .map((fund, index) => ({...fund, rankMS : index+1})));
      }else{
        setResponseServer(false)
        setMsgToUser('Something went wrong. Please, start again');
        setMsgToUser('Something went wrong. Please, start again');
      }
    }else{
      setMsgToUser('Please select a universe before submitting');
    }
  }

    
  const handleChangeStyle =(e)=>{
    if(esgFilter===e){
      setEsgFilter("")
      setAssetPickingFilterContextValue("all")
    }else{
      setEsgFilter(e);
      setAssetPickingFilterContextValue(e)
    }
  }

  const changeNetworkHover = (e) =>{
      // change nodes color
      axios.post(fundEntropyEndPoint,{
        analysis_name:scoringModelContextValue.analysis,
        fund_name:e.fundName
      })
      .then(res=>{
        setCurrentFundEntropys(res.data)
      })
      .catch(err=>console.log(err))
      // change entropy values
      setCurrentFund(e)
      let a={...jsonData}
      a.nodes.forEach(node=>{
        node.label=node.name   
        if(node.name==="PortfolioSocialScore" || node.name==="PortfolioEnvironmentalScore" || node.name==="PortfolioGovernanceScore"){
          node.title=`Causal model : ${e.sources.CM["scoreSum"][node.label]}\nMorning star : ${e.sources.MS["scoreSum"][node.label]}` 
        }else{
          node.title=`Causal model : ${e.sources.CM[node.label]}\nMorning star : ${e.sources.MS[node.label]}` 
        }           
       })
       setJsonData(a)
       setJsonDataGlobal(a)
    }

  const graphRef = useRef(null);

  const handleDisplaySection =()=>{
    setFold(!fold);
  }

  const filterNetworkData = (jsonData) => {
    return {
        edges: jsonData.edges.filter(edge => factorsGrouped.includes(edge.from)  && factorsGrouped.includes(edge.to)),
        nodes: jsonData.nodes.filter(node => factorsGrouped.includes(node.id)),
    }
  } 

  const showCheckboxes =()=> {
    setExpanded(!expanded);
}

  const handleAddFactor=(e)=>{

    const related = columnsGrouped[e.target.value].map(node=>node.id)
     
    if(e.target.checked){
     // setFactorsGrouped([...factorsGrouped, ...related])
     const tab=[...factorsGrouped, ...related]

     setFactorsGrouped([...new Set(tab)])
    } else {
     // setFactorsGrouped(_.without(factorsGrouped, ...related))
     setFactorsGrouped([...new Set(_.without(factorsGrouped, ...related))])
    }
 
  }

  return (
    <div className="main-container">
      <div className="content-main">
        <h1>Asset Picking</h1>
        <div className="edit-model-container">
          <div>
            <div className="fund-info-1">
              <div>{fundInfo && fundInfo.Name}</div>
              <br/>
              <div>{fundInfo && fundInfo.CompanyName}</div>
            </div>
            <div ref={graphRef} className="network">
            <div 
          onClick={()=>setShowColorscalePicker(!showColorscalePicker)}
          className='toggleButton'
          style={{borderColor: '#A2B1C6'}}
        >
        Toggle Colorscale Picker
        </div>
        {showColorscalePicker?
        <div style={{position:"relative", marginTop:"0px"}}>
        <ColorscalePicker 
          colorscale={pickedFont}
          onChange={(e) => {
          setPickedFont(e)
          }}
          />
          </div>
          :null}
<div className="multiselect">
  <div className="selectBox" onClick={showCheckboxes}>
    <select>
      <option>Filter Factors</option>
    </select>
    <div className="overSelect"></div>
    </div>
    {expanded &&
    <div id="checkboxes">
      {Object.keys(columnsGrouped).map((column)=>{
      return (
        <div style={{
          display: 'flex',
          alignItems: 'flex-start',
        }}>
      <label 
      style={{
        paddingTop: '13px',
        paddingLeft: '6px',
      }}
      for={column}>
        <input
        type="checkbox" 
        id={column}
        value={column}
        onChange={handleAddFactor}
        defaultChecked={columnsGrouped[column].find(factor=>factorsGrouped.includes(factor.id))?true:false}
        style={{
          top: '.8rem',
          width: '1.55rem',
          height: '1.55rem',
          marginRight:'20px',
        }}
        />{column}</label>
        </div>
        )
    })}
  </div>
    }
        </div>
        {showNetwork?
          <Network 
              networkData={filterNetworkData(jsonData)}
          />
          :null}
          <NetworkSlider
                min={edgeMinEntropy}
                max={edgeMaxEntropy}
                step={stepEdgesSlider}
                labelName={"Edge Entropy"}
                handleUpdateSliderValue={handleUpdateEdgesEntropySliderValue}
                />
                <NetworkSlider
                min={nodeMinEntropy}
                max={nodeMaxEntropy}
                step={stepNodesSlider}
                labelName={"Node Entropy"}
                handleUpdateSliderValue={handleUpdateNodesEntropySliderValue}
                />
          <Colorscale
          colorscale={pickedFont}
          />
            </div>
            {esgScoreSum && fundInfo &&
              <>
              <div className="fund-info-2">
              <div style={{lineHeight: '50px'}}>ESG SCORE</div>
              <div>Scalnyx Causal Model: <strong>{fundInfo.sources.CM.score}</strong></div>
              <div>Morningstar: <strong>{fundInfo.sources.MS.score}</strong></div>
              </div>

              <div className="esg-icons">
              <div className="esg-icon">
              <div style={{border:"2px solid #197b11",
              borderRadius:"50%"}}>
              <img src={environment} alt="environment icon"/>
              </div>
              <div>
              <div style={{fontWeight: '300', fontSize: '25px', marginBottom:'20px'}}>{esgValuesCM.PortfolioEnvironmentalScore.toString().replace('.', ',')}</div>
              <div style={{fontWeight: '200'}}>{esgValuesMS.PortfolioEnvironmentalScore.toString().replace('.', ',')} by MS</div>
              <div style={{fontWeight: '500', fontSize: '25px', color:'#197b11'}}>Environment</div>
              </div>
              </div>
              <div className="esg-icon">
              <div style={{border:"2px solid #197b11",
              borderRadius:"50%"}}>
              <img src={social} alt="social icon"/>
              </div>
              <div>
              <div style={{fontWeight: '300', fontSize: '25px', marginBottom:'20px'}}>{esgValuesCM.PortfolioSocialScore.toString().replace('.', ',')}</div>
              <div style={{fontWeight: '200'}}>{esgValuesMS.PortfolioSocialScore.toString().replace('.', ',')} by MS</div>
              <div style={{fontWeight: '500', fontSize: '25px', color:'#197b11'}}>Social</div>
              </div>
              </div>
              <div className="esg-icon">
              <div style={{border:"2px solid #197b11",
              borderRadius:"50%"}}>
              <img src={bank} alt="governance icon"/>
              </div>
              <div>
              <div style={{fontWeight: '300', fontSize: '25px', marginBottom:'20px'}}>{esgValuesCM.PortfolioGovernanceScore.toString().replace('.', ',')}</div>
              <div style={{fontWeight: '200'}}>{esgValuesMS.PortfolioGovernanceScore.toString().replace('.', ',')} by MS</div>
              <div style={{fontWeight: '500', fontSize: '25px', color:'#197b11'}}>Governance</div>
              </div>
              </div>
              <div className="esg-icon">
              <div style={{border:"2px solid #FDAD35",
              borderRadius:"50%"}}>
            {fundInfo.sources.MS.M12 > 0 ?
              <img src={gain} alt="money gain icon"/>
              :
              <img src={loss} alt="money loss icon"/>
            }
              </div>
              <div>
              <div style={{fontWeight: '200'}}>{fundInfo.sources.MS.M12.toFixed(2).toString().replace('.', ',')} by MS</div>
              <div style={{fontWeight: '500', fontSize: '25px', color:'#FDAD35'}}>M12</div>
              </div>
              </div>
              </div>
              </>
            }
          </div>
          <br/>
          <br/>
          <div className="btn-add-row">
          <button onClick={handleDisplaySection}>
            {fold ?
              <img src={minus} alt="minus button"/>
              :
              <img src={plus} alt="plus button"/>
            }
            <p>{fold ? 'Hide Investor Profile' : 'Edit Investor Profile'}</p>
            </button>
          </div>
          {fold &&
          <div className="inputs">
            <div className={"universe-dropdown"}>
              <h2>Choose Funds Universe</h2>
              <Dropdown
                search
                selection
                options={universeOptions}
                value={universeChosen}
                onChange={(e, props, b) => {
                  setUniverseChosen(props.value)
                }
                }
              />
            </div>
            <br/>
            <br/>
            <div className="dnd-section">
              <h2>Choose Investor's Profile</h2>
              <div className="filter-values">
                <a
                  className={esgFilter === "E" && "Open1"}
                  onClick={() => {
                    handleChangeStyle("E")
                  }}
                >
                  <div>E</div>
                </a>
                <a
                  className={esgFilter === "S" && "Open2"}
                  onClick={() => {
                    handleChangeStyle("S")
                  }}
                >
                  <div>S</div>
                </a>
                <a
                  className={esgFilter === "G" && "Open3"}
                  onClick={() => {
                    handleChangeStyle("G")
                  }}
                >
                  <div>G</div>
                </a>
                <a
                  className={esgFilter === "Other" && "Open4"}
                  onClick={() => {
                    handleChangeStyle("Other")
                  }}
                >
                  <div>Other</div>
                </a>
              </div>
              <div>
                 <DragTags2></DragTags2>
                 <DropTags2></DropTags2>
              </div>
            </div>

            <br/>
            <br/>
            {/* Initial state */}
            {submitButton === "initial" ?
              <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
              {msgToUser &&
              <div style={{marginBottom:'10px', color:'red'}}>{msgToUser}</div>}
              <div className="wrapper">
                <button onClick={sendScoringToServerFunction}>SUBMIT</button>
              </div>
              </div>
              :
              /* Loading state */
              submitButton === "loading" ?
                <div className="wrapper">
                  <button className="buttonload">
                    <FontAwesomeIcon icon="spinner" spin/>
                  </button>
                </div>
                :
                /* Submitted state */
                submitButton === "submitted" &&
                <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                  {msgToUser &&
                  <div style={{marginBottom:'10px', color:'red'}}>{msgToUser}</div>}
                  <div className="wrapper">
                    <div>DONE</div>
                  </div>
                 </div>
            }
          </div>
          }
          {funds.length >0 &&
          <Funds
            funds={funds}
            responseServer={responseServer}
            riskChosen={riskChosen}
            setRiskChosen={setRiskChosen}
            universeChosen={universeChosen}
            universesFromBackend={universesFromBackend}
            reportingRef={reportingRef}
            setFundInfo={setFundInfo}
            graphRef={graphRef}
            setEsgScoreSum={setEsgScoreSum}
            setEsgValuesCM={setEsgValuesCM}
            setEsgValuesMS={setEsgValuesMS}
            changeNetworkHover={changeNetworkHover}
          />
          }
        </div>
      </div>
    </div>
  )
}

export default CustomScoring;