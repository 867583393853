// hooks importation
import React, { useEffect, useRef, useState } from 'react'

// libraries importation
import { Network} from 'vis-network/standalone/esm/vis-network';

import axios from 'axios';

const changeChosenLabelColor = function (values, id, selected, hovering) {
    values.color = "#FFFFFF"
    values.size += 1
    values.face = "serif"
    values.mod = "bold italic"
    values.strokeWidth = 5
    values.strokeColor = "#000000"
  };

// Constants and variables
const options = {
    autoResize:true,
    height: '100%',
    width: '100%',
    clickToUse:false,
    
  layout: {
      // this would make the tree directed
      hierarchical: {
          enabled:true,
          levelSeparation: 75,
          nodeSpacing:100,
          direction:'UD',
          edgeMinimization: false,
          parentCentralization: false,
          sortMethod:"directed",
          shakeTowards:"leaves",
          blockShifting:true
      },
      randomSeed:2,
      improvedLayout:true,
      clusterThreshold:100
    },
    interaction: {
      tooltipDelay:10,
      selectConnectedEdges:true,
      hoverConnectedEdges:true,
      hover:true,
      hideEdgesOnDrag:true,
      hideNodesOnDrag:false,
      dragView:true,
      dragNodes :true,
      navigationButtons: true,
      keyboard: {
        enabled: false,
        speed: {x: 10, y: 10, zoom: 0.02},
        bindToWindow: true
      },
      zoomSpeed: 1,
      zoomView:true
    },
      nodes: {
        size:18,
        borderWidth:2,
        borderWidthSelected:3,
        chosen:{
          label:changeChosenLabelColor,
        },
          //image, circularImage, diamond, dot, star, triangle, triangleDown, hexagon, square and icon.
        shape: "hexagon",
        scaling: {
            label: {
              min: 8,
              max: 20,
              
            },
        },

      },
      edges:{
        smooth:true,
        arrows:{
          to:true
        }
      },
     
      physics: {
      
        hierarchicalRepulsion: {
          centralGravity: 0,
          avoidOverlap: 0.5,
        },
        solver: "hierarchicalRepulsion",
        adaptiveTimestep:true,
        enabled: true
      },
  }
  

const urlTabProbabilities = `${process.env.REACT_APP_URL_MASTER}/view_model/get_cpt`

const VisNetwork = (props) => {



  // A reference to the div rendered by this component
  const domNode = useRef(null);

  // A reference to the vis network instance
  const network = useRef(null);

  const [dataNetwork, setDataNetwork] = useState()

  useEffect(()=>{
      setDataNetwork(props.dataJson)
  },[props.dataJson])

  useEffect(()=>{
    if(dataNetwork!==null){
    // interactios with the network
    network.current = new Network(domNode.current, dataNetwork, options)
        // gestion of click event on the network
        if(network.current){
            network.current.on('click', function (properties){
                let node = properties['nodes']['0']
                if(network.current.body.nodes[node]){
                    if(props.model_name){
                      const chosingNode=dataNetwork.nodes.find(e=>e.id===node)       
                      axios.post(urlTabProbabilities,{node_name:chosingNode.name, model_name:props.model_name})
                      .then(data=>showNodeFunction(data))
                      .catch(err=>console.log("oulech"))
                    }
                  }
            })
        } 
    }     
  },[dataNetwork]);

  function showNodeFunction(data){
    props.showNodeFunction(data.data.table)
    props.setIsOpen(true);
}

    return (
        <React.Fragment>
            <div style={{height:"700px", width:"100%"}} ref = { domNode } />
        </React.Fragment>
    )
}

export default VisNetwork;