import React, {useState, useEffect, useContext} from 'react'

// import contexts
import {ClDragLayoutFactorsContext, ClDropLayoutFactorsContext, ClDropLayoutFactorsGlobalContext} from '../global_values'

import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
        display:'flex',
        justifyContent:'center',
      },
    },
  }));

const types=["Date", "Categorical", "Numerical", "String"]

const statusFilter=["All", "OFF","ON"]
const typesFilter=["All", "Date", "Categorical", "Numerical", "String"]


function isNullOrWhiteSpace(str) {
  return (!str || str.length === 0 || /^\s*$/.test(str))
}

function FactorsTable(props) {

    const classes = useStyles();

    const {clDragLayoutFactorsContextValue, setClDragLayoutFactorsContextValue} = useContext(ClDragLayoutFactorsContext)
    const {clDropLayoutFactorsContextValue, setClDropLayoutFactorsContextValue} = useContext(ClDropLayoutFactorsContext)
    const {clDropLayoutFactorsGlobalContextValue, setClDropLayoutFactorsGlobalContextValue} = useContext(ClDropLayoutFactorsGlobalContext)



    const [typesCurrentFilter, setTypesCurrentFilter] = useState("All")
    const [statusCurrentFilter, setStatusCurrentFilter] = useState("All")
    const [nameCurrentFilter, setNameCurrentFilter] = useState("")
    const [pageMax, setPageMax] = useState(0)

    const [textButtonShowDeletedFactors, setTextButtonShowDeletedFactors]=useState("hide deleted factors")
    const [showDeletedFactors, setShowDeletedFactors]=useState(true)

    useEffect(()=>{
      if(clDropLayoutFactorsContextValue.length===0){
        const tab=[]
        props["factors"].forEach(factor => {
          setClDropLayoutFactorsGlobalContextValue(
            clDropLayoutFactorsGlobalContextValue=>[...clDropLayoutFactorsGlobalContextValue,
              { name:factor, 
                type:props["factorsTypes"][factor], 
                isTarget:false,
              }
            ]
          )  
          tab.push(
            { name:factor, 
              type:props["factorsTypes"][factor], 
              isTarget:false,
            }         
          )
        }); 
        //clDropLayoutFactorsGlobalContextValue.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        setClDropLayoutFactorsContextValue(tab.filter((e,index)=>index >=0 && index<10)) 
        setPageMax(Math.round(tab.length/10)+1)
      } else {
        setPageMax(Math.round([clDropLayoutFactorsGlobalContextValue.length-clDragLayoutFactorsContextValue.length]/10)+1)      
      }
    },[])



    const handleUpdateType=(factorName,e)=>{
      clDropLayoutFactorsGlobalContextValue.find(factor=>factor.name===factorName).type=e.target.value
      clDropLayoutFactorsContextValue.find(factor=>factor.name===factorName).type=e.target.value
    }  

    const handleUpdateIsTarget=(factorName,e)=>{
      clDropLayoutFactorsGlobalContextValue.find(factor=>factor.name===factorName).isTarget=e.target.checked
      clDropLayoutFactorsContextValue.find(factor=>factor.name===factorName).isTarget=e.target.checked
      setClDropLayoutFactorsContextValue([...clDropLayoutFactorsContextValue])
    }

    const handleFilterTypes=(e)=>{
      setTypesCurrentFilter(e.target.value)
      let filterByName=[]
      if(!isNullOrWhiteSpace(nameCurrentFilter)) {      
        filterByName=clDropLayoutFactorsGlobalContextValue.filter(factor=>factor.name.toLowerCase().startsWith(nameCurrentFilter.toLowerCase()))
      }else {
        filterByName=clDropLayoutFactorsGlobalContextValue
      }
      let filterByTypes=[]
      if(e.target.value != "All"){
        filterByTypes=filterByName.filter(factor=>factor.type===e.target.value)
      }else{
       filterByTypes=filterByName
      }
      let filterByIsTarget=[]
      if(statusCurrentFilter != "All"){
        filterByIsTarget=filterByTypes.filter(factor=>factor.isTarget===statusCurrentFilter)
      }else{
       filterByIsTarget=filterByTypes
      }
      const tab=[]
      filterByIsTarget.forEach(element => {
        const f=clDragLayoutFactorsContextValue.find(factor=>factor.name===element.name)
        if(!f){
          tab.push(element)
        }
      });
      setClDropLayoutFactorsContextValue(tab.filter((e,index)=>index >=0 && index<10))
      setPageMax(Math.round(tab.length/10)+1)
    }
    
    const handleFilterIsTarget=(e)=>{
      // set filter value
      let statusCurrentFilterLocal=false
      if(e.target.value==="ON"){
        setStatusCurrentFilter(true)
        statusCurrentFilterLocal=true
      }
      else if(e.target.value==="OFF"){
        setStatusCurrentFilter(false)
        statusCurrentFilterLocal=false
      } 
      else{
        setStatusCurrentFilter("All") 
        statusCurrentFilterLocal="All"      
      }
      let filterByName=[]
      if(!isNullOrWhiteSpace(nameCurrentFilter)) {
        filterByName=clDropLayoutFactorsGlobalContextValue.filter(factor=>factor.name.toLowerCase().startsWith(nameCurrentFilter.toLowerCase()))
      }else {
        filterByName=clDropLayoutFactorsGlobalContextValue
      }
      let filterByTypes=[]
      if(typesCurrentFilter != "All"){
        filterByTypes=filterByName.filter(factor=>factor.type===typesCurrentFilter)
      }else{
       filterByTypes=filterByName
      }
      let filterByIsTarget=[]
      if(e.target.value != "All"){
        filterByIsTarget=filterByTypes.filter(factor=>factor.isTarget===statusCurrentFilterLocal)
      }else{
       filterByIsTarget=filterByTypes
      }
      const tab=[]
      filterByIsTarget.forEach(element => {
        const f=clDragLayoutFactorsContextValue.find(factor=>factor.name===element.name)
        if(!f){
          tab.push(element)
        }
      });

      setClDropLayoutFactorsContextValue(tab.filter((e,index)=>index >=0 && index<10))
      setPageMax(Math.round(tab.length/10)+1)
    }
    const handleFilterFactorName=(e)=>{
     setNameCurrentFilter(e.target.value)
     let filterByName=[]
     if(!isNullOrWhiteSpace(e.target.value)){
       filterByName=clDropLayoutFactorsGlobalContextValue.filter(factor=>factor.name.toLowerCase().startsWith(e.target.value.toLowerCase()))
     }else{
      filterByName=clDropLayoutFactorsGlobalContextValue
     }
     let filterByTypes=[]
     if(typesCurrentFilter != "All"){
       filterByTypes=filterByName.filter(factor=>factor.type===typesCurrentFilter)
     }else{
      filterByTypes=filterByName
     }
     let filterByIsTarget=[]
     if(statusCurrentFilter != "All"){
      filterByIsTarget=filterByTypes.filter(factor=>factor.isTarget===statusCurrentFilter)
     }else{
      filterByIsTarget=filterByTypes
     }
     const tab=[]
     filterByIsTarget.forEach(element => {
       const f=clDragLayoutFactorsContextValue.find(factor=>factor.name===element.name)
       if(!f){
         tab.push(element)
       }
     });

     setClDropLayoutFactorsContextValue(tab.filter((e,index)=>index >=0 && index<10))
     setPageMax(Math.round(tab.length/10)+1)
    }
    const dropToDrag=(e)=>{
      const tab=[...clDragLayoutFactorsContextValue]
      tab.push(e)
    //  tab.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      setClDragLayoutFactorsContextValue(tab)
      setClDropLayoutFactorsContextValue(clDropLayoutFactorsGlobalContextValue.filter(factor=>{
        if(!tab.find(el=>el.name===factor.name)){
          return factor
        }
      })
      .filter((e,index)=>index >=0 && index<10)
      )    
      setPageMax(Math.round((clDropLayoutFactorsGlobalContextValue.length-tab.length)/10)+1)
    }
    const dragToDrop=(e)=>{
      setClDragLayoutFactorsContextValue(clDragLayoutFactorsContextValue.filter(factor=>factor.name!=e.name))    
      const tab=[...clDropLayoutFactorsContextValue]
      tab.push(e)
    //  tab.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      setClDropLayoutFactorsContextValue(tab) 
      setPageMax(Math.round(tab.length/10)+1)    
    }
    const handleShowDeletedFactors=()=>{
      setShowDeletedFactors(!showDeletedFactors)
      if(textButtonShowDeletedFactors==="hide deleted factors"){
        setTextButtonShowDeletedFactors("show deleted factors")
        return
      }
      setTextButtonShowDeletedFactors("hide deleted factors")
    }
    const handleFilterByPage=(num)=>{
      setClDropLayoutFactorsContextValue(clDropLayoutFactorsGlobalContextValue
        .filter(factor=>!clDragLayoutFactorsContextValue.find(el=>el.name===factor.name))
        .filter((e,index)=>index >=(num-1)*10 && index<num*10))
    }


    const connector = props.connector || undefined;
    const files= props.files || undefined;

    return (
        <div className="create-model-step2">
          <section>
            {connector ? <div>Datasource   /{connector}</div>
            :
            files && 
            files.map((file)=>{
              return(
                <div>Datasets   / {file.name}</div>
              )
            }
            )
            }
          </section>
          <div className="wrap-collabsible">
            <input id="collapsible" className="toggle" type="checkbox"/>
            <label for="collapsible" className="lbl-toggle">Eliminated factors</label>
            <div className="collapsible-content">
              <section style={{
                  minheight: '77px',
                  display: "flex",
                  justifyContent: "flex-start",
                  flexFlow: "wrap"
              }}>
                {clDragLayoutFactorsContextValue.map((factor,index)=>(
              <div
                onClick={(e)=>dragToDrop(factor)}
                style={{
                  cursor: "pointer",
                  userSelect: "none",
                  padding: 16,
                  margin: "16px 16px",
                  width:'fit-content',
                  borderRadius:'2px',
                  backgroundColor:"#e5e5e5",
                  color:'white',
                  borderRadius:5,
                  fontFamily: "Overpass",
                  fontWeight: 400,
                  fontSize: 18,
                  display:'flex', 
                  justifyContent:'center', 
                  alignItems:'center'
                }}
                  >
                    {factor.name}
                </div>
                ))}
                </section>
                </div>
                </div>
                {/* </section> */}
        
            <section>
          <table 
          className="reporting-table table-auto"
          >
            <thead>
            <tr className="factors-table-header" >
              <th className="val-1">Factor name</th>
              <th className="val-2">Type</th>
              <th className="val-3">Target</th>
            </tr>
            <br/>
            <br/>
            <tr className="factors-table-header">
              <th className="val-1">
                <input type="text" onChange={(e)=>handleFilterFactorName(e)}></input>
                </th>
              <th className="val-1">
                <select name="models" id="models-choices" onChange={(e)=>handleFilterTypes(e)}>
                {typesFilter.map((type, index) => (
                  <option key={type}>{type}</option>
                ))
                }
            </select>
              </th>
              <th className="val-1"> 
                <select name="models" id="models-choices" onChange={(e)=>handleFilterIsTarget(e)}>
                {statusFilter.map((state, index) => (
                  <option key={state}>{state}</option>
                ))
                }
            </select>
              </th>
            </tr>
            </thead>
            { 
              clDropLayoutFactorsContextValue
              .map((factor,index)=>(
             <tr className="factor-row table-factors-select" id="dz" key={factor.name}>
              <td className="val-1" key={factor.name}>{factor.name}</td>
              <td className="val-1" key={factor.name?factor.name+"name":Math.random()}>
                <select name="models" id="models-choices" onChange={(e)=>handleUpdateType(factor.name,e)}
                defaultValue={factor.type?factor.type:null}>
                {types.map((type, index) => (
                  <option key={type}>{type}</option>
                ))
                }
            </select>
              </td>
              <td className="val-1" key={factor.isTarget?factor.isTarget.toString():null}>
              <label class="switch">
              <input type="checkbox" id="toggle" className="toggle--checkbox" defaultChecked ={factor.isTarget} onClick={(e)=>handleUpdateIsTarget(factor.name,e)}/>
              <span class="slider round"></span>
              </label>
              <br/><br/>
              </td>
              <div className="close" onClick={(e)=>dropToDrag(factor)}>

                  </div>
                </tr>                
              ))
            }

          </table>
          </section>
          {/* Pagination starts here */}
          <div className={classes.root} style={{marginTop: '30px'}}>
          <Pagination
              count={pageMax}
              onChange={(e, number)=>handleFilterByPage(number)}
              color="#081c4d"/>
          </div>
        </div>           
    )
}


export default FactorsTable
