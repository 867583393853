import React from 'react'
import { useEffect, useContext } from 'react'
import {ClickDragContext, ClickDropContext, AssetPickingFilterContext, ClickDndGlobalContext} from '../global_values'


function DragTags2(props) {

    const {clickDragContextValue, setClickDragContextValue}=useContext(ClickDragContext)
    const {clickDropContextValue, setClickDropContextValue}=useContext(ClickDropContext)
    const {assetPickingFilterContextValue, setAssetPickingFilterContextValue} = useContext(AssetPickingFilterContext)
    const {clickDndGlobalContextValue, setClickDndGlobalContextValue} = useContext(ClickDndGlobalContext)

    useEffect(()=>{
      if(assetPickingFilterContextValue==="all"){
        setClickDragContextValue([...clickDndGlobalContextValue])
        return
      }
      setClickDragContextValue(clickDndGlobalContextValue.filter((element)=>{
        if(!clickDropContextValue.find(e=>e.id===element.id)){
          return element['type']===assetPickingFilterContextValue
        }

      }))
    },[assetPickingFilterContextValue])

    const dragToDrop=(event)=>{
        setClickDropContextValue([...clickDropContextValue, clickDragContextValue.find(element=>element.id===event.target.dataset.id)])
        setClickDragContextValue(clickDragContextValue.filter(element=>element.id!==event.target.dataset.id))
    }

    return (
            <div
            style={{
              minHeight: 77, 
              display:"flex",
              justifyContent:"space-between",
              flexFlow:"wrap",
            }}>
           {
           clickDragContextValue.map((value,index)=>(
                <div
                key={value.id}
                data-id={value.id}
                onClick={dragToDrop}
                style={{
                  cursor: "pointer",
                  userSelect: "none",
                  padding: 16,
                  margin: "8px 8px",
                  width:'fit-content',
                  minHeight: "28px",
                  backgroundColor: "blue"
                    ? "#081c4d"
                    : "#F5FAFD",
                  filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                  color: "red" 
                  ? "#F5FAFD"
                  : "#081c4d",
                  borderRadius:5,
                  fontFamily: "Overpass",
                  fontWeight: 400,
                  fontSize: 18,
                }}
               >
                 {value['content']}
                </div>
           ))}
            </div>
    )
}

export default DragTags2
