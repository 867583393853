import React,{Fragment, useState} from 'react'

function Sliderapp(props) {

    const [valueSlider, setValueSlider]=useState(0)

    const handleUpdateSliderValue=(e)=>{
        setValueSlider(e.target.value)
        props.handleUpdateSliderValue(e.target.value)
    }
    return (
      <Fragment>
        <br/>
        <label for={props.labelName}>{props.labelName} : {parseFloat(valueSlider).toFixed(2)}</label>
        <br/><br/>
        <div className="SliderContaniner">
          <div>{props.min.toFixed(2)}</div>
           <input type="range" id="slider" min={props.min} max={props.max} step={props.step} value={valueSlider} onChange={handleUpdateSliderValue}></input>        
           <div>{props.max.toFixed(2)}</div>
        </div>
      </Fragment>
    )
}

export default Sliderapp
