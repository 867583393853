import _ from "lodash";
import React, { useEffect, useState, useContext, useCallback, Fragment} from "react";

//Import contexts
import { CreatedModelContext, ClDropLayoutFactorsGlobalContext} from '../global_values';
//Import libraries
import {useHistory} from 'react-router-dom';
// Import axios
import axios from "axios";
import chroma, { hsl } from 'chroma-js'
//Import composants 
import Network from "../subViews/network_model_view2";
import TableModal from "../subViews/TableModal";
import Loader from "../subViews/Loader";
import Timeline from "../subViews/Timeline";
import NetworkSlider from '../subViews/NetworkSlider'

import {Colorscale} from 'react-colorscales';
import ColorscalePicker from 'react-colorscales';

const jsonNetworkEndPoint = `${process.env.REACT_APP_URL_MASTER}/view_model/get_network`

const red="#f995aa"
const blue="lightblue"
const gray="gray"

const ViewModel = () => {

  const[modelSummary, setModelSummary]=useState({});

  const {createdModelContextValue, setCreatedModelCoyntextValue} = useContext(CreatedModelContext) 
  const {clDropLayoutFactorsGlobalContextValue, setClDropLayoutFactorsGlobalContextValue} = useContext(ClDropLayoutFactorsGlobalContext)

  const history = useHistory()

  const [nodeProbability, setNodeProbability] = useState([])
  // state to manage the table popin on click on node
  const[isOpen, setIsOpen]=useState(false);
  const [isLoading, setIsLoading]=useState(true);

  const [jsonData, setJsonData]=useState(null) 
  const [jsonDataGlobal, setJsonDataGlobal]=useState(null)
  const [pickedFont, setPickedFont]=useState(["#f7fcf5", "#e5f5e0", "#c7e9c0", "#a1d99b", "#74c476", "#41ab5d", "#238b45", "#006d2c", "#00441b"])
  const [showColorscalePicker, setShowColorscalePicker]=useState(false)

  const [columnsGrouped, setColumnsGrouped]=useState();
  const[factorsGrouped, setFactorsGrouped]=useState([]);
  const[expanded, setExpanded]=useState(false)

  const [nodeMaxEntropy, setNodeMaxEntropy]=useState(0)
  const [nodeMinEntropy, setNodeMinEntropy]=useState(0)
  const [edgeMaxEntropy, setEdgeMaxEntropy]=useState(0)
  const [edgeMinEntropy, setEdgeMinEntropy]=useState(0)
  const [stepEdgesSlider, setStepEdgesSlider]=useState(0)
  const [stepNodesSlider, setStepNodesSlider]=useState(0)

   // get summary from backend
   useEffect(()=>{
    const fetchData = async () => {
      const response = await axios.post(
        `${process.env.REACT_APP_URL_MASTER}/view_model/get_summary`,
        {
          model_name:createdModelContextValue.model_name
        }
      );

      setModelSummary(JSON.parse(response.data.summary));
    }
      fetchData();
},[createdModelContextValue.model_name]);
  
  // navigate to the next page
  const nextPageFunction=()=>{
    // navigate to requirements page with the name of the created model 
    history.push(`/factor-selection/${createdModelContextValue.model_name}`) 
  } 

  const showNodeFunction = useCallback((nodeProbabilities)=>{
    setNodeProbability(nodeProbabilities)
  },[]) 

  const handleUpdateEdgesEntropySliderValue = useCallback((sliderValue)=>{
   // const edges=jsonDataGlobal['edges'].filter(edge=>edge['entropy']>=sliderValue)
    const edges=[]
    jsonDataGlobal['edges'].forEach(edge => {
      if(edge['entropy']<sliderValue){
        edges.push({...edge,color:"#f5fafd"})
      }else{
        const color=jsonDataGlobal['edges'].find(element=>element['id']===edge['id']).color
        edges.push({...edge,color:color})

      }

    });
    setJsonData({nodes:jsonData['nodes'], edges:edges})
  },[jsonData, jsonDataGlobal])

  const handleUpdateNodesEntropySliderValue = useCallback((sliderValue)=>{
    const nodes=jsonDataGlobal['nodes'].filter(node=>node['entropy']>=sliderValue)
    setJsonData({nodes:nodes, edges:jsonData['edges']})
  },[jsonData, jsonDataGlobal])

useEffect(async()=>{
  setTimeout(() => {
    setIsLoading(false)
  }, 1000);
  
    // set default colors for edges
    let res=await axios.post(jsonNetworkEndPoint,{model_name:createdModelContextValue.model_name})
    res.data.edges.forEach(edge => {
      edge.color=gray
    });

    // get max width value
    const maxEntropy=Math.max.apply(Math, res.data.nodes.map(function(o) { return o.entropy; }))
    const minEntropy=Math.min.apply(Math, res.data.nodes.map(function(o) { return o.entropy; }))
    const maxEntropyEdge=Math.max.apply(Math, res.data.edges.map(function(o) { return o.entropy; }))
    const minEntropyEdge=Math.min.apply(Math, res.data.edges.map(function(o) { return o.entropy; }))
    // set min and max entropy's
    setEdgeMaxEntropy(maxEntropyEdge)
    setEdgeMinEntropy(minEntropyEdge)
    setNodeMinEntropy(minEntropy)
    setNodeMaxEntropy(maxEntropy)
    setStepEdgesSlider([(maxEntropyEdge-minEntropyEdge)/res.data.edges.length]+1)
    setStepNodesSlider([(maxEntropy-minEntropy)/res.data.nodes.length]+1)
    // get E S G factors
    let E=res.data.nodes.find(node=>node.name==="PortfolioEnvironmentalScore")
    let S=res.data.nodes.find(node=>node.name==="PortfolioGovernanceScore")
    let G=res.data.nodes.find(node=>node.name==="PortfolioSocialScore")
    // star square diamond 
    if(E){
      E.shape="star"
    }
    if(S){
      S.shape="star"
    }
    if(G){
      G.shape="star"
    }
    // star square diamond case upoload csv 
    res.data.nodes.forEach(node => {
      const f=clDropLayoutFactorsGlobalContextValue.find(factor=>factor.name===node.name)
      if(f && f.isTarget){
        node.shape="star"
      }
    });

    res.data.nodes.forEach(node=>{
    node.label=node.name  
    //node.color=chroma.scale('puBuGn').domain([maxEntropy,0])(node.entropy).toString()  
    node.color=chroma.scale(pickedFont).domain([minEntropy,maxEntropy])((node.entropy)).toString()  
    node.value=node.entropy       
   })
   const widthCalulWay="entropys"
   res.data.edges.forEach(edge=>{

    if(widthCalulWay==="entropy"){
      edge.width=edge.entropy/maxEntropyEdge*10
      edge.label=edge.entropy
      if(edge.corr>0){
        edge.color=blue
      }
      else if(edge.corr<0){
        edge.color=red
      }
     }
     else{
      if(edge.corr!==0){
        edge.width=Math.abs(edge.corr)*10
        edge.label=edge.corr 
        if(edge.corr>0){
          edge.color=blue
        }
        else if(edge.corr<0){
          edge.color=red
        }  
      } else{
        edge.width=edge.width*10
        edge.label=edge.corr 
        if(edge.corr>0){
          edge.color=blue
        }
        else if(edge.corr<0){
          edge.color=red
        }            
      }   
     }
     if(!edge.corr){
       edge.width=1
     }
   })

  setJsonData(res.data)
  setJsonDataGlobal(res.data)
    
  const test = _.groupBy(res.data.nodes,(c)=>{
    if(c.label?.substr(0, c.label.indexOf(' '))){
      return  c.label?.substr(0, c.label.indexOf(' '));
    }else{
      return  c.label
    } 
  })
  // add targets to test
  const targets=[]

  res.data.nodes.forEach(element => {
    if(clDropLayoutFactorsGlobalContextValue.find(factor=>factor.name===element.name && factor.isTarget)){
      targets.push(element)
      for(const key in test){
        const targetFactor = test[key].find(el=>el.name===element.name)
        if(targetFactor){
          test[key].splice(test[key].indexOf(targetFactor), test[key].indexOf(targetFactor)+1)
        } 
      }
    }
  });
  // if there is targets
  if(targets.length>0){
    test["Targets"]=targets
  }

  setColumnsGrouped(test)
  let tab =[];
  for(const f in test){
    test[f].forEach(e=>{
      tab.push(e.id)
    })
  }

  setFactorsGrouped(tab)
},[pickedFont])

  const filterNetworkData = (jsonData) => {
    return {
        edges: jsonData.edges.filter(edge => factorsGrouped.includes(edge.from)  && factorsGrouped.includes(edge.to)),
        nodes: jsonData.nodes.filter(node => factorsGrouped.includes(node.id)),
    }
  } 

  const showCheckboxes =()=> {
    setExpanded(!expanded);
}


const handleAddFactor=(e)=>{

  const related = columnsGrouped[e.target.value].map(node=>node.id)

  if(e.target.checked){

   // setFactorsGrouped([...factorsGrouped, ...related])
   const tab=[...factorsGrouped, ...related]

    setFactorsGrouped([...new Set(tab)])
  } else {

   // setFactorsGrouped(_.without(factorsGrouped, ...related))
    setFactorsGrouped([...new Set(_.without(factorsGrouped, ...related))])
  }
    
}

  return(
    <div className="main-container">
        <div className="content-main">
        <Timeline timelineLevel={4}/>
            <div className="network">
              <div
                onClick={() => setShowColorscalePicker(!showColorscalePicker)}
                className='toggleButton'
                style={{borderColor: '#A2B1C6'}}
              >
                Toggle Colorscale Picker
              </div>
              {showColorscalePicker ?
                <div style={{position: "relative", marginTop: "0px"}}>
                  <ColorscalePicker
                    colorscale={pickedFont}
                    onChange={(e) => {
                      setPickedFont(e)
                    }}
                  />
                </div>
                : null}
              {isLoading ?
                <Loader/>
                :
                <Fragment>
<div className="multiselect">
  <div className="selectBox" onClick={showCheckboxes}>
    <select>
      <option>Filter Factors</option>
    </select>
    <div className="overSelect"></div>
    </div>
    {expanded &&
    <div id="checkboxes">
      {Object.keys(columnsGrouped).map((column)=>{

      return (
        <div style={{
          display: 'flex',
          alignItems: 'flex-start',
        }}>
      <label 
      style={{
        paddingTop: '13px',
        paddingLeft: '6px',
      }}
      for={column}>
        <input
        type="checkbox" 
        id={column}
        value={column}
        onChange={handleAddFactor}
        defaultChecked={columnsGrouped[column].find(factor=>factorsGrouped.includes(factor.id))?true:false}
        style={{
          top: '.8rem',
          width: '1.55rem',
          height: '1.55rem',
          marginRight:'20px',
        }}
        />{column}</label>
        </div>
        )
    })}
  </div>
    }
        </div>
      {/* </div> */}
      <div>
                </div>
                <Fragment>
                <Network
                  model_name={createdModelContextValue.model_name}
                  networkData={filterNetworkData(jsonData)}
                  showNodeFunction={showNodeFunction}
                  setIsOpen={setIsOpen}
                />

    
                <NetworkSlider
                min={edgeMinEntropy}
                max={edgeMaxEntropy}
                step={stepEdgesSlider}
                labelName={"Edge Entropy"}
                handleUpdateSliderValue={handleUpdateEdgesEntropySliderValue}
                />
                <NetworkSlider
                min={nodeMinEntropy}
                max={nodeMaxEntropy}
                step={stepNodesSlider}
                labelName={"Node Entropy"}
                handleUpdateSliderValue={handleUpdateNodesEntropySliderValue}
                />
                </Fragment>
                </Fragment>
              }
              <Colorscale
                colorscale={pickedFont}
              />
            </div>
          <div className="view-model-info">
            <div>
              <div>Name</div>
              <div>{createdModelContextValue.model_name}</div>
            </div>
            <div>
              <div>Description</div>
              <div>{createdModelContextValue.description}</div>
            </div>
            <TableModal
              open={isOpen}
              setIsOpen={setIsOpen}
              onClose={() => setIsOpen(false)}
              showNode={nodeProbability}
            />
          </div>
          <section>
            {Object.keys(modelSummary).map((key) => {
              return (
                <ul>
                  <li>
                    <div>{key}</div>
                    {key === "Original dataset nodes" ? <div>{(modelSummary[key].split(' ').length)} </div> :
                      key === "Nodes to remove" ? <div>{(modelSummary[key].split(' ').length)} </div> :
                        modelSummary[key] === "" ? <div>0</div> :
                          <div>{modelSummary[key]}</div>}
                  </li>
                </ul>
              )
            })}
          </section>
          <div className="icons-buttons">
            <button className="btn-icon" style={{visibility: "hidden"}}>
              Previous
            </button>
            <button className="btn-icon" onClick={nextPageFunction}>
              Next
            </button>
          </div>
        </div>
  </div>
  )
}

export default ViewModel;