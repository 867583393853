import React, { useState, useEffect, useContext, useCallback } from "react";
//Import contexts
import { ScoringModelContext, ClDropLayoutFactorsGlobalContext} from '../global_values'
//Iport libraries
import {useHistory} from 'react-router-dom'
// Import axios
import axios from "axios";
import chroma, { hsl } from 'chroma-js'
//Import composants 
import Network from '../subViews/network_view2'
import TableModal from "../subViews/TableModal";
import Loader from "../subViews/Loader";

import {Colorscale} from 'react-colorscales';
import ColorscalePicker from 'react-colorscales';


const jsonNetworkEndPoint = `${process.env.REACT_APP_URL_MASTER}/analysis_scoring/get_network`

const red="crimson"
const blue="lightblue"

const ViewAnalysis = () => {

  const[modelSummary, setModelSummary]=useState({});

  const {scoringModelContextValue, setScoringModelContextValue} = useContext(ScoringModelContext) 
  const {clDropLayoutFactorsGlobalContextValue, setClDropLayoutFactorsGlobalContextValue} = useContext(ClDropLayoutFactorsGlobalContext)

  const history = useHistory();

  const [nodeProbability, setNodeProbability] = useState([]);
    // state to manage the table popin on click on node
    const[isOpen, setIsOpen]=useState(false);
    const [jsonData, setJsonData]=useState(null) 
    const [pickedFont, setPickedFont]=useState(["#f7fcf5", "#e5f5e0", "#c7e9c0", "#a1d99b", "#74c476", "#41ab5d", "#238b45", "#006d2c", "#00441b"])
    const [currentFund, setCurrentFund]=useState(null)
    const [showColorscalePicker, setShowColorscalePicker]=useState(false);

    const [isLoading, setIsLoading]=useState(true);
  // navigate to the next page
  const nextPageFunction=()=>{
    // navigate to analysis custom scoring 
    history.push(`/custom-scoring/${scoringModelContextValue.analysis}`) 
  } 
  const previewPageFunction=()=>{
      history.push(`/create-analysis`)
  }
  const showNodeFunction = useCallback((nodeProbabilities)=>{
    setNodeProbability(nodeProbabilities)
  },[])

     // get summary from backend
     useEffect(()=>{
      const fetchData = async () => {
        const response = await axios.post(
          `${process.env.REACT_APP_URL_MASTER}/view_model/get_summary`,
          {
            model_name:scoringModelContextValue.model_name
          }
        );
        setModelSummary(JSON.parse(response.data.summary));
      }
        fetchData();
  },[scoringModelContextValue.model_name]);
  // 
  useEffect(async()=>{
    setTimeout(() => {
      setIsLoading(false)
    }, 4000);
    let res=await axios.post(jsonNetworkEndPoint,{analysis_name:scoringModelContextValue.analysis})

      // get max width value
      const maxEntropy=Math.max.apply(Math, res.data.nodes.map(function(o) { return o.entropy; }))
      const minEntropy=Math.min.apply(Math, res.data.nodes.map(function(o) { return o.entropy; }))
      const maxEntropyEdge=Math.max.apply(Math, res.data.edges.map(function(o) { return o.entropy; }))
      const minEntropyEdge=Math.min.apply(Math, res.data.edges.map(function(o) { return o.entropy; }))
      let E=res.data.nodes.find(node=>node.name==="PortfolioEnvironmentalScore")
      let S=res.data.nodes.find(node=>node.name==="PortfolioGovernanceScore")
      let G=res.data.nodes.find(node=>node.name==="PortfolioSocialScore")
      // star square diamond 
      if(E){
        E.shape="star"
      }
      if(S){
        S.shape="star"
      }
      if(G){
        G.shape="star"
      }
      // star square diamond case upoload csv 
      res.data.nodes.forEach(node => {
        const f=clDropLayoutFactorsGlobalContextValue.find(factor=>factor.name===node.name)
        if(f && f.isTarget){
          node.shape="star"
        }
        });
      res.data.nodes.forEach(node=>{
      node.label=node.name  
      //node.color=chroma.scale('puBuGn').domain([maxEntropy,0])(node.entropy).toString()  
      node.color=chroma.scale(pickedFont).domain([minEntropy,maxEntropy])((node.entropy)).toString()  
      node.value=node.entropy       
     })
     const widthCalulWay="entropys"
     res.data.edges.forEach(edge=>{
      if(widthCalulWay==="entropy"){
        edge.width=edge.entropy/maxEntropyEdge*10
        edge.label=edge.entropy
        if(edge.corr>0){
          edge.color=blue
        }
        else if(edge.corr<0){
          edge.color=red
        }
       }
       else{
        if(edge.corr!==0){
          edge.width=Math.abs(edge.corr)*10
          edge.label=edge.corr 
          if(edge.corr>0){
            edge.color=blue
          }
          else if(edge.corr<0){
            edge.color=red
          }  
        } else{
          edge.width=edge.width*10
          edge.label=edge.corr 
          if(edge.corr>0){
            edge.color=blue
          }
          else if(edge.corr<0){
            edge.color=red
          }            
        }   
       }
       if(!edge.corr){
        edge.width=1
      }
     })

    setJsonData(res.data)
  },[pickedFont])

  return(
    <div className="main-container">
    <div className="content-main">
      <div className="view-model-info"> 
      <div>
        <div>Analysis name</div>
        <div>{scoringModelContextValue.analysis}</div>
      </div>
      <div>
        <div>Analysis description</div>
        <div>{scoringModelContextValue.analysis_description}</div>
      </div>
      {/* <div>
        <div>Analysis model</div>
        <div>{scoringModelContextValue.model_name}</div>
      </div> */}
      </div>
      <div className="network">
      <div 
          onClick={()=>setShowColorscalePicker(!showColorscalePicker)}
          className='toggleButton'
          style={{borderColor: '#A2B1C6'}}
        >
        Toggle Colorscale Picker
        </div>
        {showColorscalePicker?
        <div style={{position:"relative", marginTop:"0px"}}>
        <ColorscalePicker 
          colorscale={pickedFont}
          onChange={(e) => {
          setPickedFont(e)
          }}
          />
          </div>
          :null}
        {isLoading ?
          <Loader/>
          :
 <Network  dataJson={jsonData}  model_name={scoringModelContextValue.model_name} showNodeFunction={showNodeFunction} setIsOpen={setIsOpen}></Network>
        }
        <Colorscale
          colorscale={pickedFont}
          />
          </div>

      <TableModal
          open={isOpen} 
          setIsOpen={setIsOpen}
          onClose={()=>setIsOpen(false)}
          showNode={nodeProbability}
      />
      <section>
        {Object.keys(modelSummary).map((key)=>{
          return(
            <ul>
              <li>
                <div>{key}</div>
                {key === "Original dataset nodes" ? <div>{(modelSummary[key].split(' ').length)} </div> :  
                key === "Nodes to remove" ? <div>{(modelSummary[key].split(' ').length)} </div>:
                modelSummary[key] === "" ? <div>0</div>:
                <div>{modelSummary[key]}</div> }         
              </li>
            </ul>
          )
        })}
      </section>

           <div className="icons-buttons">
              <button className="btn-icon" onClick={previewPageFunction}>
                Previous
              </button>
                <button className="btn-icon" onClick={nextPageFunction}>
                  Next
                </button>
            </div>
    </div>
  </div>
  )
}

export default ViewAnalysis;