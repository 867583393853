import {createContext} from "react"
// actions
export const NetworkContext = createContext()
// custom scoring 
export const NetworkDataNetworkContext = createContext()
export const ScoringModelContext = createContext()
// create model, actions
export const NetworkDataNetworkCreateModelContext = createContext()
export const CreatedModelContext = createContext()
export const CommentsRequirementsContext = createContext()
export const RestartDeletedNodesContext = createContext()
export const DeletedNodesEdgesContext = createContext()
// scoring, reporting
export const AnalysisNameContext = createContext()
// click drag and click drop in asset picking
export const ClickDragContext = createContext()
export const ClickDropContext = createContext()
export const ClickDndGlobalContext = createContext()
export const AssetPickingFilterContext = createContext()
// click drag and click drop in factor selection
export const ClDragSetValueContext = createContext()
export const ClDropSetValueContext = createContext()
export const ClDragSetValueGlobalContext = createContext()
export const ClDragSetOrderContext = createContext()
export const ClDropSetOrderContext = createContext()
export const ClDragSetOrderGlobalContext = createContext()
export const ClDragAddEdgeContext = createContext()
export const ClDropAddEdgeContext = createContext()
export const ClDragAddEdgeGlobalContext = createContext()
export const ClDragForbidEdgeContext = createContext()
export const ClDropForbidEdgeContext = createContext()
export const ClDragForbidEdgeGlobalContext = createContext()
export const FactorSelectionFilterContext = createContext()
// click drag and click drop in explore dataset
export const ClDragLayoutFactorsContext = createContext()
export const ClDropLayoutFactorsContext = createContext()
export const ClDropLayoutFactorsGlobalContext = createContext()