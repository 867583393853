import React from "react";

const FactorSelectionButtons = ({requirement, setRequirement}) => {
  return (
    <div className="requirements-links">
    <a className={requirement ==="order" && "Open1"}
       onClick={()=>setRequirement("order")}
    >
        <div>Order Priority</div> 
    </a>
    <a className={requirement ==="edge" && "Open2"}
       onClick={()=>setRequirement("edge")}
    >   
        <div>Add Edge</div>
    </a>
    <a className={requirement ==="forbidEdge" && "Open5"}
       onClick={()=>setRequirement("forbidEdge")}
    >   
        <div>Forbid Edge</div>
    </a>
    <a className={requirement ==="value" && "Open3"}
       onClick={()=>setRequirement("value")}
    >
        <div>Filter Values</div>
    </a>
    <a className={requirement ==="recap" && "Open4"}
       onClick={()=>setRequirement("recap")}
    >
        <div>Summary</div>
    </a>
</div>
  )
}

export default FactorSelectionButtons;