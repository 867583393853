import React, {useState, useContext, useEffect} from "react";
import axios from 'axios'
import { NetworkContext, 

  CreatedModelContext, 
  CommentsRequirementsContext,
  ClDropSetOrderContext,
  ClDropForbidEdgeContext,
  ClDropAddEdgeContext,
  ClDropSetValueContext } from '../global_values'




let requirementsFromBackend = [
  {
    type:"order priority",
  },
  {
    type:"add edge",
  },
  {
    type:"forbid edge",
  },
  {
    type:"set values",
  },
  {
    type:"delete nodes",
  },
  {
    type:"delete edges",
  },
]

const jsonNetworkEndPoint = `${process.env.REACT_APP_URL_MASTER}/view_model/get_network`

const FactorSelectionRecap = (props) => {

  const[requirements, setReaquirements]=useState(requirementsFromBackend);

  const {networkContextValue, setNetworkContextValue} = useContext(NetworkContext)
  const {commentsRequirementsContextValue, setCommentsRequirementsContextValue} = useContext(CommentsRequirementsContext)
  const {clDropSetValueContextValue, setClDropSetValueContextValue} = useContext(ClDropSetValueContext) 
  const {clDropAddEdgeContextValue, setClDropAddEdgeContextValue} = useContext(ClDropAddEdgeContext)
  const {clDropForbidEdgeContextValue, setClDropForbidEdgeContextValue} = useContext(ClDropForbidEdgeContext)
  const {clDropSetOrderContextValue, setClDropSetOrderContextValue} = useContext(ClDropSetOrderContext)


 /* useEffect(async()=>{
    let res=await axios.post(jsonNetworkEndPoint,{model_name:createdModelContextValue.model_name})
        setJsonData(res.data)
  },[])*/
  
  return (
  /*  jsonData!==null? */
    <>
      {requirements && requirements!==null?requirements.map((requirement)=>{
        return(
          <div className="recap-section">
             <div></div>
            <div className="recap-content">
            <div>
            {requirement.type === "order priority" ?
              <table style={{border: "1px solid #081c4d"}}>
               <thead style={{backgroundColor: "#081c4d", color:"#fff"}}>
                <tr>
                 <th colSpan="2">Sequences</th>
               </tr>
               </thead >
               <tbody>
               <tr>
                 <td style={{border: "1px solid #081c4d",color:"#081c4d", textAlign:"center"}}>Tag</td>
                 <td style={{border: "1px solid #081c4d",color:"#081c4d", textAlign:"center"}}>Value</td>
                </tr>
              { clDropSetOrderContextValue.map((o, index)=>(
               <tr>
               <td style={{border: "1px solid #081c4d", textAlign:"center"}}>{o.content}</td>
               <td style={{border: "1px solid #081c4d", textAlign:"center"}}>{o.order}</td>
              </tr>
              ))}
               </tbody>
               <br/>
               <tfoot>
               <tr>
                 <th colSpan="2">Comments</th>
               </tr>
               <tr>
                 <td colSpan="2"  style={{border: "1px solid #081c4d",color:"#081c4d"}}>
                   <pre style={{marginLeft:"2%"}}>{commentsRequirementsContextValue.commentSequence}</pre>
                  </td>
                </tr>
               </tfoot>
              </table>      
        
            :
            requirement.type === "add edge" ?
            <table style={{border: "1px solid #081c4d"}}>
            <thead style={{backgroundColor: "#081c4d", color:"#fff"}}>
             <tr>
              <th colSpan="2">Added Edge(s)</th>
            </tr>
            </thead >
            <tbody>
            <tr>
              <td style={{border: "1px solid #081c4d",color:"#081c4d", textAlign:"center"}}>Source</td>
              <td style={{border: "1px solid #081c4d",color:"#081c4d", textAlign:"center"}}>Destination</td>
             </tr>
           { clDropAddEdgeContextValue.map((o, index)=>(
            <tr>
            <td style={{border: "1px solid #081c4d", textAlign:"center"}}>{o.source?o.source.content:null}</td>
            <td style={{border: "1px solid #081c4d", textAlign:"center"}}>{o.destination?o.destination.content:null}</td>
           </tr>
           ))}
            </tbody>
            <br/>
            <tfoot>
               <tr>
                 <th colSpan="2">Comments</th>
               </tr>
               <tr>
                 <td colSpan="2"  style={{border: "1px solid #081c4d",color:"#081c4d"}}>
                   <pre style={{marginLeft:"2%"}}>{commentsRequirementsContextValue.commentAddEgde}</pre>
                  </td>
                </tr>
               </tfoot>
           </table>  
            :     
            requirement.type === "forbid edge"?
            <table style={{border: "1px solid #081c4d"}}>
            <thead style={{backgroundColor: "#081c4d", color:"#fff"}}>
             <tr>
              <th colSpan="2">Forbided Edge(s)</th>
            </tr>
            </thead >
            <tbody>
            <tr>
              <td style={{border: "1px solid #081c4d",color:"#081c4d", textAlign:"center"}}>Source</td>
              <td style={{border: "1px solid #081c4d",color:"#081c4d", textAlign:"center"}}>Destination</td>
             </tr>
           { clDropForbidEdgeContextValue.map((o, index)=>(
            <tr>
            <td style={{border: "1px solid #081c4d", textAlign:"center"}}>{o.source?o.source.content:null}</td>
            <td style={{border: "1px solid #081c4d", textAlign:"center"}}>{o.destination?o.destination.content:null}</td>
           </tr>
           ))}
            </tbody>
            <br/>
            <tfoot>
               <tr>
                 <th colSpan="2">Comments</th>
               </tr>
               <tr>
                 <td colSpan="2"  style={{border: "1px solid #081c4d",color:"#081c4d"}}>
                   <pre style={{marginLeft:"2%"}}>{commentsRequirementsContextValue.commentAddEgde}</pre>
                  </td>
                </tr>
               </tfoot>
           </table>  
            :
            requirement.type === "set values" ?
            <table style={{border: "1px solid #081c4d"}}>
            <thead style={{backgroundColor: "#081c4d", color:"#fff"}}>
             <tr>
              <th colSpan="2">Setted Value(s)</th>
            </tr>
            </thead >
            <tbody>
            <tr>
              <td style={{border: "1px solid #081c4d",color:"#081c4d", textAlign:"center"}}>Tag</td>
              <td style={{border: "1px solid #081c4d",color:"#081c4d", textAlign:"center"}}>Value</td>
             </tr>
           { clDropSetValueContextValue.map((o, index)=>(
            <tr>
            <td style={{border: "1px solid #081c4d", textAlign:"center"}}>{o.content}</td>
            <td style={{border: "1px solid #081c4d", textAlign:"center"}}>{o.profile}</td>
           </tr>
           ))}
            </tbody>
            <br/>
            <tfoot>
               <tr>
                 <th colSpan="2">Comments</th>
               </tr>
               <tr>
                 <td colSpan="2"  style={{border: "1px solid #081c4d",color:"#081c4d"}}>
                   <pre style={{marginLeft:"2%"}}>{commentsRequirementsContextValue.commentSetValue}</pre>
                  </td>
                </tr>
               </tfoot>
           </table>  
            :
            requirement.type === "delete nodes" ?
            <table style={{border: "1px solid #081c4d"}}>
            <thead style={{backgroundColor: "#081c4d", color:"#fff"}}>
             <tr>
              <th colSpan="2">Deleted Value(s)</th>
            </tr>
            </thead >
            <tbody>
           {networkContextValue.redNodes && networkContextValue.redNodes.length !== 0? 
           networkContextValue.redNodes.map((o, index)=>(
            <tr>
            <td style={{border: "1px solid #081c4d", textAlign:"center"}}>{o}</td>
           </tr>
           ))
          :null}
            </tbody>
           </table> 
            :
            requirement.type === "delete edges" ?
            <table style={{border: "1px solid #081c4d"}}>
            <thead style={{backgroundColor: "#081c4d", color:"#fff"}}>
             <tr>
              <th colSpan="2">Deleted Edge(s)</th>
            </tr>
            </thead >
            <tbody>
            <tr>
              <td style={{border: "1px solid #081c4d",color:"#081c4d", textAlign:"center"}}>Source</td>
              <td style={{border: "1px solid #081c4d",color:"#081c4d", textAlign:"center"}}>Destination</td>
             </tr>
           {networkContextValue.redEdgesFromTo && networkContextValue.redEdgesFromTo.length !== 0? 
           networkContextValue.redEdgesFromTo.map((o, index)=>(
            <tr>
            <td style={{border: "1px solid #081c4d", textAlign:"center"}}>{o.source}</td>
            <td style={{border: "1px solid #081c4d", textAlign:"center"}}>{o.destination}</td>
           </tr>
           ))
          :null}
            </tbody>
           </table>  
            :null
          } 
          </div>

          {/*<div>
            <div>Comments</div>
            <div>{props.comment}</div>
          </div>*/}
          </div>
          </div>
        )
      }):null}
   </>
  /* :null */
  )
}

export default FactorSelectionRecap;
