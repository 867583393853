import React from "react"
import {useHistory} from 'react-router-dom'
// Import Icons
import next from "../assets/next.svg"


const Home = () => {

  let history = useHistory()

  function nextPageFunction(e){
    history.push("/create-model")
  }

  return (
      <div className="main-container">
        <div className="content-main">
          <h1>Let's start creating your ESG financial model together</h1>
          <button
            style={{
              width: '270px',
              height: '75px',
              color: 'white'
            }}
            onClick={nextPageFunction}>
            <p>Click here to start</p>
            <img src={next} alt="next icon" />
          </button>
        </div>
      </div>
  )
}

export default Home;