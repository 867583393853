import React from 'react'
import { useContext } from 'react'
import {ClickDropContext, ClickDragContext, AssetPickingFilterContext} from '../global_values'
// RC-SLIDER
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import trash from "../assets/trash.svg"
function DropTags2() {

    const {clickDropContextValue, setClickDropContextValue}=useContext(ClickDropContext)
    const {clickDragContextValue, setClickDragContextValue}=useContext(ClickDragContext)
    const {assetPickingFilterContextValue, setAssetPickingFilterContextValue} = useContext(AssetPickingFilterContext)

    const dropToDrag=(event)=>{
        if(event.target.dataset.type===assetPickingFilterContextValue || "all"===assetPickingFilterContextValue){
          setClickDragContextValue([...clickDragContextValue, clickDropContextValue.find(element=>element.id===event.target.dataset.id)])
          setClickDropContextValue(clickDropContextValue.filter(element=>element.id!==event.target.dataset.id))
        }
    }
    const setWeightFunction=((weight,row)=>{
         const tag=clickDropContextValue.find(element=>element.id===row.id)
         tag.weight=weight
    })
    const setProfileFunction=((e)=>{
      const tag=clickDropContextValue.find(element=>element.id===e.target.dataset.id)
      tag.profile=e.target.value
    })
    return (
        <table className="responsive-table">
        <tr className="table-header">
          <th className="col col-1">Investment’s type</th>
          <th className="col col-2">Profile</th>
          <th className="col col-3">Weight</th>
        </tr>
        {
        clickDropContextValue.map((value, index) => {
              return (
                    <tr className="table-row"
                    key={value['id']}
                    > 
                        <td className="col-1"
                        style={{
                          userSelect: "none",
                          margin: "8px 8px",
                          width:"67px",
                          minHeight: "28px",
                          borderRadius:5,
                          fontFamily: "Overpass",
                          fontWeight: 400,
                          fontSize: 18,
                        }}
                      >
                        {value['content']}
                      </td>
                    <td className="col-2">
                    <select onChange={setProfileFunction} data-id={value.id}>
                       <option value={value.profile}>{value.profile}</option>
                      { 
                        value.profileOptions.map((profile,index)=>(
                          profile !== value.profile ?
                          <option value={profile}>{profile}</option>
                          :null
                        ))
                      }
                    </select>
                    </td>
                    <td className="col-3">
                      <div className="slider-bar">
                      <Slider
                        min={value.weightOptions[0]}
                        max={value.weightOptions[value.weightOptions.length-1]}
                        step={1}
                        defaultValue={value.weight}
                        onChange={(e)=>setWeightFunction(e,value)}
                        trackStyle={{ backgroundColor: '#081c4d', height: 10 }}
                        handleStyle={{
                          borderColor: '#081c4d',
                          height: 28,
                          width: 28,
                          marginTop: -9,
                          backgroundColor: '#081c4d',
                        }}
                      />
                      </div>
                    </td>         
                    <div 
                  style={{ 
                    backgroundColor: assetPickingFilterContextValue===value['type'] || assetPickingFilterContextValue==='all'? 'crimson':'lightGray',
                    height: '30px',
                    width:'60px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius:'5px',
                    margin: '8px 10px 0px 10px',
                    cursor: assetPickingFilterContextValue===value['type']? 'pointer':null,
                  }}
                  data-id={value['id']}
                  onClick={dropToDrag}
                  >
                  <img 
                  src={trash} alt="trash"
                  data-id={value['id']}
                  data-type={value['type']}
                  onClick={dropToDrag}
                  />
                  </div>
                    </tr>
        )})
      }
          </table>
    )
}

export default DropTags2
