import React, {useEffect, useContext} from 'react'

//Import contexts
import { ClDragForbidEdgeContext,
    ClDropForbidEdgeContext,
    ClDragForbidEdgeGlobalContext,
    FactorSelectionFilterContext
    } from '../global_values'

function DragAddEdge() {

    const {clDragForbidEdgeContextValue, setClDragForbidEdgeContextValue} = useContext(ClDragForbidEdgeContext)
    const {clDropForbidEdgeContextValue, setClDropForbidEdgeContextValue} = useContext(ClDropForbidEdgeContext)
    const {clDragForbidEdgeContextGlobalValue, setClDragForbidEdgeContextGlobalValue} = useContext(ClDragForbidEdgeGlobalContext)
    const {factorSelectionFilterContextValue, setFactorSelectionFilterContextValue} = useContext(FactorSelectionFilterContext)
   
    useEffect(()=>{
      if(factorSelectionFilterContextValue==="all"){
        setClDragForbidEdgeContextValue([...clDragForbidEdgeContextGlobalValue])
        return
      }
      setClDragForbidEdgeContextValue(clDragForbidEdgeContextGlobalValue.filter((element)=>{
          return element['type']===factorSelectionFilterContextValue

      }))
    },[factorSelectionFilterContextValue])
   
    const dragToDrop=(event)=>{

      if(clDropForbidEdgeContextValue.length !==0){
        if(clDropForbidEdgeContextValue[clDropForbidEdgeContextValue.length-1].source && clDropForbidEdgeContextValue[clDropForbidEdgeContextValue.length-1].destination){
          setClDropForbidEdgeContextValue([...clDropForbidEdgeContextValue, {source:clDragForbidEdgeContextValue.find(element=>element.id.toString()===event.target.dataset.id)}])
        }else{
          clDropForbidEdgeContextValue[clDropForbidEdgeContextValue.length-1].destination=clDragForbidEdgeContextValue.find(element=>element.id.toString()===event.target.dataset.id)
          setClDropForbidEdgeContextValue([...clDropForbidEdgeContextValue])
        }
      }else{
        setClDropForbidEdgeContextValue([...clDropForbidEdgeContextValue, {source:clDragForbidEdgeContextValue.find(element=>element.id.toString()===event.target.dataset.id)}])       
      }
    }

    return (
        <div
        style={{
          minHeight: 77, 
          display:"flex",
          justifyContent:"space-between",
          flexFlow:"wrap",
        }}>
       {
       clDragForbidEdgeContextValue.map((value,index)=>(
            <div
            key={value.id}
            data-id={value.id}
            onClick={dragToDrop}
            style={{
              cursor: "pointer",
              userSelect: "none",
              padding: 16,
              margin: "8px 8px",
              width:'fit-content',
              minHeight: "28px",
              backgroundColor: "blue"
                ? "#081c4d"
                : "#F5FAFD",
              filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
              color: "red" 
              ? "#F5FAFD"
              : "#081c4d",
              borderRadius:5,
              fontFamily: "Overpass",
              fontWeight: 400,
              fontSize: 18,
            }}
           >
             {value['content']}
            </div>
       ))}
        </div>
    )
}

export default DragAddEdge
