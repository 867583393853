import React, {useState, useEffect} from "react";

// import Icons
import dragFiles from "../assets/dragFiles.svg";


 const DragAndDrop = (props) => {
    const [drag, setDrag]=useState(false);
    
    const dropRef = React.createRef()

    let dragCounter = 0;

    const handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }
    
    const handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        dragCounter++  
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDrag(true);
          }
    }
    
    const handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        dragCounter--
        if (dragCounter === 0) {
            setDrag(false);
          }
    }

    const handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDrag(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
          props.handleDrop(e.dataTransfer.files)
          e.dataTransfer.clearData()
          dragCounter = 0    
        }
      }
    

      useEffect(() => {
         let div = dropRef.current
         div.addEventListener('dragenter', handleDragIn)
         div.addEventListener('dragleave', handleDragOut)
         div.addEventListener('dragover', handleDrag)
         div.addEventListener('drop', handleDrop)
      }, []); 
  
  return (
    <div 
    style={{display: 'inline-block', position: 'relative', textAlign:'center'}}
    ref={dropRef}>
         {drag &&
          <div 
            style={{
              border: 'dashed grey 4px',
              backgroundColor: 'rgba(255,255,255,.8)',
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0, 
              right: 0,
              zIndex: 9999, 
             
            }}
          >
            <div 
              style={{
                position: 'absolute',
                top: '50%',
                right: 0,
                left: 0,
                textAlign: 'center',
                color: '#2f5596',
                fontSize: 26,
              }}
            >
              <img src={dragFiles} alt="drag icon"/>
              <div style={{fontFamily:'Montserrat',fontWeight:'600', fontSize:'18px', lineHeight:'25px', color:'#1a1e43', marginTop:'15px'}}>Drag CSV file to Upload</div>
            </div>
          </div>
          }
        {props.children}
    </div>
  );

}

export default DragAndDrop;