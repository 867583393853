

/*import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from "axios";
import VerticalBarChart from '../subViews/VerticalBarChart';

const rand = () => Math.round(Math.random() * 20 - 10);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
    },
    title: {
      display: true,
      text: 'Performance/ Risk map',
      position:'top'
    },
  },
}

const MultiType = ({forecastName, chosenDate, factors}) => {

  const [sharpeRatio,setSharpeRatio]=useState()
  const [whatIfAnalysisReturn,setWhatIfAnalysisReturn]=useState()

  const [data, setData] = useState({
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        type: 'line',
        label: 'Dataset 1',
        borderColor: 'rgb(54, 162, 235)',
        borderWidth: 2,
        fill: false,
        data: [rand(), rand(), rand(), rand(), rand(), rand()],
      },
      {
        type: 'bubble',
        label: 'Dataset 2',
        backgroundColor: 'rgb(255, 99, 132)',
        data: [{x:"January",y:rand(),r:7}, {x:"February",y:rand(),r:7}, rand(), rand(), rand(), rand(), rand()],
        borderColor: 'white',
        borderWidth: 2,
      },
    ],
  })

  useEffect(async()=>{
    let labels=[]
    const bubbleData=[]
    const plotData=[]
    const plot2Data=[]
    if(forecastName!=null){
      const getWhat_If_Analysis=`${process.env.REACT_APP_URL_MASTER}/what-if-analysis?name=${forecastName}`
      const res=await axios.get(getWhat_If_Analysis)

      labels=res.data.plot.line.x

      labels.push(res.data.plot.bubble['Current Portfolio'].x*100)
      labels.push(res.data.plot.bubble['Expected Portfolio'].x*100)
      labels=labels.sort(function(a, b) {
        return a - b;
      })
      for (let i=0;i<labels.length;i++){
        labels[i]=(labels[i]*100).toFixed(2)
        plotData.push(
        {x:(res.data.plot.line.x[i]*100), y:res.data.plot.line.y['Current Efficient Frontier'][i]*100}
        )
        plot2Data.push(
        {x:(res.data.plot.line.x[i]*100), y:res.data.plot.line.y['Expected Efficient Frontier'][i]*100}
        )
      }   
      setData({
        labels:labels,
        datasets:[
          {
            type: 'bubble',
            label: 'Current Efficient Frontier',
            backgroundColor: 'rgb(46, 196, 182)',
            data:[...plotData,{
              x:res.data.plot.bubble['Current Portfolio'].x*100,
              y:res.data.plot.bubble['Current Portfolio'].y*100,
              r:res.data.plot.bubble['Current Portfolio'].r,
            }].sort(function(a, b) {
              return a.y - b.y;
            }),
            fill:false
          },  
          {
            type: 'bubble',
            label: 'Expected Efficient Frontier',
            backgroundColor: 'tomato',
            data: [...plot2Data,{
              x:res.data.plot.bubble['Expected Portfolio'].x*100,
              y:res.data.plot.bubble['Expected Portfolio'].y*100,
              r:res.data.plot.bubble['Expected Portfolio'].r,
            }].sort(function(a, b) {
              return a.y - b.y;
            }),
            fill:false
          },  
        ]
      })
      setWhatIfAnalysisReturn(res.data.return)
      setSharpeRatio(res.data.sharpeRatio)
    }
  },[forecastName])

  return(
  <>
  <div style={{display:'flex', alignItems:'flex-end'}}>
<div style={{flex:'50%'}}>
<Bar data={data} options={options}/>
</div>
<div style={{flex:'50%'}}>
 <VerticalBarChart whatIfAnalysisReturn={whatIfAnalysisReturn} sharpeRatio={sharpeRatio}/>
 
</div>
</div>
  </>
)
}

export default MultiType;*/



import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from "axios";
import VerticalBarChart from '../subViews/VerticalBarChart';

const rand = () => Math.round(Math.random() * 20 - 10);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
    },
    title: {
      display: true,
      text: 'Performance/ Risk map',
      position:'top'
    },
  },
}

const MultiType = ({forecastName, chosenDate, factors}) => {

  const [sharpeRatio,setSharpeRatio]=useState()
  const [whatIfAnalysisReturn,setWhatIfAnalysisReturn]=useState()

  const [data, setData] = useState({
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        type: 'line',
        label: 'Dataset 1',
        borderColor: 'rgb(54, 162, 235)',
        borderWidth: 2,
        fill: false,
        data: [rand(), rand(), rand(), rand(), rand(), rand()],
      },
      {
        type: 'bubble',
        label: 'Dataset 2',
        backgroundColor: 'rgb(255, 99, 132)',
        data: [{x:"January",y:rand(),r:7}, {x:"February",y:rand(),r:7}, rand(), rand(), rand(), rand(), rand()],
        borderColor: 'white',
        borderWidth: 2,
      },
    ],
  })

  useEffect(async()=>{
    let labels=[]

    const plotData=[]
    const plot2Data=[]
    if(forecastName!=null){
      const getWhat_If_Analysis=`${process.env.REACT_APP_URL_MASTER}/what-if-analysis?name=${forecastName}`
      const res=await axios.get(getWhat_If_Analysis)
      console.log(res.data.plot)
      labels=res.data.plot.line.x
      labels.push(res.data.plot.bubble['Current Portfolio'].x)
      labels.push(res.data.plot.bubble['Expected Portfolio'].x)
      labels=labels.sort(function(a, b) {
        return a - b;
      })
      for (let i=0;i<labels.length;i++){
        labels[i]=(labels[i]*100).toFixed(2)
        plotData.push(
        {x:(res.data.plot.line.x[i]*100), y:res.data.plot.line.y['Current Efficient Frontier'][i]*100}
        )
        plot2Data.push(
        {x:(res.data.plot.line.x[i]*100), y:res.data.plot.line.y['Expected Efficient Frontier'][i]*100}
        )
      }   
      setData({
        labels:labels,
        datasets:[

          {
            type: 'bubble',
            label: 'Current Portfolio',
            backgroundColor: 'rgb(46, 196, 182)',
            data: [{
              x:(res.data.plot.bubble['Current Portfolio'].x*100).toFixed(2),
              y:(res.data.plot.bubble['Current Portfolio'].y*100),
              r:res.data.plot.bubble['Current Portfolio'].r,
            }],
            //fill:true
          },
          {
            type: 'bubble',
            label: 'Expected Porfolio',
            backgroundColor: 'tomato',
            data: [{
              x:(res.data.plot.bubble['Expected Portfolio'].x*100).toFixed(2),
              y:(res.data.plot.bubble['Expected Portfolio'].y*100),
              r:res.data.plot.bubble['Expected Portfolio'].r,
            }],
            //fill:true
          },
          {
            type: 'line',
            label: 'Current Efficient Frontier',
            backgroundColor: 'rgb(46, 196, 182)',
            data:plotData,
          },  
          {
            type: 'line',
            label: 'Expected Efficient Frontier',
            backgroundColor: 'tomato',
            data: plot2Data,
          }, 
        ]
      })
      setWhatIfAnalysisReturn(res.data.return)
      setSharpeRatio(res.data.sharpeRatio)
    }
  },[forecastName])

  return(
  <>
  <div style={{display:'flex', alignItems:'flex-end'}}>
<div style={{flex:'50%'}}>
<Bar data={data} options={options}/>
</div>
<div style={{flex:'50%'}}>
 <VerticalBarChart whatIfAnalysisReturn={whatIfAnalysisReturn} sharpeRatio={sharpeRatio}/>
 
</div>
</div>
  </>
)
}

export default MultiType;
