import React, {useContext} from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import NavigationBarLink from "./NavigationBarLink";
//Import contexts
import {  CreatedModelContext } from '../global_values'

// Import of Logo 
import logo from "../assets/white-logo.png";

const NavigationBar = () => {
  let history = useHistory();
  const { pathname } = useLocation();

  const {createdModelContextValue, setCreatedModelContextValue} = useContext(CreatedModelContext)

  return (
        <div className="side-menu-container">   
         <img src={logo} alt="logo de Scalnyx" style={{objectFit:"cover", width:"90%", margin:"30px 15px"}}
         onClick={()=>history.push("/")}
         /> 
          <div className="menu-pages">
            <NavigationBarLink to="/create-model" imgSrc="PlusIcon" txt="New Model" />
            <NavigationBarLink to={`/model/${createdModelContextValue.model_name}`} imgSrc="ViewIcon" txt="View" />
            <NavigationBarLink
              to={`/factor-selection/${createdModelContextValue.model_name}`}
              isActive={() => ['/factor-selection'].includes(pathname) || pathname.startsWith('/factor-selection') || pathname.startsWith('/custom-model')}
              imgSrc="ActionsIcon" txt="Factor selection"/>
              <NavigationBarLink 
              to="/create-simulation" 
              isActive={() => ["/create-simulation" ].includes(pathname) || pathname.startsWith("/simulation" )}
              imgSrc="ForecastIcon" txt="What if analysis"/>
            <NavigationBarLink
              to="/create-analysis"
              isActive={() => ['/create-analysis'].includes(pathname) || pathname.startsWith('/analysis') || pathname.startsWith('/custom-scoring')}
              imgSrc="ScoreIcon" txt="Asset Picking"/>
            <NavigationBarLink to="/data-sources" imgSrc="DataIcon" txt="Data Sources"/>
            <NavigationBarLink to="/portfolio" imgSrc="PortfolioIcon" txt="Portfolio Analysis"/>
            <NavigationBarLink to="/risk-analysis" imgSrc="RiskIcon" txt="Risk Analysis" />
            <NavigationBarLink to="/reporting" imgSrc="ReportIcon" txt="Reporting" />
            <NavigationBarLink to="/models" imgSrc="ModelsIcon" txt="All models" />
            </div>
          </div>
      );
}

export default NavigationBar;
