import React, {useEffect, useContext} from 'react'

//Import contexts
import { ClDragSetOrderContext,
         ClDropSetOrderContext,
         ClDragSetOrderGlobalContext,
         FactorSelectionFilterContext
         } from '../global_values'

function DropFixValues2() {

    const {clDragSetOrderContextValue, setClDragSetOrderContextValue} = useContext(ClDragSetOrderContext)
    const {clDropSetOrderContextValue, setClDropSetOrderContextValue} = useContext(ClDropSetOrderContext) 
    const {clDragSetOrderContextGlobalValue, setClDragSetOrderContextGlobalValue} = useContext(ClDragSetOrderGlobalContext)
    const {factorSelectionFilterContextValue, setFactorSelectionFilterContextValue} = useContext(FactorSelectionFilterContext)

    useEffect(()=>{
      if(factorSelectionFilterContextValue==="all"){
        setClDragSetOrderContextValue([...clDragSetOrderContextGlobalValue.filter((element)=>!clDropSetOrderContextValue.find(e=>e.id===element.id))])
        return
      }
      setClDragSetOrderContextValue(clDragSetOrderContextGlobalValue.filter((element)=>{
        if(!clDropSetOrderContextValue.find(e=>e.id===element.id)){
          return element['type']===factorSelectionFilterContextValue
        }
      }))
    },[factorSelectionFilterContextValue])

    const dragToDrop=(event)=>{
      setClDropSetOrderContextValue([...clDropSetOrderContextValue, clDragSetOrderContextValue.find(element=>element.id.toString()===event.target.dataset.id)])
      setClDragSetOrderContextValue(clDragSetOrderContextValue.filter(element=>element.id.toString()!==event.target.dataset.id))
  }

    return (
        <div
        style={{
          minHeight: 77, 
          display:"flex",
          justifyContent:"space-between",
          flexFlow:"wrap",
        }}>
       {
       clDragSetOrderContextValue.map((value,index)=>(
            <div
            key={value.id}
            data-id={value.id}
            onClick={dragToDrop}
            style={{
              cursor: "pointer",
              userSelect: "none",
              padding: 16,
              margin: "8px 8px",
              width:'fit-content',
              minHeight: "28px",
              backgroundColor: "blue"
                ? "#081c4d"
                : "#F5FAFD",
              filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
              color: "red" 
              ? "#F5FAFD"
              : "#2F5596",
              borderRadius:5,
              fontFamily: "Overpass",
              fontWeight: 400,
              fontSize: 18,
            }}
           >
             {value['content']}
            </div>
       ))}
        </div>
    )
}

export default DropFixValues2
