import React, {useContext} from 'react'
import forward from "../assets/forward.svg"
import trash from "../assets/trash.svg"
//Import contexts
import { ClDropForbidEdgeContext,
    FactorSelectionFilterContext
    } from '../global_values'

function DropAddEdge() {

    const {clDropForbidEdgeContextValue, setClDropForbidEdgeContextValue} = useContext(ClDropForbidEdgeContext)
    const {factorSelectionFilterContextValue, setFactorSelectionFilterContextValue} = useContext(FactorSelectionFilterContext)

    const dropToDrag=(event)=>{

        if(event.target.dataset.source && event.target.dataset.destination){
            setClDropForbidEdgeContextValue(clDropForbidEdgeContextValue.filter((element)=>{
                if(element.destination){
                   return element.source.id.toString()!==event.target.dataset.source ||
                    element.destination.id.toString()!==event.target.dataset.destination
                }else{
                    return element.source.id.toString()!==event.target.dataset.source
                }
            }
            ))
        }
        if(event.target.dataset.source && !event.target.dataset.destination){
            setClDropForbidEdgeContextValue(clDropForbidEdgeContextValue.filter(element=>
                element.source.id.toString()!==event.target.dataset.source 
            ))            
        }
    }

    return (
        <div style={{
            width:'100%',
            margin:'50px auto',
            display:"flex",
            flexDirection:"column",
        }}
        key="dest_zone">
          {
            clDropForbidEdgeContextValue.map((value,key)=>(
            <div key={value.source.id} style={{ width:"100%", display:"flex", justifyContent:"space-around", alignItems:"center", marginBottom:"30px"}}>
                <div 
                    style={{
                        backgroundColor:"white",
                        color:'#081c4d',
                        borderRadius:5,
                        height:"70px",
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center",
                        margin: '20px 10px 0px 0px',
                        border: '1px solid #081c4d',
                        padding:"8px",
                        flex:"35%"
                        }}
                >          
                <div
                    style={{
                        fontFamily: "Overpass",
                        fontWeight: 400,
                        fontSize: 18,
                            }}
                >{value.source.content}
                </div>
                </div>
                <div>
                    <img src={forward} alt="forward icon"
                    style={{ margin: '20px 10px 0px 0px',}}
                    />
                </div> 
                <div 
                    style={{
                        backgroundColor:"white",
                        color:'#081c4d',
                        borderRadius:5,
                        height:"70px",
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center",
                        margin: '20px 10px 0px 0px',
                        border: '1px solid #081c4d',
                        padding:"8px",
                        flex:"35%"
                        }}
                >    
                <div
                    style={{
                        fontFamily: "Overpass",
                        fontWeight: 400,
                        fontSize: 18,
                        }}
                >{value.destination?value.destination.content:""}
                </div>
                </div>
                <div
                    style={{ 
                        backgroundColor: 'crimson',
                        height: '50px',
                        width:'70px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius:'5px',
                        margin: '20px 10px 0px 0px',
                        cursor: factorSelectionFilterContextValue===value['type']? 'pointer':null,
                        marginRight:"10px"
                      }}
                    data-source={value.source.id}
                    data-destination={value.destination?value.destination.id:null}
                    onClick={dropToDrag}
                >
                  <img 
                    src={trash} alt="trash"
                    data-source={value.source.id}
                    data-destination={value.destination?value.destination.id:null}
                    onClick={dropToDrag}
                  />
                </div> 
            </div>
            )) 
          }  
        </div>
    )
}

export default DropAddEdge
