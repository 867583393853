import React, {useState, useContext, useEffect} from "react";
//Import Contexts
import {NavBarStateContext, ScoringModelContext} from '../global_values';
//Import Libraries
import {useHistory} from 'react-router-dom';
//Import Images
import tooltip from "../assets/tooltip.svg";
import axios from "axios";
//Import Components
import Loader from "../subViews/Loader"

const Portfolio = () => {
  const {scoringModelContextValue, setScoringModelContextValue} = useContext(ScoringModelContext);
  const [isLoading, setIsLoading]=useState(true);
  const [svgSrc, setSvgSrc]=useState("");
  const [portfolioTab,setPortfolioTab]=useState({});
  const [activeRow,setActiveRow]=useState(undefined);
  const [displayAside, setDisplayAside]=useState(false);
  const [asideValues, setAsideValues]= useState({});

  // API to Get the svg and the portfolio tab
  useEffect(()=>{
    const fetchData = async () => {
    const resSvg = await axios.post(`${process.env.REACT_APP_URL_MASTER}/portfolio_analysis/get_rebalance_figure`, 
    {
      analysis_name:scoringModelContextValue.analysis
    }
    );
    setSvgSrc(resSvg.data.figure);

   const resTab = await axios.post(`${process.env.REACT_APP_URL_MASTER}/portfolio_analysis/get_rebalancing_table`,
   {
    analysis_name:scoringModelContextValue.analysis
   }
   );
   setPortfolioTab(resTab.data); 
  };
  setIsLoading(false);
  fetchData();
  }, [scoringModelContextValue.analysis])

  const history=useHistory();

  //preview to create analysis
  const previewPageFunction=()=>{
    history.push(`/redirection/${scoringModelContextValue.analysis}`)
  }

  const handleDisplayAside=(index)=>{
    setActiveRow(index);
    setDisplayAside(true);
    setAsideValues(portfolioTab.data[index]);
  }

  return  (
    isLoading 
    ? 
    <Loader/>
    :
  <div className="main-container">
    <div className="content-main">
    <div className="portfolio-graph-svg" dangerouslySetInnerHTML={{__html: svgSrc}}></div>


<div className={displayAside ? "report-section-2blocs" : "report-section"}>
    <div className="reporting-table-container">
      <table className="reporting-table table-auto">
          <tr className="table-header">
            <th className="val-1">Fund Name</th>
            <th className="val-2">Current Value</th>
          </tr>
          
            {portfolioTab && portfolioTab.data && portfolioTab.data!==null?
            portfolioTab.data.map((d, index)=>{
              return (
                <tr
                className="report-row table-select" 
                id={index === activeRow ? "active" : {}}
                onClick={(e)=>handleDisplayAside(index)}
                >
                <td className="val-1">{d["Fund Name"]}</td>
                <td className="val-1">{d["Current Value"]}</td>
                </tr> 
              )
            }):null}
      </table>
      </div>
 
    {displayAside && 
    <>
    <div className="tooltip">
      {portfolioTab && portfolioTab.data && portfolioTab.data!==null?
      portfolioTab.data.map((i, index)=>{
        return(
        <img src={tooltip} alt="tooltip" style={{visibility: index===activeRow ? "visible" : "hidden" }}/>       
        )
      }):null}
    </div>
      <aside>
       {Object.keys(asideValues).filter((k)=>{return k !=="Fund Name" && k !=="Current Value"}).map((key)=>{
         return(
        <ul>
          <li>
            <div>{key}</div><div>&#32;{asideValues[key]}</div>
          </li>
        </ul>
         )
       })} 
      </aside>
      </>
      }
    </div>  

    <div className="icons-buttons">
      <button className="btn-icon" onClick={previewPageFunction}>
       Previous
      </button>
      <button className="btn-icon" style={{visibility:"hidden"}}>
       Next
      </button>
    </div>
  </div>
  </div>
  )
}

export default Portfolio;