import React, {useState, useEffect, useContext} from "react";
import {useHistory} from 'react-router-dom';
//Import contexts
import { ScoringModelContext, CreatedModelContext, ClickDropContext, ClickDragContext, AssetPickingFilterContext} from '../global_values'
import {v4 as uuid} from "uuid";
import axios from "axios";

const getDagEndpoint = `${process.env.REACT_APP_URL_MASTER}/view_model/get_dag`
const scoringCreateReport = `${process.env.REACT_APP_URL_MASTER}/analysis_scoring/new_analysis`
const urlModelChoices = `${process.env.REACT_APP_URL_MASTER}/view_model/get_model_names`

const echecResponse = `Oups, there is an error`

const CreateAnalysis = () => {

  const {scoringModelContextValue, setScoringModelContextValue} = useContext(ScoringModelContext)
  const {createdModelContextValue, setCreatedModelContextValue}=useContext(CreatedModelContext)
  const {clickDropContextValue, setClickDropContextValue}=useContext(ClickDropContext)
  const {clickDragContextValue, setClickDragContextValue}=useContext(ClickDragContext)
  const {assetPickingFilterContextValue, setAssetPickingFilterContextValue} = useContext(AssetPickingFilterContext)

  const history=useHistory()

  const[name, setName]=useState("");
  const[description, setDescription]=useState("");
  const[model,setModel]=useState(createdModelContextValue.model_name || "");
  const[modelChoices, setModelChoices]=useState([]);
  const[showNextButton, setShowNextButton]=useState(false)
  const[serverMsg, setServerMsg]=useState("");

  // get connectors from backend
  useEffect(()=>{
       let ScoringModelContextLocal=[]
       axios.get(urlModelChoices)
       .then(e=>{
        e.data.model_names.forEach(model => {
            ScoringModelContextLocal.push({ id: uuid(), label: model, selected:false})
        });
        setModelChoices(ScoringModelContextLocal)
       })
       .catch(err=>console.log(err))
  },[])
  // get old state of this page
  useEffect(()=>{
    setName(scoringModelContextValue.analysis)
    setDescription(scoringModelContextValue.analysis_description)
    //setModel(scoringModelContextValue.model_name)
  },[])

// to test 
function createAnalysisFunction(e){
  e.preventDefault();
  // delete older values of contexts
  setClickDragContextValue([])
  setClickDropContextValue([])
  setAssetPickingFilterContextValue("all")
  // hide next button
  setShowNextButton(false)
  // creating the new model
  axios.post(scoringCreateReport,{
    analysis_name:name,
    analysis_description:description,
    model_name:model
  })
  .then(res => {
    // save name and description of the model in global state context
    setScoringModelContextValue({model_name : model, analysis:name, analysis_description : description})
    // get the model that we have just create
    axios.post(getDagEndpoint, {model_name : model})
    .then(result=>{
      // show the next button
      setShowNextButton(true)
      // show to the user that the model was succefully created
      //setServerMsg(succesResponse)
    })
    .catch(err =>{
      // show to the user that the model was not created
      setServerMsg(echecResponse)
    } )
  })
  .catch(err =>{
      // show to the user that the model was not created
      setServerMsg(echecResponse)
  } )
}
  // navigate to the next page
  function nextPageFunction(){
    // navigate to view analysis with the name of the created analysis
    history.push(`/analysis/${name}`) 
  } 
  function handleUpdateModel(e){
    setModel(e.target.value)
  }
  return (
  <div className="main-container">
    <div className="content-main">
    <div className="edit-model-container">
      <form onSubmit={createAnalysisFunction} className="form-model">
      <h2>Create Analysis</h2>
      <div style={{ display:'flex', justifyContent:'space-between', alignItems:"flex-start"}}> 
      <div className="col-1" style={{flex:'50%'}}>
        <div className="form-input">
          <label>Name</label>   
          <input 
          name="model's name" 
          type="text"
          required
          defaultValue={name}
          onChange={(event)=>setName(event.target.value)}>
          </input>
        </div>
        <div className="form-input">
          <label>Description</label>   
          <textarea 
          name="model's description" 
          type="textarea"
          rows="10"
          defaultValue={description}
          onChange={(event)=>setDescription(event.target.value)}>
          </textarea>
        </div>   
        </div>
        <div className="col-2" style={{flex:'50%'}}>
        <div className="form-input">
        <label>Choose Model</label>
        <div className="dropdown-select">
            <select name="models" onChange={handleUpdateModel}>
              {modelChoices.map((model, index) => {
                return (
                  <option key={model.id}>
                    {model.label}
                  </option>
                )
              })
              }
            </select>
        </div>
        </div>
        </div>
      <div>
      </div>
        </div>
        <input className="submit-btn" type="submit" value="Create Analysis"/>
      </form>
      </div>
      <div className="icons-buttons" style={{padding: '0 5%',boxSizing: 'border-box'}}>
        <button className="btn-icon" style={{visibility:"hidden"}}>
          Previous
        </button>
        {
          showNextButton &&
          <button className="btn-icon" onClick={nextPageFunction}>
            Next
          </button>
        }
        </div>
    </div>
  </div>
  )
}

export default CreateAnalysis;
