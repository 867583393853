import React, {useEffect, useRef} from 'react'
import { Network} from 'vis-network/standalone/esm/vis-network';


var changeChosenLabelColor = function (values, id, selected, hovering) {
  values.color = "#FFFFFF"
  values.size += 1
  values.face = "serif"
  values.mod = "bold italic"
  values.strokeWidth = 5
  values.strokeColor = "#000000"
};

const options = {
    autoResize:true,
    height: '100%',
    width: '100%',
    clickToUse:false,
    
  layout: {
      // this would make the tree directed
      hierarchical: {
          enabled:true,
          levelSeparation: 75,
          nodeSpacing:100,
          direction:'UD',
          edgeMinimization: false,
          parentCentralization: false,
          sortMethod:"directed",
          shakeTowards:"leaves",
          blockShifting:true
      },
      randomSeed:2,
      improvedLayout:true,
      clusterThreshold:100
    },
    interaction: {
      tooltipDelay:10,
      selectConnectedEdges:true,
      hoverConnectedEdges:true,
      hover:true,
      hideEdgesOnDrag:true,
      hideNodesOnDrag:false,
      dragView:true,
      dragNodes :true,
      navigationButtons: true,
      keyboard: {
        enabled: false,
        speed: {x: 10, y: 10, zoom: 0.02},
        bindToWindow: true
      },
      zoomSpeed: 1,
      zoomView:true
    },
      nodes: {
        size:18,
        borderWidth:2,
        borderWidthSelected:3,
        chosen:{
          label:changeChosenLabelColor,
        },
          //image, circularImage, diamond, dot, star, triangle, triangleDown, hexagon, square and icon.
        shape: "hexagon",
        scaling: {
            label: {
              min: 8,
              max: 20,
              
            },
        },

      },
      edges:{
        smooth:true,
        arrows:{
          to:true
        }
      },
     
      physics: {
      
        hierarchicalRepulsion: {
          centralGravity: 0,
          avoidOverlap: 0.5,
        },
        solver: "hierarchicalRepulsion",
        adaptiveTimestep:true,
        enabled: true
      },
  }


function NetworkAssetPicking(props) {
    // A reference to the div rendered by this component
    const domNode = useRef(null);

    // A reference to the vis network instance
    const network = useRef(null);

    useEffect(()=>{
        if(props.networkData!==null){
            network.current = new Network(domNode.current, props.networkData, options)         
        }
    },[props])

    return (
        <div style={{height:"800px", width:"100%", marginBottom:'30px'}} ref = { domNode } />
    )
}

export default NetworkAssetPicking