import React, {useContext, useEffect, useState} from "react";
//Import contexts
import {ScoringModelContext} from '../global_values';
//Import libraries
import axios from "axios";

const reportingGraphEndPoint=`${process.env.REACT_APP_URL_MASTER}/analysis_scoring/get_explanation`
const reportingExplanationEndPoint=`${process.env.REACT_APP_URL_MASTER}/analysis_scoring/get_report3`

const Reporting = () => {

  const {scoringModelContextValue, setScoringModelContextValue} = useContext(ScoringModelContext) 
  
  const [dataReporting, setDataReporting]=useState({});
  const [show, setShow]=useState(false);
  const [displayEsgTab, setDisplayEsgTab]=useState(false);
  const [activeRow,setActiveRow]=useState(undefined);
  const [previewIndex, setPreviewIndex]=useState()


const handleDisplayESGValues=(index,d)=>{
  // change background colors in network
  if(displayEsgTab===false){
    setDisplayEsgTab(!displayEsgTab);
  }else{
    if(index===previewIndex){
      setDisplayEsgTab(!displayEsgTab);
    }
  }
  // handleDisplayESGValues
  setActiveRow(index);
  setPreviewIndex(index)
}

  useEffect(()=>{
       axios.post(reportingExplanationEndPoint,
       {analysis_name:scoringModelContextValue.analysis})
       .then(e=>{
          setDataReporting(e.data)
       })

       axios.post(reportingGraphEndPoint,{
         analysis_name: scoringModelContextValue.analysis
       })
       .then(res=>{
             setShow(true)
       })
       .catch(err=>console.log("error svg endpoint"))
  },[])

  return (
    show &&
    <div className={"report-section"}>
      <div className="reporting-table-container">
      <table className="reporting-table table-auto">
      <tr className="table-header-scoring">
      {dataReporting && dataReporting.main_columns && dataReporting.main_column !== null &&
      dataReporting.main_columns.map((h, index) => {
        return(
        <th className="val-1">{h}</th> 
        )
      })
    }
    </tr>
  {dataReporting && dataReporting.data &&
    dataReporting.data.map((d, index)=>{
      return(
        <tr className="report-row table-select" 
        id={index === activeRow ? "active" : {}}
        onClick={(e)=>handleDisplayESGValues(index,d)}
        >
      {dataReporting.main_columns.map((h)=>{
      return(
        typeof d[h] === "number"
        ? 
        <td className="val-1">{d[h].toExponential(3)}</td>
        :
        <td className="val-1">{d[h]}</td>
      )
    })
  }
    </tr>
      )
  })}
      </table>
      </div>  
    </div>
  )
}

export default Reporting;