import React, {useEffect, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import {ClDropLayoutFactorsGlobalContext, ClDropLayoutFactorsContext, ClDragLayoutFactorsContext} from '../global_values'

const useStyles = makeStyles({
  root: {
    '& .targetStyle': {
      color:'#197b11'
    },
  },
  
});

export default function BasicColumnsGrid({columns, rows}) {
  const {clDragLayoutFactorsContextValue, setClDragLayoutFactorsContextValue} = useContext(ClDragLayoutFactorsContext)
  const {clDropLayoutFactorsContextValue, setClDropLayoutFactorsContextValue} = useContext(ClDropLayoutFactorsContext)
  const {clDropLayoutFactorsGlobalContextValue, setClDropLayoutFactorsGlobalContextValue} = useContext(ClDropLayoutFactorsGlobalContext)

const classes = useStyles();

  return (
    <div 
    className={classes.root} 
    style={{ height: 800, width: '100%' }}>
      <DataGrid
        columns={columns.filter(column=>!clDragLayoutFactorsContextValue.find(e=>e.name===column.field))}
        rows={rows}
        rowHeight={30}
        disableColumnResize={false}
      />
    </div>
  );
}



