import React from "react";
import { NavLink } from "react-router-dom";

// Import FontAwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Import des Icones
import {ReactComponent as PlusIcon} from "../assets/plus.svg";
import {ReactComponent as ViewIcon} from  "../assets/view.svg";
import {ReactComponent as ForecastIcon} from  "../assets/forecast.svg";
import {ReactComponent as ActionsIcon} from "../assets/action.svg";
import {ReactComponent as DataIcon} from "../assets/data.svg";
import {ReactComponent as PortfolioIcon} from  "../assets/portfolio.svg";
import {ReactComponent as RiskIcon} from "../assets/risk.svg";
import {ReactComponent as ReportIcon} from  "../assets/report.svg";
import {ReactComponent as ModelsIcon} from  "../assets/models.svg";

const NavigationBarLink = ({to, imgSrc, txt, isActive}) => {
    return (
        <NavLink to={to} activeClassName="nav-button-active" isActive={isActive} className="nav-button">
          <div className="nav-button-content">
              {imgSrc === "PlusIcon"  ? <PlusIcon/> :
              imgSrc === "ForecastIcon" ? <ForecastIcon/> :
              imgSrc === "ViewIcon" ? <ViewIcon/> :
              imgSrc === "ActionsIcon" ? <ActionsIcon/> :
              imgSrc === "DataIcon" ? <DataIcon/> :
              imgSrc === "ScoreIcon" ? <FontAwesomeIcon icon="coins" className="fas fa-coins"/> :
              imgSrc === "PortfolioIcon" ? <PortfolioIcon/> :
              imgSrc === "RiskIcon" ? <RiskIcon/> :
              imgSrc === "ReportIcon" ? <ReportIcon/> :
              imgSrc === "ModelsIcon" && <ModelsIcon/> 
            }
            <p>{txt}</p>
            </div>
        </NavLink>
      );
}

export default NavigationBarLink;