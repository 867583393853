import React from "react";

const Loader = () => {
  return (
    <div className="loading-container">
    <div className="test-loader">
      <div className="yellow"></div>
      <div className="red"></div>
      <div className="blue"></div>
      <div className="violet"></div>
    </div>
    </div>
  );
}

export default Loader;