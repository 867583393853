import React, { useEffect, useState } from "react";
import { Bar } from 'react-chartjs-2';

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
    },
    title: {
      display: true,
      text: 'Expected Return/ Risk',
      position:'top'
    },
  },
}

const VerticalBarChart = (props) => {
  const [data , setData]=useState({});

   const labels = ['Return', 'Sharpe Ratio'];

  useEffect(()=>{
    console.log(props)
    if(props.whatIfAnalysisReturn && props.sharpeRatio){
      setData({
        labels: labels,
        datasets: [
          {
            label: 'Current',
            data:[props.whatIfAnalysisReturn.current, props.sharpeRatio.current],
            borderColor: 'rgb(46, 196, 182)',
            backgroundColor: 'rgb(46, 196, 182)',
          },
          {
            label: 'Expected',
            data: [props.whatIfAnalysisReturn.expected, props.sharpeRatio.expected],
            borderColor: 'tomato',
            backgroundColor: 'tomato',
          }
        ]
      })
    }
  },[props])
  return (
    <div className="canvas-container">
      <div style={{visibility:'hidden'}}>Return/ Sharp Ratio map</div>
        <Bar data={data} options={options}/>
    </div>
  );
}

export default VerticalBarChart;