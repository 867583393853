// hooks importation

import React, { useEffect, useRef, useState} from 'react';
// libraries importation
import { Network} from 'vis-network/standalone/esm/vis-network';

var changeChosenLabelColor = function (values, id, selected, hovering) {
    values.color = "#FFFFFF"
    values.size += 1
    values.face = "serif"
    values.mod = "bold italic"
    values.strokeWidth = 5
    values.strokeColor = "#000000"
  };

// Constants and variables
const options = {
    autoResize:true,
    height: '100%',
    width: '100%',
    clickToUse:false,
    
  layout: {
      // this would make the tree directed
      hierarchical: {
          enabled:true,
          levelSeparation: 75,
          nodeSpacing:100,
          direction:'UD',
          edgeMinimization: false,
          parentCentralization: false,
          sortMethod:"directed",
          shakeTowards:"leaves",
          blockShifting:true
      },
      randomSeed:2,
      improvedLayout:true,
      clusterThreshold:100
    },
    interaction: {
      tooltipDelay:10,
      selectConnectedEdges:true,
      hoverConnectedEdges:true,
      hover:true,
      hideEdgesOnDrag:true,
      hideNodesOnDrag:false,
      dragView:true,
      dragNodes :true,
      navigationButtons: true,
      keyboard: {
        enabled: false,
        speed: {x: 10, y: 10, zoom: 0.02},
        bindToWindow: true
      },
      zoomSpeed: 1,
      zoomView:true
    },
      nodes: {
        size:18,
        borderWidth:2,
        borderWidthSelected:3,
        chosen:{
          label:changeChosenLabelColor,
        },
          //image, circularImage, diamond, dot, star, triangle, triangleDown, hexagon, square and icon.
        shape: "hexagon",
        scaling: {
            label: {
              min: 8,
              max: 20,
              
            },
        },

      },
      edges:{
        smooth:true,
        arrows:{
          to:true
        }
      },
     
      physics: {
      
        hierarchicalRepulsion: {
          centralGravity: 0,
          avoidOverlap: 0.5,
        },
        solver: "hierarchicalRepulsion",
        adaptiveTimestep:true,
        enabled: true
      },
  }

const VisNetwork = (props) => {
  // A reference to the div rendered by this component
  const domNode = useRef(null);

  // A reference to the vis network instance
  const network = useRef(null);

  const [dataNetwork, setDataNetwork] =  useState()
  const [dataNetworkGlobal, setDataNetworkGlobal] =  useState()

  useEffect(()=>{
    const nodesGlobal=[]
    const edgesGlobal=[]
    if(props.networkData){
      props.networkData.nodes.forEach(node => {
        if(props.funds.find(fund=>fund.name===node.name)){
          node.shape="star"
        }
        node.defaultColor=node.color
        nodesGlobal.push({...node})
      })
      props.networkData.edges.forEach(edge => {
        edge.defaultColor=edge.color
        edgesGlobal.push({...edge})
      })
      setDataNetworkGlobal({nodes:[...nodesGlobal], edges:[...edgesGlobal]})
      setDataNetwork({...props.networkData})
    }
  },[props.networkData, props.funds])

  useEffect(()=>{
      network.current = new Network(domNode.current, dataNetwork, options)
  },[dataNetwork])

  useEffect(()=>{
      if(dataNetwork && dataNetwork!==null){
        //nodes style
        const node=dataNetwork.nodes.find(node=>node.name===props.chosenFactor)
        if(node){
          node.color="crimson"
          setDataNetwork({...dataNetwork})     
        }
        dataNetwork.nodes.forEach(node => {
          if(node.color!="crimson"){
            node.color="rgba(100,100,100,0.2)"          
          }
        })
        //edges style
        dataNetwork.nodes.filter(node=>node.color!="crimson").forEach(node => {
          dataNetwork.edges.filter(edge=>edge.from===node.id || edge.to===node.id).forEach(edge => {
            edge.color="rgba(100,100,100,0.2)"
          })
        })
        dataNetwork.nodes.filter(node=>node.color==="crimson").forEach(node => {
          dataNetwork.edges.filter(edge=>edge.from===node.id || edge.to===node.id).forEach(edge2 => {
            edge2.color=edge2.defaultColor
          })
        })
        
        props.updateData({nodes:[...dataNetwork.nodes], edges:[...dataNetwork.edges]})
      }
  },[props.chosenFactor])

  useEffect(()=>{
    if(dataNetwork && dataNetwork!==null){
        const f=dataNetwork.nodes.find(element=>element.name===props.deletedFactor)
        if(f.color==="crimson"){
          f.color="rgba(100,100,100,0.2)"
          dataNetwork.edges.filter(edge=>edge.from===f.id || edge.to===f.id)
          .forEach(element => {
            element.color="rgba(100,100,100,0.2)"
          });
          setDataNetwork({...dataNetwork})    
          //edges style
        dataNetwork.nodes.filter(node=>node.color==="crimson").forEach(node => {
          dataNetwork.edges.filter(edge=>edge.from===node.id || edge.to===node.id).forEach(edge2 => {
            edge2.color=edge2.defaultColor
          })
        })
        }    
        if(dataNetwork.nodes.find(node=>node.color==="crimson")){
          console.log(dataNetwork.nodes.find(node=>node.color==="crimson"))
          setDataNetwork({...dataNetwork}) 
          props.updateData({nodes:[...dataNetwork.nodes], edges:[...dataNetwork.edges]})
        } else{
          const nodesLocal=[]
          const edgesLocal=[]
            dataNetworkGlobal.nodes.forEach(node => {
              nodesLocal.push({...node})
            })
            dataNetworkGlobal.edges.forEach(edge => {
              edgesLocal.push({...edge})
            })
          setDataNetwork({nodes:[...nodesLocal], edges:[...edgesLocal]})
          props.updateData({nodes:[...nodesLocal], edges:[...edgesLocal]})
        }     
    }
  },[props.deletedFactor])

     
    return (
        <React.Fragment>
            <div style={{height:"700px"}} ref = { domNode } />   
        </React.Fragment>
    )
}

export default VisNetwork

