//import React, { useContext } from "react";
import React, { useState, useContext, useEffect } from "react";
import chroma from 'chroma-js'
import {Colorscale} from 'react-colorscales';
import ColorscalePicker from 'react-colorscales';
//Import contexts
import {
   NetworkContext, 
   CreatedModelContext,
   ClDragSetValueContext,
   ClDragSetValueGlobalContext,
   ClDropSetValueContext,
   ClDragAddEdgeContext,
   ClDropAddEdgeContext,
   ClDragAddEdgeGlobalContext,
   ClDragForbidEdgeContext,
   ClDropForbidEdgeContext,
   ClDragForbidEdgeGlobalContext,
   ClDragSetOrderContext,
   ClDropSetOrderContext,
   ClDragSetOrderGlobalContext,
   FactorSelectionFilterContext,
   ClDropLayoutFactorsGlobalContext } from '../global_values'
//Import libraries 
import axios from 'axios'
import {useHistory} from 'react-router-dom'
// Import des composants 
import Network from '../subViews/network_adjust2'
import FactorSelectionRecap from "./FactorSelectionRecap";
import FactorSelectionButtons from "../subViews/FactorSelectionButtons";
import DragSetValues from "../subViews/DragSetValues"
import DropSetValues from "../subViews/DropSetValues"
import DragSetOrders from "../subViews/DragSetOrders"
import DropSetOrders from "../subViews/DropSetOrders"
import DragAddEdges from "../subViews/DragAddEdge"
import DropAddEdges from "../subViews/DropAddEdge"
import DragForbidEdges from "../subViews/DragForbidEdge"
import DropForbidEdges from "../subViews/DropForbidEdge"


const removeNodeEndPoint=`${process.env.REACT_APP_URL_MASTER}/new_model/requirement/remove_nodes`
//const removeEdgeEndPoint=`${process.env.REACT_APP_URL_MASTER}/view_model/get_dag`
const sequenceEndPoint=`${process.env.REACT_APP_URL_MASTER}/new_model/requirement/set_causal_priority`
const setValuesEndPoint=`${process.env.REACT_APP_URL_MASTER}/new_model/requirement/set_value`
const addEdgeEndPoint=`${process.env.REACT_APP_URL_MASTER}/new_model/requirement/manage_edges`
const jsonNetworkEndPoint = `${process.env.REACT_APP_URL_MASTER}/view_model/get_network_initial`
const fixValuesEndPoint = `${process.env.REACT_APP_URL_MASTER}/view_model/get_domain`

const blue="lightblue"
const red="#f995aa"
const gray="gray"

const FactorSelection = () => {  

  const {networkContextValue, setNetworkContextValue} = useContext(NetworkContext)
  const {createdModelContextValue, setCreatedModelContextValue} = useContext(CreatedModelContext)

  // click version
  const {clDragSetValueContextValue, setClDragSetValueContextValue} = useContext(ClDragSetValueContext)
  const {clDropSetValueContextValue, setClDropSetValueContextValue} = useContext(ClDropSetValueContext) 
  const {clDragSetValueContextGlobalValue, setClDragSetValueContextGlobalValue} = useContext(ClDragSetValueGlobalContext)
  const {clDragAddEdgeContextValue, setClDragAddEdgeContextValue} = useContext(ClDragAddEdgeContext)
  const {clDropAddEdgeContextValue, setClDropAddEdgeContextValue} = useContext(ClDropAddEdgeContext)
  const {clDragAddEdgeContextGlobalValue, setClDragAddEdgeContextGlobalValue} = useContext(ClDragAddEdgeGlobalContext)
  const {clDragForbidEdgeContextValue, setClDragForbidEdgeContextValue} = useContext(ClDragForbidEdgeContext)
  const {clDropForbidEdgeContextValue, setClDropForbidEdgeContextValue} = useContext(ClDropForbidEdgeContext)
  const {clDragForbidEdgeContextGlobalValue, setClDragForbidEdgeContextGlobalValue} = useContext(ClDragForbidEdgeGlobalContext)
  const {clDragSetOrderContextValue, setClDragSetOrderContextValue} = useContext(ClDragSetOrderContext)
  const {clDropSetOrderContextValue, setClDropSetOrderContextValue} = useContext(ClDropSetOrderContext)
  const {clDragSetOrderContextGlobalValue, setClDragSetOrderContextGlobalValue} = useContext(ClDragSetOrderGlobalContext)
  const {FactorSelectionFilterContextValue, setFactorSelectionFilterContextValue} = useContext(FactorSelectionFilterContext)
  const {clDropLayoutFactorsGlobalContextValue, setClDropLayoutFactorsGlobalContextValue} = useContext(ClDropLayoutFactorsGlobalContext)
  
  const history=useHistory()
    
  const [requirement, setRequirement]=useState("");
  const [responseServer, setResponseServer]=useState("")
  const [showNextButton, setShowNextButton]=useState(false)

  const [jsonData, setJsonData]=useState(null) 
  const [pickedFont, setPickedFont]=useState(["#f7fcf5", "#e5f5e0", "#c7e9c0", "#a1d99b", "#74c476", "#41ab5d", "#238b45", "#006d2c", "#00441b"])
  const [showColorscalePicker, setShowColorscalePicker]=useState(false)

  const [ esgFilter, setEsgFilter ] = useState("");

  const nextPageFunction=(e)=>{
      history.push(`/custom-model/${createdModelContextValue.model_name}`)
  } 

  const sendRecapToServer=async(e)=>{
    const clDropForbidEdgeContextValueLocal=[]
    clDropForbidEdgeContextValue.forEach(element => {
      if(element.source && element.destination){
        clDropForbidEdgeContextValueLocal.push(
          {
            source:element.source.content,
            destination:element.destination.content
          }
          )
      }
    });
    const clDropAddEdgeContextValueLocal=[]
    clDropAddEdgeContextValue.forEach(element => {
      if(element.source && element.destination){
        clDropAddEdgeContextValueLocal.push(
          {
            source:element.source.content,
            destination:element.destination.content
          }
          )
      }
    });
    const managEdgeRes=await axios.post(addEdgeEndPoint,{
      model_name:createdModelContextValue.model_name,
      edges_to_forbid:networkContextValue.redEdgesFromTo?networkContextValue.redEdgesFromTo.concat(clDropForbidEdgeContextValueLocal):clDropForbidEdgeContextValueLocal || [],
      edges_to_impose:clDropAddEdgeContextValueLocal || [],        
    })   
    const deleteNodeRes=await axios.post(removeNodeEndPoint,
      {
          model_name:createdModelContextValue.model_name,
          nodes_to_remove:networkContextValue.redNodes || []
      }) 
      const resultSetValues=[]
      clDropSetValueContextValue.forEach(element => {
        resultSetValues.push({node_name:element.content, value:element.profile})      
      });

    const setValueRes=await axios.post(setValuesEndPoint,{
      model_name:createdModelContextValue.model_name,
      node_values:resultSetValues
    })
    const resultSetOrders=[]
    clDropSetOrderContextValue.forEach(element => {
      resultSetOrders.push({node_name:element.content, value:element.order}) 
    });
    clDragSetOrderContextValue.forEach(element => {
      resultSetOrders.push({node_name:element.content, value:2}) 
    });
    const setOrderRes= await axios.post(sequenceEndPoint,{
      model_name:createdModelContextValue.model_name,
      causal_priority:resultSetOrders
  })
  setResponseServer("requirements saved on server, click next")
  setShowNextButton(true)
  }
 
  const findType = (nodeId, types) => {
    if(types.E.includes(nodeId)) {
      return "E"
    }
    if(types.S.includes(nodeId)) {
      return "S"
    }
    if(types.G.includes(nodeId)) {
      return "G"
    }
    if(types.Other.includes(nodeId)) {
      return "Other"
    }
}

  useEffect(async()=>{
    let res=await axios.post(jsonNetworkEndPoint,{model_name:createdModelContextValue.model_name})
       console.log(res.data.nodes)
      // set default colors for edges
      res.data.edges.forEach(edge => {
        edge.color=gray
      });
    
      // get max width value
      const maxEntropy=Math.max.apply(Math, res.data.nodes.map(function(o) { return o.entropy; }))
      const minEntropy=Math.min.apply(Math, res.data.nodes.map(function(o) { return o.entropy; }))
      const maxEntropyEdge=Math.max.apply(Math, res.data.edges.map(function(o) { return o.entropy; }))
      //const minEntropyEdge=Math.min.apply(Math, res.data.edges.map(function(o) { return o.entropy; }))
      let E=res.data.nodes.find(node=>node.name==="PortfolioEnvironmentalScore")
      let S=res.data.nodes.find(node=>node.name==="PortfolioGovernanceScore")
      let G=res.data.nodes.find(node=>node.name==="PortfolioSocialScore")
      // star square diamond 
      if(E){
        E.shape="star"
      }
      if(S){
        S.shape="star"
      }
      if(G){
        G.shape="star"
      }

    // star square diamond case upoload csv 
    res.data.nodes.forEach(node => {
      const f=clDropLayoutFactorsGlobalContextValue.find(factor=>factor.name===node.name)
      if(f && f.isTarget){
        node.shape="star"
      }
    });

    res.data.nodes.forEach(node=>{
    node.label=node.name  
    //node.color=chroma.scale('puBuGn').domain([maxEntropy,0])(node.entropy).toString()  
    node.color=chroma.scale(pickedFont).domain([minEntropy,maxEntropy])((node.entropy)).toString()  
    node.value=node.entropy       
   })
   const widthCalulWay="entropys"
   res.data.edges.forEach(edge=>{

    if(widthCalulWay==="entropy"){
      edge.width=edge.entropy/maxEntropyEdge*10
      edge.label=edge.entropy
      if(edge.corr>0){
        edge.color=blue
      }
      else if(edge.corr<0){
        edge.color=red
      }
     }
     else{
      if(edge.corr!==0){
        edge.width=Math.abs(edge.corr)*10
        edge.label=edge.corr 
        if(edge.corr>0){
          edge.color=blue
        }
        else if(edge.corr<0){
          edge.color=red
        }  
      } else{
        edge.width=edge.width*10
        edge.label=edge.corr 
        if(edge.corr>0){
          edge.color=blue
        }
        else if(edge.corr<0){
          edge.color=red
        }            
      }   
     }
     if(!edge.corr){
       edge.width=1
     }
   })

  setJsonData(res.data)
   

     // set drag tags
     if(clDragSetValueContextGlobalValue.length===0){
      const response = await axios.post(`${process.env.REACT_APP_URL_MASTER}/view_model/get_esg_types`, 
      {
        model_name:createdModelContextValue.model_name
      })

     res.data.nodes.forEach(async(node) => {
       const result=findType(node.name, response.data)
       console.log(result)
      setClDragSetOrderContextValue(clDragSetOrderContextValue=>[...clDragSetOrderContextValue,
        {id: node.id, content: node.name, orderOptions:[1,2,3,4,5], order:2, type:result}      
      ])
      setClDragSetOrderContextGlobalValue(clDragSetOrderContextValue=>[...clDragSetOrderContextValue,
        {id: node.id, content: node.name, orderOptions:[1,2,3,4,5], order:2, type:result}      
      ])
      setClDragAddEdgeContextValue(clDragAddEdgeContextValue=>[...clDragAddEdgeContextValue,
        {id: node.id, content: node.name, type:result}
      ])
      setClDragAddEdgeContextGlobalValue(clDragAddEdgeContextValue=>[...clDragAddEdgeContextValue,
        {id: node.id, content: node.name, type:result}
      ])
      setClDragForbidEdgeContextValue(clDragForbidEdgeContextValue=>[...clDragForbidEdgeContextValue,
        {id: node.id, content: node.name, type:result}
      ])
      setClDragForbidEdgeContextGlobalValue(clDragForbidEdgeContextValue=>[...clDragForbidEdgeContextValue,
        {id: node.id, content: node.name, type:result}
      ])
      const choices = await axios.post(fixValuesEndPoint, {node:node.name, model_name:createdModelContextValue.model_name});
      setClDragSetValueContextValue(clDragSetValueContextValue=>[...clDragSetValueContextValue,
        {id: node.id, content: node.name, profileOptions: choices.data.domain, profile:choices.data.domain[0], type:result}
      ])
      setClDragSetValueContextGlobalValue(clDragSetValueContextValue=>[...clDragSetValueContextValue,
        {id: node.id, content: node.name, profileOptions: choices.data.domain, profile:choices.data.domain[0], type:result}
      ])
     })
    }
  },[])

  
  const handleChangeStyle =(e)=>{
    if(esgFilter===e){
      setEsgFilter("")
      setFactorSelectionFilterContextValue("all")
    }else{
      setEsgFilter(e);
      setFactorSelectionFilterContextValue(e)
    }
  }
  return(
    <div className="main-container">
    <div className="content-main">
    {
    <>
       <div className="network">
       <div 
          onClick={()=>setShowColorscalePicker(!showColorscalePicker)}
          className='toggleButton'
          style={{borderColor: '#A2B1C6'}}
        >
        Toggle Colorscale Picker
        </div>
        {showColorscalePicker?
        <div style={{position:"relative", marginTop:"0px"}}>
        <ColorscalePicker 
          colorscale={pickedFont}
          onChange={(e) => {
          setPickedFont(e)
          }}
          />
          </div>
          :null}
           <Network networkData={jsonData}/>
          <Colorscale
          colorscale={pickedFont}
          />
      </div>
      <FactorSelectionButtons requirement={requirement} setRequirement={setRequirement}/>
      <div className="filter-values">
              <a 
              className={esgFilter==="E" && "Open1"}
              onClick={()=>{handleChangeStyle("E")}}
              >
                <div style={{width:"100px"}}>E</div> 
                {/* <img src={valueE} alt="value"/> */}
              </a>
              <a 
                className={esgFilter==="S" && "Open2"}
                onClick={()=>{handleChangeStyle("S")}}
              >   
                  <div style={{width:"100px"}}>S</div>
              </a>
              <a 
                  className={esgFilter==="G" && "Open3"}
                  onClick={()=>{handleChangeStyle("G")}}
              >
                  <div style={{width:"100px"}}>G</div>
              </a>
              <a
                className={esgFilter==="Other" && "Open4"}
                onClick={()=>{handleChangeStyle("Other")}}
              >
                <div style={{width:"100px"}}>Other</div>
              </a>
          </div>
      {requirement === "order" ? <><DragSetOrders/><DropSetOrders/></>
      :
      requirement === "edge" ? <><DragAddEdges/><DropAddEdges/></>
      :    
      requirement === "forbidEdge" ? <><DragForbidEdges/><DropForbidEdges/></>      
      :
      requirement === "value" ? <><DragSetValues/><DropSetValues/></>
      :
      requirement === "recap" && <FactorSelectionRecap/>
  }
      <div style={{width:"100%", display:'flex', justifyContent:'flex-end', alignItems:'center', marginTop:'20px'}}>
        <button className="b" onClick={sendRecapToServer}
        >Submit</button>
      </div>
          </>
    }
      {
         <div className="icons-buttons">
              <button className="btn-icon" 
              style={{visibility: requirement !== "recap" && "hidden"}}
              onClick={()=>setRequirement("order")}>
                Previous
              </button>
              {
                showNextButton?
                <button className="btn-icon" onClick={nextPageFunction}>
                Next
              </button>
                :null
              }
        </div>
      }
        </div>
        </div>
  )
}

export default FactorSelection;