// hooks importation
import React, { useEffect, useRef, useState, useContext,memo } from 'react';
// contexts importation 
import {NetworkContext,  
  DeletedNodesEdgesContext,
  RestartDeletedNodesContext} from '../global_values';
// libraries importation
import { Network} from 'vis-network/standalone/esm/vis-network';

// create a Network class 
class NetworkObject {
  constructor(redNodes, redEdges, redEdgesFromTo){
    this.redNodes=redNodes
    this.redEdges=redEdges
    this.redEdgesFromTo=redEdgesFromTo
  }
}

var changeChosenLabelColor = function (values, id, selected, hovering) {
    values.color = "#FFFFFF"
    values.size += 1
    values.face = "serif"
    values.mod = "bold italic"
    values.strokeWidth = 5
    values.strokeColor = "#000000"
  };
  
  const options = {
      autoResize:true,
      height: '100%',
      width: '100%',
      clickToUse:false,
      
    layout: {
        // this would make the tree directed
        hierarchical: {
            enabled:true,
            levelSeparation: 75,
            nodeSpacing:100,
            direction:'UD',
            edgeMinimization: false,
            parentCentralization: false,
            sortMethod:"directed",
            shakeTowards:"leaves",
            blockShifting:true
        },
        randomSeed:2,
        improvedLayout:true,
        clusterThreshold:100
      },
      interaction: {
        tooltipDelay:10,
        selectConnectedEdges:true,
        hoverConnectedEdges:true,
        hover:true,
        hideEdgesOnDrag:true,
        hideNodesOnDrag:false,
        dragView:true,
        dragNodes :true,
        navigationButtons: true,
        keyboard: {
          enabled: false,
          speed: {x: 10, y: 10, zoom: 0.02},
          bindToWindow: true
        },
        zoomSpeed: 1,
        zoomView:true
      },
        nodes: {
          size:18,
          borderWidth:2,
          borderWidthSelected:3,
          chosen:{
            label:changeChosenLabelColor,
          },
            //image, circularImage, diamond, dot, star, triangle, triangleDown, hexagon, square and icon.
          shape: "hexagon",
          scaling: {
              label: {
                min: 8,
                max: 20,
                
              },
          },
  
        },
        edges:{
          smooth:true,
          arrows:{
            to:true
          }
        },
       
        physics: {
        
          hierarchicalRepulsion: {
            centralGravity: 0,
            avoidOverlap: 0.5,
          },
          solver: "hierarchicalRepulsion",
          adaptiveTimestep:true,
          enabled: true
        },
    }

// redNodes
let redNodes = []
// redEdges
let redEdges = []
// redEges from node to another
let redEdgesFromTo =[]
// Save default color of each node
let defaultColorNodes=[]
// Save default color of each edge
let defaultColorEdges=[]
// Colors
const red="#f996aa"
const blue="lightblue"
const VisNetwork = (props) => {

const {networkContextValue, setNetworkContextValue} = useContext(NetworkContext) 
const {restartDeletedNodesContextValue, setRestartDeletedNodesContextValue} = useContext(RestartDeletedNodesContext)
const {deletedNodesEdgesContextValue, setDeletedNodesEdgesContextValue} = useContext(DeletedNodesEdgesContext)
 

  const [dataNetwork, setDataNetwork] =  useState()

  // A reference to the div rendered by this component
  const domNode = useRef(null);

  // A reference to the vis network instance
  const network = useRef(null);

  useEffect(()=>{
    redEdges=[]
    redNodes=[]
    redEdgesFromTo=[]
  },[deletedNodesEdgesContextValue])

  useEffect(()=>{
    setDataNetwork(props.networkData)
  },[props.networkData])

  // creation of the network 
  useEffect(()=>{
      redEdges=[]
      redNodes=[]
      redEdgesFromTo=[]
      // interactios with the network
      network.current = new Network(domNode.current, dataNetwork, options)
      // save default state of the network 
      for(const node in network.current.body.nodes) {
        // save default color of each node 
        let colorNode = network.current.body.nodes[node].options.color.background
        defaultColorNodes.push({node:node, colorNode:colorNode})
      }
      // save default color of each edge 
      for(const edge in network.current.body.edges){
        let colorEdge = network.current.body.edges[edge].options.color.color
        defaultColorEdges.push({edge:edge, colorEdge:colorEdge})
      }
      // get the old state of the network
      if(networkContextValue.redNodes){
        networkContextValue.redNodes.forEach(element => {
          if(dataNetwork){
            let nodeBody2=dataNetwork.nodes.find(el=>el.name===element)
            if(nodeBody2){
              let nodeBody=network.current.body.nodes[nodeBody2.id]
              if(nodeBody){
                nodeBody.options.color.background = "crimson"   
                redNodes.push(element)
              } 
            }
          }
        });
      }
      if(networkContextValue.redEdgesFromTo && dataNetwork){

        networkContextValue.redEdgesFromTo.forEach(e => {
          for (const key in network.current.body.edges){
            let bodyEdge=network.current.body.edges[key]
            let fromID=bodyEdge.fromId
            let toId=bodyEdge.toId
            let source=dataNetwork.nodes.find(el=>el.id===fromID)
            let destination=dataNetwork.nodes.find(el=>el.id===toId)        
            if(source.name===e.source && destination.name===e.destination){
              bodyEdge.options.color.color = "crimson" 
              redEdgesFromTo.push({source:e.source, destination:e.destination})
            }
          }
        });
      }
      // gestion of click event on the network
      if(network.current){
        network.current.on('click', function (properties){
            let node = properties['nodes']['0']
            let edge = properties['edges']['0']
            // case of node click
            if (network.current.body.nodes[node]){
               let nodeBody=network.current.body.nodes[node]
               // case of background color of node is red
               if(nodeBody.options.color.background === "crimson"){
                // remove the node to redNods context
                 const nodee=dataNetwork.nodes.find(el=>el.id===node)
                 redNodes=redNodes.filter(e=>e!==nodee.name)
  
                 let networkObject = new NetworkObject(redNodes, redEdges, redEdgesFromTo)
                 setNetworkContextValue({...networkObject})
                // find default color of this node
                let nodeDefaultColor = defaultColorNodes.find(e=>e.node===node.toString()).colorNode
                // change color of the node to origin color
                nodeBody.options.color.background = nodeDefaultColor   
               }
               // case of background color of node is his color by default 
               else {
                // add the node to redNods context
                const nodee=dataNetwork.nodes.find(el=>el.id===node)
                redNodes.push(nodee.name)
                let networkObject = new NetworkObject(redNodes, redEdges, redEdgesFromTo)
                setNetworkContextValue({...networkObject})
                nodeBody.options.color.background = "crimson"                    
               }              
            } 
            // end case of node click
            // case of edge click
            else if (network.current.body.edges[edge]){
                let edgeBody=network.current.body.edges[edge]
                // case of edge with the crimson color
                if(edgeBody.options.dashes){
                  redEdges=redEdges.filter(e=>e!==edge)
                  const source=dataNetwork.nodes.find(e=>e.id===network.current.body.edges[edge].from.id)
                  const destination=dataNetwork.nodes.find(e=>e.id===network.current.body.edges[edge].to.id)                 
                  redEdgesFromTo=redEdgesFromTo.filter(e=>(e.source !== source.name || e.destination !== destination.name))  
                  let networkObject = new NetworkObject(redNodes, redEdges, redEdgesFromTo)
                  setNetworkContextValue({...networkObject})
                  //let colorEdge = defaultColorEdges.find(e=>e.edge===edge).colorEdge
                  //edgeBody.options.color.color = colorEdge
                  edgeBody.options.dashes=false    
                }
                // case of edge with the default color               
                else {
  
                  const source=dataNetwork.nodes.find(e=>e.id===network.current.body.edges[edge].from.id)
                  const destination=dataNetwork.nodes.find(e=>e.id===network.current.body.edges[edge].to.id)  

                  redEdgesFromTo.push({source:source.name,destination:destination.name})
                  let networkObject = new NetworkObject(redNodes, redEdges, redEdgesFromTo)
                  setNetworkContextValue({...networkObject})
                  redEdges.push(edge)
                  //edgeBody.options.color.color = red  
                  edgeBody.options.dashes=[10, 10, 10, 10]    
      
                }
            }
            // end case of edge click
        })   
    }
    },[dataNetwork]);


  return (
    <React.Fragment>
     <div style={{height:"700px"}} ref = { domNode } />
    </React.Fragment>

  );
};

export default memo(VisNetwork);











