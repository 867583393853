import React, {useState, useEffect, useContext, useCallback, Fragment} from "react";
//Import contexts
import {    NetworkContext, 
  CreatedModelContext,
  ClDragLayoutFactorsContext,
  ClDropLayoutFactorsContext,
  ClDragSetValueContext,
  ClDragSetValueGlobalContext,
  ClDropSetValueContext,
  ClDragAddEdgeContext,
  ClDropAddEdgeContext,
  ClDragAddEdgeGlobalContext,
  ClDragForbidEdgeContext,
  ClDropForbidEdgeContext,
  ClDragForbidEdgeGlobalContext,
  ClDragSetOrderContext,
  ClDropSetOrderContext,
  ClDragSetOrderGlobalContext,
  FactorSelectionFilterContext,
  ClDropLayoutFactorsGlobalContext } from '../global_values'
// Import libraries
import {v4 as uuid} from "uuid";
import axios from "axios";
import {useHistory} from 'react-router-dom';
import DatePicker from "react-datepicker";
import {parse} from 'papaparse'
import "react-datepicker/dist/react-datepicker.css";
// Import SubViews
import DragAndDrop from "../subViews/DragAndDrop";
import Timeline from "../subViews/Timeline";
import DatasetTable from "../subViews/DatasetTable";
import FactorsTable from "../subViews/FactorsTable";

const getConnectorsEndPoint = `${process.env.REACT_APP_URL_MASTER}/new_model/get_data_connectors`;
const createModelEndPoint = `${process.env.REACT_APP_URL_MASTER}/new_model/morning_star_connector`;
const postCsvFileEndPoint = `${process.env.REACT_APP_URL_MASTER}/new_model/upload_csv `;
const manageCsvFileEndPoint = `${process.env.REACT_APP_URL_MASTER}/new_model/manage_csv `;
const getDataTableEndPoint = `${process.env.REACT_APP_URL_MASTER}/new_model/get_dataset`;

//const successResponse = `The model is being generated...`;

const succesMessage="Dataset generated successfully"


//var csv is the CSV file with headers

const NewModel = () => {
  const {createdModelContextValue, setCreatedModelContextValue} = useContext(CreatedModelContext);
  const {networkContextValue, setNetworkContextValue} = useContext(NetworkContext)

  const {clDragLayoutFactorsContextValue, setClDragLayoutFactorsContextValue} = useContext(ClDragLayoutFactorsContext)
  const {clDropLayoutFactorsContextValue, setClDropLayoutFactorsContextValue} = useContext(ClDropLayoutFactorsContext)
  const {clDropLayoutFactorsGlobalContextValue, setClDropLayoutFactorsGlobalContextValue} = useContext(ClDropLayoutFactorsGlobalContext)
  
  const {clDropSetValueContextValue, setClDropSetValueContextValue} = useContext(ClDropSetValueContext)
  const {clDragSetValueContextValue, setClDragSetValueContextValue} = useContext(ClDragSetValueContext)
  const {clDragSetValueContextGlobalValue, setClDragSetValueContextGlobalValue} = useContext(ClDragSetValueGlobalContext)
  
  const {clDropAddEdgeContextValue, setClDropAddEdgeContextValue} = useContext(ClDropAddEdgeContext)
  const {clDragAddEdgeContextValue, setClDragAddEdgeContextValue} = useContext(ClDragAddEdgeContext)
  const {clDragAddEdgeContextGlobalValue, setClDragAddEdgeContextGlobalValue} = useContext(ClDragAddEdgeGlobalContext)
  
  const {clDropForbidEdgeContextValue, setClDropForbidEdgeContextValue} = useContext(ClDropForbidEdgeContext)  
  const {clDragForbidEdgeContextValue, setClDragForbidEdgeContextValue} = useContext(ClDragForbidEdgeContext)
  const {clDragForbidEdgeContextGlobalValue, setClDragForbidEdgeContextGlobalValue} = useContext(ClDragForbidEdgeGlobalContext)
  
  const {clDropSetOrderContextValue, setClDropSetOrderContextValue} = useContext(ClDropSetOrderContext)
  const {clDragSetOrderContextValue, setClDragSetOrderContextValue} = useContext(ClDragSetOrderContext)
  const {clDragSetOrderContextGlobalValue, setClDragSetOrderContextGlobalValue} = useContext(ClDragSetOrderGlobalContext)
  
 

  const history=useHistory();

  const [sourceChoice, setSourceChoice] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [connector,setConnector] = useState("");

  const [show, setShow] = useState("choose-dataset");

  const [columns, setColumns]=useState([]);
  const [rows, setRows]=useState([]);
  const [rowsGlobal, setRowsGlobal]=useState([])


  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [minDate, setMinDate] = useState()
  const [maxDate, setMaxDate] = useState()
 

  const [factors, setFactors] = useState([])
  const [factorsTypes, setFactorsTypes] = useState([])

  const [showPreviousButton, setShowPreviousButton] = useState(false);

  const [isDragged, setIsDragged]=useState(false);

  const [files, setFiles]=useState([]);
  const [timelineLevel, setTimelineLevel]=useState(1);

  const [getNotConsideredFactors, setGetNotConsideredFactors] = useState(0)


  const [showNextButton, setShowNextButton] = useState(false)
  const [showDate, setShowDate] = useState(true)

  const [postCsvDone, setPostCsvDone] = useState(false)

 // Fetch connectors from backend
  useEffect(()=>{
       let connectors=[]
       axios.get(getConnectorsEndPoint)
       .then(e=>{
        e.data.connectors.forEach(connector => {
          connectors.push({ id: uuid(), label: connector, selected:false})
        });
        setDataSource(connectors)
        setConnector(connectors[0].label)
       })
       .catch(err=>console.log(err))     
  },[])




  const changeStringDateFormat=useCallback((string1)=>{
    if(string1 != null){
      let tabString1=string1.split(".")
      let tabString2=[]
      tabString2.push(tabString1[1],tabString1[0],tabString1[2])
      let string2=tabString2.toString()
      return string2
    }
  },[])


  const previewPageFunction=()=>{
    if(show==="filter-factors-table"){
      if(connector!=null){
        setShow("choose-dataset")
        setTimelineLevel(1);
        setShowPreviousButton(false)
        return
      }
        setTimelineLevel(2);
        setShow("chosing-types-targets")
        return
    }
    if(show==="chosing-types-targets"){
      setShowPreviousButton(false)
      setShow("choose-dataset")
      setTimelineLevel(1);
      return
    }
  }

  const handleDatasetDisplay=async()=>{
    setShowPreviousButton(true)
    if(show==="choose-dataset"){

      if(connector!=null){
        setShow("filter-factors-table")  
        setTimelineLevel(3);
        setShowPreviousButton(true)      
        return
      }
      setShow("chosing-types-targets")
      setTimelineLevel(2)
    }
    else if(show==="chosing-types-targets"){
      setGetNotConsideredFactors(Math.random())
      setShow("filter-factors-table")
      setTimelineLevel(3);
      setShowPreviousButton(true)
    }
    else if (show==="filter-factors-table") {
      if(connector!=null){
        setCreatedModelContextValue({model_name : name, description : description});
        setNetworkContextValue({});
        setTimelineLevel(4);
        history.push(`/model/${name}`)
        return
      }
      if(postCsvDone){

        const res= await handleCreateModel()     
        if(res.data.success===true){
          setCreatedModelContextValue({model_name : name, description : description});
          setNetworkContextValue({});
          setTimelineLevel(4); 
          history.push(`/model/${name}`)
        }
      }else{
        console.log("ch3ar")
      }

    }

  }
  
const handleDataTable=async(e)=>{
  e.preventDefault()
  setShowDate(true)
  setClDropLayoutFactorsContextValue([])
  setClDropLayoutFactorsGlobalContextValue([])
  setClDragLayoutFactorsContextValue([])

  setClDropSetValueContextValue([])
  setClDragSetValueContextValue([])
  setClDragSetValueContextGlobalValue([])

  setClDropForbidEdgeContextValue([])
  setClDragForbidEdgeContextValue([])
  setClDragForbidEdgeContextGlobalValue([])

  setClDropSetOrderContextValue([])
  setClDragSetOrderContextValue([])
  setClDragSetOrderContextGlobalValue([])

  setClDropAddEdgeContextValue([])
  setClDragAddEdgeContextValue([])
  setClDragAddEdgeContextGlobalValue([])
  // case upload csv
  if(connector != null){
    const response = await axios.post(createModelEndPoint, {model_name : name, description : description, data_source:connector})
    const dataSetRes = await axios.post(getDataTableEndPoint, {data_source:connector}) 
    // set columns  
    const tabColumns=[]
    dataSetRes.data.columns.forEach(element => {
      tabColumns.push({id: element.id, field:element.field, headerName:element.field, width:150, headerClassName: 'super-app-theme--header', headerAlign: 'justify'})
    });
    setColumns(tabColumns)

    const rowsCalc = dataSetRes['data']['rows'].map((row, index)=> {
      return {
        ...row,
        id:index+1,
      }
    })
    setRows(rowsCalc)
    setShowDate(false)
  }
  setTimeout(() => {
    setShowNextButton(true)    
  }, 1000);
}


const handleDrop= async(newFiles)=>{
  setClDropLayoutFactorsContextValue([])
  setClDropLayoutFactorsGlobalContextValue([])
  setClDragLayoutFactorsContextValue([])

  setClDropSetValueContextValue([])
  setClDragSetValueContextValue([])
  setClDragSetValueContextGlobalValue([])

  setClDropForbidEdgeContextValue([])
  setClDragForbidEdgeContextValue([])
  setClDragForbidEdgeContextGlobalValue([])

  setClDropSetOrderContextValue([])
  setClDragSetOrderContextValue([])
  setClDragSetOrderContextGlobalValue([])

  setClDropAddEdgeContextValue([])
  setClDragAddEdgeContextValue([])
  setClDragAddEdgeContextGlobalValue([])
  setConnector(null)
  parse(newFiles[0],{	
    header:true,
    dynamicTyping: true,
    complete: function(results) {

      const types=[]
      results['meta']['fields'].forEach(element => {
        if(typeof results['data'][0][element]==="number"){
          types[element]="Numerical"
          return
        }
        if(typeof results['data'][0][element]==="string"){
          types[element]="String"
          return
        }
        types[element]=typeof results['data'][0][element]
      });
      setFactorsTypes(types)
    // get all factors
    setFactors(results['meta']['fields'])
 
    const rowsCalc = results['data'].map((row, index)=> {
      return {
        ...row,
        id:index+1,
      }
    })
    setRows(rowsCalc)
    setRowsGlobal(rowsCalc)
    const columnsCreated =Object.keys(rowsCalc[0]).map((title)=>{
      return(
        {field:title, headerName:title, width:150, headerAlign: 'justify'}
      )
    })

    const isTargets=clDropLayoutFactorsGlobalContextValue.filter(factor=>factor.isTarget===true)
    .filter(factor=>!clDragLayoutFactorsContextValue.find(el=>el.name===factor.name))
    .map(t => t.name)

    // find target from context in columns and aplly conditional style
    const styledColumn = columnsCreated.map(c => {
    return {
      ...c,
      headerClassName: isTargets.includes(c.field) ? 'targetStyle' : 'super-app-theme--header'
    }
    })
    setColumns(styledColumn)

    //setColumns(columnsCreated)
    if(results['meta']['fields'].includes("Date")){
      const minDateL=results['data'].find(row=>typeof row.Date==="string").Date
      const rowsLocal=[...results['data']]
      const maxDateL=rowsLocal.reverse().find(row=>typeof row.Date==="string").Date
      setMinDate(changeStringDateFormat(minDateL))
      setMaxDate(changeStringDateFormat(maxDateL))
      setStartDate(new Date(changeStringDateFormat(minDateL)))
      setEndDate(new Date(changeStringDateFormat(maxDateL)))
    }
	},
  })
   // csvToArray(str,",")
   const f = Array.prototype.slice.call(newFiles)
   const fileList = [...files].concat(f)
   setFiles(fileList)

   const formData = new FormData()
   formData.append("model_name", name)
   formData.append("description", description)
   formData.append("file", newFiles[0])

    const response = await axios.post(postCsvFileEndPoint, formData)
    setPostCsvDone(response.data.success)
}



  const updateStartDate=(date)=>{
    setStartDate(date)
    const rowsLocal=[...rowsGlobal]
    const tab=[]
    rowsLocal.forEach(element => {
     if( new Date(changeStringDateFormat(element['Date']))>=date && new Date(changeStringDateFormat(element['Date']))<=endDate){
       tab.push(element)
     }
    });
    setRows(tab)
  }

  const updateEndDate=(date)=>{
    setEndDate(date) 
    const rowsLocal=[...rowsGlobal]
    const tab=[]
    rowsLocal.forEach(element => {
     if( new Date(changeStringDateFormat(element['Date']))>=startDate && new Date(changeStringDateFormat(element['Date']))<=date){
       tab.push(element)
     }
    });
    setRows(tab)
  }

  const handleCreateModel=()=>{ 

    const tab=clDropLayoutFactorsGlobalContextValue.filter(e=>{
      if(!clDragLayoutFactorsContextValue.find(el=>el.name===e.name)){
        return e
      }
    })
    let beginDate = null
    let endDate = null
    if(rows.find(row=>typeof row.Date==="string")){
      beginDate=rows.find(row=>typeof row.Date==="string").Date
      const rowsLocal=[...rows]
      endDate=rowsLocal.reverse().find(row=>typeof row.Date==="string").Date
    }
    return( 
      axios.post(manageCsvFileEndPoint,
        {
          model_name: name,
          date_interval:beginDate !=null && beginDate.toString() != "Invalid Date"?{beg:beginDate, end:endDate}:null,
          vars_info:tab
        }
      )
    )
   
  }
  const restartDefaultsDates=()=>{
    setStartDate(new Date(minDate))
    setEndDate(new Date(maxDate))
    const rowsLocal=[...rowsGlobal]
    const tab=[]
    rowsLocal.forEach(element => {
     if( new Date(changeStringDateFormat(element['Date']))>=new Date(minDate) && new Date(changeStringDateFormat(element['Date']))<=new Date(maxDate)){
       tab.push(element)
     }
    });
    setRows(tab)
  }

  return (
  <div className="main-container">
    <div className="content-main">
    <Timeline timelineLevel={timelineLevel}/>
    {show ==="choose-dataset"&&
      <div className="edit-model-container">
        <form onSubmit={handleDataTable} className="form-model">
        <h2>Create Model</h2>
        <div className="form-input">
          <label>Name</label>   
          <input 
            name="model's name" 
            type="text"
            required
            value={name}
            onChange={(event)=>setName(event.target.value)}>
          </input>
        </div>
        <div className="form-input">
          <label>Description</label>   
          <textarea 
          name="model's description" 
          type="textarea"
          rows="10"
          value={description}
          onChange={(event)=>setDescription(event.target.value)}>
          </textarea>
        </div>
        <div className="separator-line"></div>
        <h3>Dataset</h3>
        <div className="toggle-button-data-source">
          <div 
          className={sourceChoice === "data source" && "clicked"}
          onClick={()=>setSourceChoice("data source")}>Data Source</div>
          <div 
           className={sourceChoice === "csv file" && "clicked"}
          onClick={()=>setSourceChoice("csv file")}>Import Dataset</div>
        </div>
        {sourceChoice === "data source" 
        ?
        <div className="data-source-section">
          <select name="connectors" onChange={(e)=>setConnector(e.target.value)}>
            {dataSource.map((source)=>{
              return (
              <option value={source.label}>{source.label}</option>
              )
            })}
          </select>     
          </div>
        :
        sourceChoice === "csv file"
        &&
        <div className="data-source-section">
        <DragAndDrop handleDrop={handleDrop} isDragged={isDragged} setIsDragged={setIsDragged}>
            <div className="import-with-dnd">
              {files.map((file)=>{
                return(
                  <div>{file.name}</div>
                )
              }
              )}
            </div>
        </DragAndDrop> 

        </div>
        }   
        <br/>
        <br/>
        <br/>
        <div style={{direction:'rtl'}}>
        <input  className="b" type="submit" value="Submit"/>
        <br/><br/>
        {showNextButton?succesMessage:null}
        </div>
      </form>
      </div>
      }      
      {
        show==="chosing-types-targets" ?
            <FactorsTable
              factors={factors}
              factorsTypes={factorsTypes}
              getNotConsideredFactors={getNotConsideredFactors}
              connector={connector}
              files={files}
            />  
        :null   
      }
      {
        show==="filter-factors-table" ?
        <Fragment>
        <DatasetTable
        columns={columns}
        rows={rows}
        connector={connector}
        files={files}
       />
       {
         showDate && minDate?
         <div className="dateLimits">
         <label style={{  width: "40%",color: "#2f5596"}}>Filter by date </label><br/><br/>
        <DatePicker selected={startDate} onChange={date => updateStartDate(date)} 
          minDate={new Date(minDate)}
          maxDate={endDate?endDate:new Date(maxDate)}
          placeholderText="Start date"
        />
        <DatePicker selected={endDate} onChange={date => updateEndDate(date)} 
          minDate={startDate?startDate:new Date(minDate)}
          maxDate={new Date(maxDate)}
          placeholderText="End date"
        /><br/>
          <button onClick={restartDefaultsDates}>Reset</button>
         </div>
         :null
       }
      </Fragment>
      :null}
    </div>
  <div className="icons-buttons" style={{padding: '0 5%',boxSizing: 'border-box'}}>
    <button 
    style={{visibility : !showPreviousButton ? "hidden" : "visible"}}
    onClick={previewPageFunction}
    >Previous</button>
    <button 
    style={{visibility : !showNextButton ? "hidden" : "visible"}}
    onClick={handleDatasetDisplay}
    >Next</button>
  </div>
  </div>
  )

}

export default NewModel;
