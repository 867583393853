import React from 'react';
import {useState} from 'react';
import { 
         NetworkDataNetworkCreateModelContext,
         NetworkContext, 
         CreatedModelContext,
         AnalysisNameContext,
         ScoringModelContext,
         CommentsRequirementsContext,
         RestartDeletedNodesContext,
         DeletedNodesEdgesContext,
         ClickDragContext,
         ClickDropContext,
         AssetPickingFilterContext,
         ClickDndGlobalContext,
         ClDragSetValueContext,
         ClDropSetValueContext,
         ClDragSetValueGlobalContext,
         ClDragSetOrderContext,
         ClDropSetOrderContext,
         ClDragSetOrderGlobalContext,
         ClDragAddEdgeContext,
         ClDropAddEdgeContext,
         ClDragAddEdgeGlobalContext,
         ClDragForbidEdgeContext,
         ClDropForbidEdgeContext,
         ClDragForbidEdgeGlobalContext,
         FactorSelectionFilterContext,
         ClDragLayoutFactorsContext,
         ClDropLayoutFactorsContext,
         ClDropLayoutFactorsGlobalContext} from './global_values';

import {Route} from 'react-router-dom'
        
import './App.css';

import NavBar from './subViews/NavigationBar';
import Home from './views/Home';
import CreateModel from './views/CreateModel';
import ViewModel from "./views/ViewModel";
import FactorSelection from "./views/FactorSelection";
import CustomModel from './views/CustomModel';
import CreateAnalysis from './views/CreateAnalysis';
import ViewAnalysis from './views/ViewAnalysis';
import CustomScoring from './views/CustomScoring';
import Reporting from './views/Reporting';
import ChoiceAfterScoring from './views/ChoiceAfterScoring';
import CreateForecast from './views/CreateForecast';
import ForecastOverview from "./views/ForecastOverview";

import Portfolio from './views/Portfolio';

// Import FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core';
import {faCoins, faSpinner } from '@fortawesome/free-solid-svg-icons';
library.add(faCoins, faSpinner);

function App() {

  const [networkDataNetworkCreateModelContextValue, setNetworkDataNetworkCreateModelContextValue] = useState(null)
  const [networkContextValue, setNetworkContextValue] = useState({})

  const [createdModelContextValue, setCreatedModelContextValue] = useState({model_name:"",description:""})
  const [scoringModelContextValue, setScoringModelContextValue] = useState({model_name:"",description:""})
  const [analysisNameContextValue, setAnalysisNameContextValue] = useState("")
  const [commentsRequirementsContextValue, setCommentsRequirementsContextValue] = useState({commentSequence:"",commentSetValue:"",commentAddEgde:""})
  const [restartDeletedNodesContextValue, setRestartDeletedNodesContextValue] = useState([])
  const [deletedNodesEdgesContextValue, setDeletedNodesEdgesContextValue] = useState(false)  

  const [clickDropContextValue, setClickDropContextValue] = useState([])  
  const [clickDragContextValue, setClickDragContextValue] = useState([])  
  const [assetPickingFilterContextValue, setAssetPickingFilterContextValue] = useState("all")
  const [clickDndGlobalContextValue, setClickDndGlobalContextValue] = useState([])
  const [clDragSetValueContextValue, setClDragSetValueContextValue] = useState([])
  const [clDropSetValueContextValue, setClDropSetValueContextValue] = useState([])
  const [clDragSetValueContextGlobalValue, setClDragSetValueContextGlobalValue] = useState([])
  const [clDragSetOrderContextValue, setClDragSetOrderContextValue] = useState([])
  const [clDropSetOrderContextValue, setClDropSetOrderContextValue] = useState([])
  const [clDragSetOrderContextGlobalValue, setClDragSetOrderContextGlobalValue] = useState([])
  const [clDragAddEdgeContextValue, setClDragAddEdgeContextValue] = useState([])
  const [clDropAddEdgeContextValue, setClDropAddEdgeContextValue] = useState([])
  const [clDragAddEdgeContextGlobalValue, setClDragAddEdgeContextGlobalValue] = useState([])
  const [clDragForbidEdgeContextValue, setClDragForbidEdgeContextValue] = useState([])
  const [clDropForbidEdgeContextValue, setClDropForbidEdgeContextValue] = useState([])
  const [clDragForbidEdgeContextGlobalValue, setClDragForbidEdgeContextGlobalValue] = useState([])
  const [factorSelectionFilterContextValue, setFactorSelectionFilterContextValue] = useState("all")

  const [clDragLayoutFactorsContextValue, setClDragLayoutFactorsContextValue] = useState([])
  const [clDropLayoutFactorsContextValue, setClDropLayoutFactorsContextValue] = useState([])
  const [clDropLayoutFactorsGlobalContextValue, setClDropLayoutFactorsGlobalContextValue] = useState([])

  return (
    <div className="App">
      <ClDropLayoutFactorsGlobalContext.Provider value={{clDropLayoutFactorsGlobalContextValue, setClDropLayoutFactorsGlobalContextValue}}>
      <ClDragLayoutFactorsContext.Provider value={{clDragLayoutFactorsContextValue, setClDragLayoutFactorsContextValue}}>
      <ClDropLayoutFactorsContext.Provider value={{clDropLayoutFactorsContextValue, setClDropLayoutFactorsContextValue}}>
      <ClDragForbidEdgeGlobalContext.Provider value={{clDragForbidEdgeContextGlobalValue, setClDragForbidEdgeContextGlobalValue}}>
      <ClDropForbidEdgeContext.Provider value={{clDropForbidEdgeContextValue, setClDropForbidEdgeContextValue}}>
      <ClDragForbidEdgeContext.Provider value={{clDragForbidEdgeContextValue, setClDragForbidEdgeContextValue}}>
      <ClDragAddEdgeGlobalContext.Provider value={{clDragAddEdgeContextGlobalValue, setClDragAddEdgeContextGlobalValue}}>
      <ClDragSetOrderGlobalContext.Provider value={{clDragSetOrderContextGlobalValue, setClDragSetOrderContextGlobalValue}}>
      <ClDragSetValueGlobalContext.Provider value={{clDragSetValueContextGlobalValue, setClDragSetValueContextGlobalValue}}>
      <FactorSelectionFilterContext.Provider value={{factorSelectionFilterContextValue, setFactorSelectionFilterContextValue}}>
      <ClDropAddEdgeContext.Provider value={{clDropAddEdgeContextValue, setClDropAddEdgeContextValue}}>
      <ClDragAddEdgeContext.Provider value={{clDragAddEdgeContextValue, setClDragAddEdgeContextValue}}>
      <ClDropSetOrderContext.Provider value={{clDropSetOrderContextValue, setClDropSetOrderContextValue}}>
      <ClDragSetOrderContext.Provider value={{clDragSetOrderContextValue, setClDragSetOrderContextValue}}>
      <ClickDndGlobalContext.Provider value={{clickDndGlobalContextValue, setClickDndGlobalContextValue}}>
      <AssetPickingFilterContext.Provider value={{assetPickingFilterContextValue, setAssetPickingFilterContextValue}}>
      <ClDropSetValueContext.Provider value={{clDropSetValueContextValue, setClDropSetValueContextValue}}>
       <ClDragSetValueContext.Provider value={{clDragSetValueContextValue, setClDragSetValueContextValue}}>
       <ClickDropContext.Provider value={{clickDropContextValue, setClickDropContextValue}}>
       <ClickDragContext.Provider value={{clickDragContextValue, setClickDragContextValue}} >
       <DeletedNodesEdgesContext.Provider value={{deletedNodesEdgesContextValue, setDeletedNodesEdgesContextValue}}>
       <RestartDeletedNodesContext.Provider value={{restartDeletedNodesContextValue, setRestartDeletedNodesContextValue}}>
       <CommentsRequirementsContext.Provider value={{commentsRequirementsContextValue, setCommentsRequirementsContextValue}}>
       <ScoringModelContext.Provider value={{scoringModelContextValue, setScoringModelContextValue}}>
       <AnalysisNameContext.Provider value={{analysisNameContextValue, setAnalysisNameContextValue}}>
       <CreatedModelContext.Provider value={{createdModelContextValue, setCreatedModelContextValue}}>
       <NetworkDataNetworkCreateModelContext.Provider value={{networkDataNetworkCreateModelContextValue, setNetworkDataNetworkCreateModelContextValue}}>
       <NetworkContext.Provider value={{networkContextValue, setNetworkContextValue}}>
       <NavBar></NavBar>
       <Route path="/create-model">
       <CreateModel/>
       </Route>
       <Route path="/model/:modelName" exact>
       <ViewModel/>
       </Route>
       <Route path="/factor-selection/:modelName">
       <FactorSelection/>
       </Route>
       <Route path="/custom-model/:modelName">
       <CustomModel/>
       </Route>
       <Route path="/create-analysis" exact>
       <CreateAnalysis/>
       </Route>
       <Route path="/analysis/:analysisName">
       <ViewAnalysis/>
       </Route>
       <Route path="/custom-scoring/:analysisName">
       <CustomScoring/>
       </Route>
       <Route path="/create-simulation">
         <CreateForecast/>
       </Route>
       <Route path="/simulation/:analysisName">
         <ForecastOverview/>
       </Route>
      <Route path="/redirection/:analysisName">
      <ChoiceAfterScoring/>
      </Route>
       <Route path="/reporting/:analysisName">
      <Reporting/>
      </Route>
      <Route path="/portfolio/:analysisName" exact>
      <Portfolio/>
      </Route>  
       <Route path="/" exact>
       <Home/>
       </Route>
       </NetworkContext.Provider>
       </NetworkDataNetworkCreateModelContext.Provider>
       </CreatedModelContext.Provider>
       </AnalysisNameContext.Provider>
       </ScoringModelContext.Provider>
       </CommentsRequirementsContext.Provider>
       </RestartDeletedNodesContext.Provider>
       </DeletedNodesEdgesContext.Provider>
       </ClickDragContext.Provider>
       </ClickDropContext.Provider>
       </ClDragSetValueContext.Provider> 
       </ClDropSetValueContext.Provider>
       </AssetPickingFilterContext.Provider>
       </ClickDndGlobalContext.Provider>
       </ClDragSetOrderContext.Provider>
       </ClDropSetOrderContext.Provider>
       </ClDragAddEdgeContext.Provider>
       </ClDropAddEdgeContext.Provider>
       </FactorSelectionFilterContext.Provider>
       </ClDragSetValueGlobalContext.Provider>
       </ClDragSetOrderGlobalContext.Provider>
       </ClDragAddEdgeGlobalContext.Provider>
       </ClDragForbidEdgeContext.Provider>
       </ClDropForbidEdgeContext.Provider>
       </ClDragForbidEdgeGlobalContext.Provider>
       </ClDropLayoutFactorsContext.Provider>
       </ClDragLayoutFactorsContext.Provider>
       </ClDropLayoutFactorsGlobalContext.Provider>
    </div>
  );
}

export default App;
