import axios from "axios";
import React, {useEffect, useState} from "react";
import { useLocation, useHistory } from "react-router-dom";

import { Bar } from 'react-chartjs-2';
import Plot2 from './Plot2';
import SaveAnalysisModal from "../subViews/SaveAnalysisModal";

import Network from '../subViews/network_what_If_Analysis';

import { format } from 'date-fns';
import chroma from 'chroma-js'

const jsonNetworkEndPoint = `${process.env.REACT_APP_URL_MASTER}/view_model/get_network`

const red="#f995aa"
const blue="lightblue"
const gray="gray"

const options3 = {
  indexAxis: 'y',
  // Elements options apply to all of the options unless overridden in a dataset
  // In this case, we are setting the border of each horizontal bar to be 2px wide
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'right',
    },
    title: {
      display: true,
      text: 'Factors Influence',
      position:'top'
    },
  },
}
const options = {
  indexAxis: 'y',
  // Elements options apply to all of the options unless overridden in a dataset
  // In this case, we are setting the border of each horizontal bar to be 2px wide
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
    },
    title: {
      display: true,
      text: 'Expected Returns',
    },
  },
}
const options2 = {
  indexAxis: 'y',
  // Elements options apply to all of the options unless overridden in a dataset
  // In this case, we are setting the border of each horizontal bar to be 2px wide
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
    },
    title: {
      display: true,
      text: 'Factors Influence',
      position:'top'
    },
  },
}

const ForecastOverview = () => {

   const location = useLocation();
   console.log("location:", location);
   const {factors}= location.state;
   const {chosenDate} = location.state;
   const {forecastName} = location.state;
   const {plotExpectedReturnsChart} = location.state;
   const {plotVariableInfluenceChart} = location.state;
   const {explicativeFactors} = location.state;
   const {funds} = location.state;
   const {chosenModel} = location.state;
   const {updateD} = location.state;

   // state to manage popin on save
   const[isOpen, setIsOpen]=useState(false);

   const [jsonData, setJsonData]=useState(null)
   const [fundss, setFunds] = useState([])
   const [chosenFactor, setChosenFactor]= useState(null)
   const [deletedFactor, setDeletedFactor]=useState(null)

   const [pickedFont, setPickedFont]=useState(["#f7fcf5", "#e5f5e0", "#c7e9c0", "#a1d99b", "#74c476", "#41ab5d", "#238b45", "#006d2c", "#00441b"])

   let history = useHistory();

   const handleNavigation =()=>{
     history.push('/create-simulation')
   }

   useEffect(async()=>{
// set default colors for edges
let res=await axios.post(jsonNetworkEndPoint,{model_name:chosenModel})
res.data.edges.forEach(edge => {
  edge.color=gray
});

// get max width value
const maxEntropy=Math.max.apply(Math, res.data.nodes.map(function(o) { return o.entropy; }))
const minEntropy=Math.min.apply(Math, res.data.nodes.map(function(o) { return o.entropy; }))
const maxEntropyEdge=Math.max.apply(Math, res.data.edges.map(function(o) { return o.entropy; }))
//const minEntropyEdge=Math.min.apply(Math, res.data.edges.map(function(o) { return o.entropy; }))

// get E S G factors
let E=res.data.nodes.find(node=>node.name==="PortfolioEnvironmentalScore")
let S=res.data.nodes.find(node=>node.name==="PortfolioGovernanceScore")
let G=res.data.nodes.find(node=>node.name==="PortfolioSocialScore")
// star square diamond 
if(E){
  E.shape="star"
}
if(S){
  S.shape="star"
}
if(G){
  G.shape="star"
}


res.data.nodes.forEach(node=>{
node.label=node.name  
//node.color=chroma.scale('puBuGn').domain([maxEntropy,0])(node.entropy).toString()  
node.color=chroma.scale(pickedFont).domain([minEntropy,maxEntropy])((node.entropy)).toString()  
node.value=node.entropy       
})
const widthCalulWay="entropys"
res.data.edges.forEach(edge=>{

if(widthCalulWay==="entropy"){
  edge.width=edge.entropy/maxEntropyEdge*10
  edge.label=edge.entropy
  if(edge.corr>0){
    edge.color=blue
  }
  else if(edge.corr<0){
    edge.color=red
  }
 }
 else{
  if(edge.corr!==0){
    edge.width=Math.abs(edge.corr)*10
    edge.label=edge.corr 
    if(edge.corr>0){
      edge.color=blue
    }
    else if(edge.corr<0){
      edge.color=red
    }  
  } else{
    edge.width=edge.width*10
    edge.label=edge.corr 
    if(edge.corr>0){
      edge.color=blue
    }
    else if(edge.corr<0){
      edge.color=red
    }            
  }   
 }
 if(!edge.corr){
   edge.width=1
 }
})
updateD.nodes.filter(e=>e.color==="crimson").forEach(element => {
  res.data.nodes.find(node=>node.name===element.name).color="crimson"
});

setJsonData(res.data)
// get funds and explicative factors

// initialize states to empty arrays
setFunds([])
const response2=await axios.get(`${process.env.REACT_APP_URL_MASTER}/what-if-analysis?name=${forecastName}&&fields=factors`)
response2.data.factors.forEach(factor => {
    if(factor.is_target){
        factor.pfWeight=factor.pfWeight*100
        setFunds(funds=>[...funds,factor])
    }
});
   },[])

  return (
    <div className="main-container">
    <div className="content-main">
    <div style={{display:'flex', justifyContent:'space-between', alignItems:'flex-start'}}>
        <h2>Simulation Overview</h2>
        <span>
        <button 
        className="btn-run-simulation"
        onClick={()=>{
        setIsOpen(true)}}
        >SAVE SIMULATION</button>
        <SaveAnalysisModal
        forecastName={forecastName}
        open={isOpen} 
        setIsOpen={setIsOpen}
        onClose={()=>setIsOpen(false)}/>
        </span>
        </div>
        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
        <div className="date">
          {format(new Date(chosenDate), 'PPPppp').split('at')[0]}
        </div>
        <div style={{visibility:'hidden'}}>Allocation 1</div>
        {/* <button onClick={handleNavigation}>BACK</button> */}
        </div>
        <div className="edit-model-container">
          <h3 style={{textAlign:"center"}}>Allocation 1</h3>
          <div className="legend" style={{direction:'rtl', display:'flex'}}>
            <div style={{backgroundColor:'tomato', height:'50px', width:'100px', display:'flex', justifyContent:'center', alignItems:'center', color:'white',fontWeight:'700'}}>High</div>
            <div style={{backgroundColor:'#2EC4B6', height:'50px', width:'100px', display:'flex', justifyContent:'center', alignItems:'center', color:'white',fontWeight:'700'}}>Low</div>
          </div>
          <div style={{display:'flex', justifyContent:'space-between', alignItems:'start'}}>
          <div className="col-1">
          <div>Weight</div>
          <div style={{height:factors.filter(factor => !factor.is_target).length*60 + factors.filter(factor => !factor.is_target).length*2, display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
            {factors.filter(factor => factor.is_target).map((factor)=>{
              return (
                <div style={{
                  height:`${factor.pfWeight*100}%`, 
                  backgroundColor:'#081c4d', color: 'white', display:'flex', justifyContent:'center', alignItems:'center', marginBottom:'2px'}}>{factor.name.charAt(0).toUpperCase()+factor.name.slice(1)}&#32;{factor.pfWeight}%
                  </div>
              )
            })}
           
          </div>
          </div>       
          <div className="col-2">
          <div>Hypothesis</div>
          <div>
            {factors.filter(factor => !factor.is_target).map((factor)=>{
              return(
                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:'2px'}}>
                  <div style={{height:'60px', display:'flex', justifyContent:'space-between',alignItems:'center', width:`${factor.prob*100}%`, backgroundColor:'tomato', color:'white', paddingLeft:'10px'}}>
                  <div>{factor.name.charAt(0).toUpperCase()+factor.name.slice(1)}</div>
                  <div style={{transform: "rotate(-90deg)"}}>{`${factor.prob}%`}</div>
                  </div>
                  <div style={{height:'60px', color:'white', backgroundColor:'#2EC4B6',width:`${(100-factor.prob)*100}%`, display:'flex', alighItems:'center', justifyContent:'flex-end'}}>
                  <div style={{ transform: "rotate(-90deg)"}}>{`${(100-factor.prob)}%`}</div>
                  </div>
                  </div>
              )
            })}
          </div>
          </div>
          </div>
        <div className="blue-line"></div>    
        <div>
        <Network
        networkData={jsonData}
        chosenFactor={chosenFactor}
        deletedFactor={deletedFactor}
        funds={fundss}
        />
        </div>      
        <div style={{width: '100%', display:'flex', flexDirection:'row'}}>
          <div style={{width:'49%'}}>
          <Bar data={plotVariableInfluenceChart} options={options} />
          </div> 
          <br/><br/> 
          <div style={{width:'49%'}}>
          <Bar data={plotExpectedReturnsChart} options={options2}/>
          </div>       
          </div>
          <div>
            <Plot2 forecastName={forecastName} chosenDate={chosenDate} factors={explicativeFactors.concat(funds)}/>
          </div>
        </div>
        </div>
        </div>
   
  );
}

export default ForecastOverview;




