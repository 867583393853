import React, {useState} from "react";

// Import Pagination UI Material Library
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

// Icons Import
import arrowDown from "../assets/arrowUp.svg";
import arrowUp from "../assets/arrowDown.svg";

// RC-SLIDER
import Slider, { SliderTooltip }  from "rc-slider";
import "rc-slider/assets/index.css";
const { Handle } = Slider;

// RC-SLIDER-TOOLTIP
const handle = props => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${value}`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};
const wrapperStyle = { width: 400, margin: 50, color:'red' };
// RC-SLIDER

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
      display:'flex',
      justifyContent:'center',
    },
  },
}));

const Funds = ({funds, riskChosen,setRiskChosen, universeChosen, reportingRef, setFundInfo, graphRef, setEsgScoreSum, setEsgValuesCM, setEsgValuesMS, changeNetworkHover
}) => {
  const [activeRowMS,setActiveRowMS]=useState(undefined);
  const [sortByFct, setSortByFct] = useState();
  const [page, setPage]=useState(1);
  
  const pageMax = Math.round((funds.filter(f => f.risk <= riskChosen)).length/10);
  const fixedPage = Math.min(page, pageMax);

  const handleDisplayScoreSum =(index, fund)=>{
    graphRef.current.scrollIntoView();
    setFundInfo({...fund});
    setEsgScoreSum(true);
    setEsgValuesMS({...fund.sources.MS.scoreSum});
    setEsgValuesCM({...fund.sources.CM.scoreSum});
    setActiveRowMS(activeRowMS===index ? -1 : index);
    changeNetworkHover({...fund});
  }

  const classes = useStyles();

  return (
    <div>
      {/* Risk Slider starts here */}
      <div className={"risk-slider"}>
        {riskChosen ?
          <p style={{fontWeight: '500', fontSize: '25px', color: '#2f5596'}}>SRRI: <span>{riskChosen}</span></p>
          :
          <p>Choose the risk value desired</p>
        }
        <Slider
          value={riskChosen}
          min={1}
          max={7}
          step={1}
          handle={handle}
          onChange={(e) => {
            setRiskChosen(e)
          }}
          trackStyle={{backgroundColor: '#CA7A02', height: 10}}
          handleStyle={{
            borderColor: '#CA7A02',
            height: 28,
            width: 28,
            marginTop: -9,
            backgroundColor: '#CA7A02',
          }}
          railStyle={{backgroundColor: '#648BCE', height: 10}}
        />
      </div>
      {/* Funds Table starts here */}
      <div className={"report-section"}>
        <div ref={reportingRef}>
          {universeChosen &&
          <div className="universe-title">{universeChosen} Funds</div>
          }
        </div>
        <div className="reporting-table-container">
          <table className="reporting-table table-auto">
            <tr className="table-header-scoring">
              <th className="val-1">Fund Name</th>
              <th className="val-1">SRRI</th>
              <th className="val-1" style={{display: 'flex', justifyContent: "center", alignItems: "center"}}>
                <div>Score MS</div>
                <div className="sort-btns">
                  <button onClick={() => setSortByFct(() => (a, b) => {
                    return a.sources.MS.score - b.sources.MS.score
                  })}><img src={arrowUp}/></button>
                  <button onClick={() => setSortByFct(() => (a, b) => {
                    return b.sources.MS.score - a.sources.MS.score
                  })}><img src={arrowDown}/></button>
                </div>
              </th>
              <th className="val-1">Rank MS</th>
              <th className="val-1" style={{display: 'flex', justifyContent: "center", alignItems: "center"}}>
                <div>Score CM</div>
                <div className="sort-btns">
                  <button onClick={() => setSortByFct(() => (a, b) => {
                    return a.sources.CM.score - b.sources.CM.score
                  })}><img src={arrowUp}/></button>
                  <button onClick={() => setSortByFct(() => (a, b) => {
                    return b.sources.CM.score - a.sources.CM.score
                  })}><img src={arrowDown}/></button>
                </div>
              </th>
              <th className="val-1">Rank CM</th>
            </tr>
            {funds
              .filter(f => f.risk <= riskChosen)
              .sort(sortByFct)
              .filter((f, index) => index >= (fixedPage - 1) * 10 && index < fixedPage * 10)
              .map((fund, index) => {
                return (
                  <tr className="report-row table-select"
                      id={index === activeRowMS ? "active" : {}}
                      onClick={(e) => handleDisplayScoreSum(index, fund)}>
                    <td className="val-1">{fund.fundName}</td>
                    <td className="val-1">{fund.risk}</td>
                    <td className="val-1">{fund.sources.MS.score.toFixed(2)}</td>
                    <td className="val-1">{fund.rankMS}</td>
                    <td className="val-1">{fund.sources.CM.score.toFixed(2)}</td>
                    <td className="val-1">{fund.rankCM}</td>
                  </tr>
                )
              })
            }
          </table>
          {/* Pagination starts here */}
          <div className={classes.root} style={{marginTop: '30px'}}>
            <Pagination
              count={pageMax}
              onChange={(e, number)=>setPage(number)}
              color="primary"/>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Funds;