import React, {useEffect, useContext} from 'react'

//Import contexts
import { ClDragSetValueContext,
  ClDropSetValueContext,
  FactorSelectionFilterContext,
  ClDragSetValueGlobalContext} from '../global_values'

function DropFixValues2() {

  const {clDragSetValueContextValue, setClDragSetValueContextValue} = useContext(ClDragSetValueContext)
  const {clDropSetValueContextValue, setClDropSetValueContextValue} = useContext(ClDropSetValueContext)
  const {clDragSetValueContextGlobalValue, setClDragSetValueContextGlobalValue} = useContext(ClDragSetValueGlobalContext)
  const {factorSelectionFilterContextValue, setFactorSelectionFilterContextValue} = useContext(FactorSelectionFilterContext)


    useEffect(()=>{
      if(factorSelectionFilterContextValue==="all"){
        setClDragSetValueContextValue([...clDragSetValueContextGlobalValue.filter((element)=>!clDropSetValueContextValue.find(e=>e.id===element.id))])
        return
      }
      setClDragSetValueContextValue(clDragSetValueContextGlobalValue.filter((element)=>{
        if(!clDropSetValueContextValue.find(e=>e.id===element.id)){
          return element['type']===factorSelectionFilterContextValue
        }
      }))
    },[factorSelectionFilterContextValue])

    const dragToDrop=(event)=>{
      setClDropSetValueContextValue([...clDropSetValueContextValue, clDragSetValueContextValue.find(element=>element.id.toString()===event.target.dataset.id)])
      setClDragSetValueContextValue(clDragSetValueContextValue.filter(element=>element.id.toString()!==event.target.dataset.id))
  }

    return (
        <div
        style={{
          minHeight: 77, 
          display:"flex",
          justifyContent:"space-between",
          flexFlow:"wrap",
        }}>
       {
       clDragSetValueContextValue.map((value,index)=>(
            <div
            key={value.id}
            data-id={value.id}
            onClick={dragToDrop}
            style={{
              cursor: "pointer",
              userSelect: "none",
              padding: 16,
              margin: "8px 8px",
              width:'fit-content',
              minHeight: "28px",
              backgroundColor: "blue"
                ? "#081c4d"
                : "#F5FAFD",
              filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
              color: "red" 
              ? "#F5FAFD"
              : "#081c4d",
              borderRadius:5,
              fontFamily: "Overpass",
              fontWeight: 400,
              fontSize: 18,
            }}
           >
             {value['content']}
            </div>
       ))}
        </div>
    )
}

export default DropFixValues2
